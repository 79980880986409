

import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more"; // For more chart types
import HighchartsStock from "highcharts/modules/stock"; // For navigator and scrollbar
import Exporting from "highcharts/modules/exporting"; // Import exporting module for download functionality

HighchartsMore(Highcharts);
HighchartsStock(Highcharts);
Exporting(Highcharts); // Initialize exporting module (although we will disable it)

const ChartComponent = ({
  data,
  TrendsHeight,
  divWidth,
  toggle,
  setToggle,
  windowHeight
}) => {
  // Retrieve displayNames from localStorage
  const storedDisplayNames = localStorage.getItem("displayNames");
  const displayNames = JSON.parse(storedDisplayNames);
  console.log("displayNames", displayNames);

  const chartRef = useRef(null);

  // Utility function to get display name
  const getDisplayName = (key) => {
    // Split key by '-'
    const parts = key.split("-");
    // Map each part using displayNames and join them with a -
    return parts.map((part) => displayNames[part] || part).join(" - ");
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      zoomType: "xy",
      
      resetZoomButton: {
        position: {
          align: 'center', // Align the button to the center horizontally
          verticalAlign: 'top', // Keep it at the top vertically
          x: 0, // No horizontal offset, keep it centered
          y: 10, // Optional: Adjust vertical offset if needed
        },
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
      backgroundColor: "",
      // height:
      //   window.innerHeight < 850
      //     ? window.innerHeight * 0.75
      //     : window.innerHeight * 0.81,
      height: windowHeight
    },
    title: {
      text: "", // Remove "Values" label
    },
    xAxis: {
      type: "datetime",
      labels: {
        style: {
          color: "#ffffff",
        },
        rotation: 0,
        formatter: function () {
          const point = this.axis.series[0]?.data?.find(
            (p) => p?.x === this.value
          );
          return point
            ? point.display_date
            : Highcharts.dateFormat("%Y-%m-%d", this.value);
        },
      },
      gridLineColor: "rgba(128, 128, 128, 0.2)",
      minRange: 24 * 3600 * 1000,
    },
    yAxis: [
      {
        title: {
          text: "", // Will be dynamically set
          style: {
            color: "#ffffff",
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            color: "#ffffff",
          },
        },
        gridLineColor: "rgba(128, 128, 128, 0.2)",
      },
      {
        title: {
          text: "", // Will be dynamically set
          style: {
            color: "#ffffff",
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            color: "#ffffff",
          },
        },
        opposite: true,
        gridLineColor: "rgba(128, 128, 128, 0.2)",
      },
    ],
    tooltip: {
      shared: true,
      followPointer: true,
      backgroundColor: localStorage.getItem("theme")==="dark"?"#1c2427":"#F3FFFE",
      style: {
        color: localStorage.getItem("theme")==="dark"?"white":"#0a2472",
      },
      useHTML: true,
      formatter: function () {
        let tooltipText =
          "<b>" +
          (this.points[0].point.display_date ||
            Highcharts.dateFormat("%Y-%m-%d", this.x)) +
          "</b><br/>";
        this.points.forEach((point) => {
          let seriesName = getDisplayName(point.series.name); // Use displayNames mapping
          let seriesKey = Object.keys(data.chart_config).find(
            (key) => data.chart_config[key].color === point.series.color
          );
          let units = seriesKey ? data.chart_config[seriesKey].units : "";

          let symbol;
          if (point.series.type === "line") {
            // Line series - use a horizontal line as the symbol
            symbol = `<span style="display:inline-block; width:12px; height:2px; background-color:${point.series.color}; margin-right:5px;"></span>`;
          } else if (point.series.type === "column") {
            // Column series - use a small circle as the symbol
            symbol = `<span style="background-color:${point.series.color}; width:8px; height:8px; display:inline-block; border-radius:50%; margin-right:5px;"></span>`;
          } else {
            // Default - circle symbol
            symbol = `<span style="background-color:${point.series.color}; width:8px; height:8px; display:inline-block; border-radius:50%; margin-right:5px;"></span>`;
          }

          tooltipText += `${symbol}<span style="color:${point.series.color
            }">${seriesName}:</span> <b>${Highcharts.numberFormat(
              point.y,
              2
            )} ${units}</b><br/>`;
        });

        return tooltipText;
      },
    },
    legend: {
      itemStyle: {
        color: "#ffffff",
        fontWeight: "normal",
        fontFamily: "Arial, sans-serif",
      },
      itemHoverStyle: {
        color: "#d6ff41",
        fontWeight: "normal",
      },
      layout: "horizontal",
      align: "center",
      verticalAlign: window.innerWidth < 600 ? "bottom" : "top",
      labelFormatter: function () {
        return getDisplayName(this.name); // Use displayNames mapping
      },
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        borderWidth: 0,
        events: {
          mouseOut: function () {
            if (this.chart.tooltip) {
              this.chart.tooltip.hide();
            }
          },
        },
      },
    },
    navigator: {
      enabled: false,
      height: 20,
      xAxis: {
        gridLineColor: "#d6ff41",
      },
      series: {
        data: [], // Initial empty data for the navigator
        color: "#d6ff41",
        lineColor: "#d6ff41",
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [],
  });

  useEffect(() => {
    if (!data || !data.chart_config) {
      console.error("Invalid data or chart_config is missing");
      return;
    }

    const series = [];
    const yAxis0Fields = data.chart_config.yAxis0.split(", ");
    const yAxis1Fields = data.chart_config.yAxis1.split(", ");

    // Prepare the yAxis0 and yAxis1 titles with corresponding series colors
    const yAxis0Title = yAxis0Fields
      .map((field) => {
        const unit = data.chart_config[field]?.units || "";
        const displayName = getDisplayName(field);
        const color = data.chart_config[field]?.color || "#ffffff"; // Default color if not found
        return `<span class="text-colors">${displayName}${unit ? ` (${unit})` : ""
          }</span>`;
      })
      .join(", ");

    const yAxis1Title = yAxis1Fields
      .map((field) => {
        const unit = data.chart_config[field]?.units || "";
        const displayName = getDisplayName(field);
        const color = data.chart_config[field]?.color || "#ffffff"; // Default color if not found
        return `<span class="text-colors"
        >${displayName}${unit ? ` (${unit})` : ""
          }</span>`;
      })
      .join(", ");

    yAxis0Fields.forEach((field) => {
      if (data.chart_config[field]) {
        series.push({
          name: field,
          className:'roi-primary-chart',
          displayName: getDisplayName(field),
          type: data.chart_config[field].type,
          data: data.data.map((item) => ({
            x: new Date(item.date).getTime(),
            y: item[field],
            display_date: item.display_date,
          })),
          yAxis: 0,
          color: data.chart_config[field].color,
          tooltip: {
            pointFormatter: function () {
              return `${getDisplayName(
                this.series.name
              )}: ${Highcharts.numberFormat(this.y, 2)}`;
            },
          },
        });
      }
    });

    yAxis1Fields.forEach((field) => {
      if (data.chart_config[field]) {
        series.push({
          name: field,
          className:'roi-primary-chart',
          displayName: getDisplayName(field),
          type: data.chart_config[field].type,
          data: data.data.map((item) => ({
            x: new Date(item.date).getTime(),
            y: item[field],
            display_date: item.display_date,
          })),
          yAxis: 1,
          color: data.chart_config[field].color,
          tooltip: {
            pointFormatter: function () {
              return `${getDisplayName(
                this.series.name
              )}: ${Highcharts.numberFormat(this.y, 2)}`;
            },
          },
        });
      }
    });

    const navigatorData = data.data.map((item) => [
      new Date(item.date).getTime(),
      item[data.chart_config.yAxis0.split(", ")[0]],
    ]);

    const xAxisMin = data.data.length === 1 ? navigatorData[0][0] : undefined;
    const xAxisMax = data.data.length === 1 ? navigatorData[0][0] : undefined;

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series,
      yAxis: [
        {
          ...prevOptions.yAxis[0],
          title: {
            text: `<span class="cm-filter-title">${yAxis0Title}</span>`, // Use HTML to add a class
            useHTML: true, // Enable HTML rendering for the title to allow className
          },
        },
        {
          ...prevOptions.yAxis[1],
          title: {
            text: `<span class="cm-filter-title">${yAxis1Title}</span>`, // Use HTML to add a class
            useHTML: true, // Enable HTML rendering for the title to allow className
          },
        },
      ],
      xAxis: {
        ...prevOptions.xAxis,
        min: xAxisMin,
        max: xAxisMax,
      },
      tooltip: {
        ...prevOptions.tooltip,
        formatter: function () {
          let tooltipText =
            "<b>" +
            (this.points[0].point.display_date ||
              Highcharts.dateFormat("%Y-%m-%d", this.x)) +
            "</b><br/>";
          this.points.forEach((point) => {
            const units = data?.chart_config[point?.series?.name]?.units || "";
            const seriesName = getDisplayName(point.series.name);
            let symbol;
            if (point.series.type === "line") {
              symbol = `<span style="display:inline-block; width:12px; height:2px; background-color:${point.series.color}; margin-right:5px;"></span>`;
            } else if (point.series.type === "column") {
              symbol = `<span style="background-color:${point.series.color}; width:8px; height:8px; display:inline-block; border-radius:50%; margin-right:5px;"></span>`;
            } else {
              symbol = `<span style="background-color:${point.series.color}; width:8px; height:8px; display:inline-block; border-radius:50%; margin-right:5px;"></span>`;
            }

            tooltipText += `${symbol}<span style="color:${point.series.color
              }">${seriesName}:</span> <b>${Highcharts.numberFormat(
                point.y,
                2
              )} ${units}</b><br/>`;
          });

          return tooltipText;
        },
      },
      navigator: {
        ...prevOptions.navigator,
        series: {
          ...prevOptions.navigator.series,
          data: navigatorData,
        },
      },
    }));
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      if (chart && data && data.data.length > 0) {
        const navigatorData = data.data.map((item) => [
          new Date(item.date).getTime(),
          item[data.chart_config.yAxis0.split(", ")[0]],
        ]);
        chart.xAxis[0].setExtremes(
          navigatorData[0][0],
          navigatorData[navigatorData.length - 1][0],
          true,
          false
        );
      }
    }
  }, [chartOptions]);

  const calculateWidth = (categories, containerWidth, minColumnWidth) => {
    let converedWidthByColumns = minColumnWidth * categories + categories * 50;
    return Math.max(containerWidth, converedWidthByColumns);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: toggle
            ? calculateWidth(data.data.length, divWidth, 60)
            : "100%",
          height: "100%",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default ChartComponent;
