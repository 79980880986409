import React, { useState } from "react";
import CustomRangeSlider from "../Components/CustomRangeSlider";
import { Slider } from "@mui/material";
import { styled } from "@mui/system";

const Channel = (props) => {
  console.log("CHannel Props", props);
  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: "#2a2a2a", // Dark background color
    height: 4, // Slightly increased height for better visibility
    padding: "0", // Add padding for shadow visibility

    "& .MuiSlider-thumb": {
      width: 10,
      height: 10,
      backgroundColor: "#d6ff41",
      boxShadow: "2px 2px 4px #1a1a1a, -2px -2px 4px #3a3a3a",
      "&:hover, &.Mui-focusVisible": {
        boxShadow: "4px 4px 8px #1a1a1a, -4px -4px 8px #3a3a3a",
      },
      "&.Mui-active": {
        boxShadow: "inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3a3a3a",
      },
    },

    "& .MuiSlider-track": {
      height: 8,
      backgroundColor: "#d6ff41",
      boxShadow: "inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3a3a3a",
    },

    "& .MuiSlider-rail": {
      height: 8,
      backgroundColor: "black",
      boxShadow: "inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3a3a3a",
    },

    "& .MuiSlider-valueLabel": {
      display: "none",
    },
  }));

  const [direction, setDirection] = useState(null);

  const GetRowSpan = (ListData, Level) => {
    let count = 0;
    for (var i = 0; i < ListData?.length; i++) {
      const MyLevel = Level + "|" + ListData[i].mykey;
      if (props.Levels.includes(MyLevel)) {
        count += GetRowSpan(ListData[i].subchannel, MyLevel);
      }
      count += 1;
    }
    return count;
  };

  const hexToRgb = (hex) => {
    // Remove the '#' if it's there
    hex = hex.replace(/^#/, "");

    // Parse the red, green, blue values
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  };

  const getSuggestedDiff = (item, ele) => {
    const sugg = ele.spends;
    const prev = item.spends;
    const diff = sugg - prev;
    const color = diff > 0 ? (localStorage.getItem("theme") ==="dark"?"#03ff03":"#0a2472") : "red";
    const per = diff === 0 || prev === 0 ? 0 : (diff / prev) * 100;

    return (
      <span style={per !== 0 ? { color: color } : {}}>
        {per.toFixed(1)}% ({diff.toFixed(1)})
      </span>
    );
  };

  const GetNewFixedDataIndex = (item, NewFixedData) => {
    for (var i = 0; i < NewFixedData?.length; i++) {
      if (NewFixedData[i].mykey === item.mykey) {
        return NewFixedData[i];
      }
    }
    return "";
  };

  const GetNewDataIndex = (item, NewData) => {
    console.log("GetNewDataIndex ", item, " ", NewData);
    for (var i = 0; i < NewData?.length; i++) {
      if (NewData[i].mykey === item.mykey) {
        return NewData[i];
      }
    }
    return "";
  };

  const GetChannelData = (ListData, NewFixedData, NewData, Level) => {
    console.log("props NewData ", NewData);
    const theme = localStorage.getItem("theme")
    return ListData.map((item, index) => {
      // console.log("item.mykey: ", item.mykey);
      const MyLevel = Level + "|" + item.mykey;

      const NewFixedDataLevel = GetNewFixedDataIndex(item, NewFixedData);
      // console.log("NewFixedDataLevel: ", NewFixedDataLevel);

      const NewDataLevel = GetNewDataIndex(item, NewData);
      // console.log("NewDataLevel: ", NewDataLevel);
      const diff = getSuggestedDiff(item, NewFixedDataLevel);
      // console.log(NewFixedDataLevel)
      // console.log(item)
      const colorList = [
        "rgba(38,50,56, 0)",
        "rgba(40,60,80, 0.8)",
        "rgba(60,80,100, 0.2)",
        "rgba(38,50,56, 0.3)",
        "rgba(38,50,56, 0.4)",
        "rgba(38,50,56, 0.5)",
        "rgba(38,50,56, 0.6)",
        "rgba(38,50,56, 0.7)",
        "rgba(38,50,56, 0.8)",
        "rgba(38,50,56, 0.9)",
      ];
      // "#546E7A",
      //         "#607D8B",
      //         "#78909C",
      //         "#90A4AE",
      //         '#B0BEC5'
      const colors =
        localStorage.getItem("CurrentTheme") === "Dark"
          ? [
            "transparent",
            "#455A64",
            "#37474F",
            "#263238",
            "#263238",
            "#263238",
            "#263238",
            "#263238",
            "#263238",
          ]
          : // colorList :
          [
            "transparent",
            "#FFFFFF",
            "#F0F0F0",
            "#EAEAEA",
            "#D3D3D3",
            "#C0C0C0",
            "#A9A9A9",
            "#808080",
            "#696969",
          ];

      console.log(
        "MYLEVEL ",
        MyLevel,
        " ",
        MyLevel.split("|").length,
        "  ",
        colors[MyLevel.split("|").length - 2]
      );

      return (
        <React.Fragment key={index}>
          <tr
            style={{
              // backgroundColor: `rgba(${hexToRgb(
              //   colors[MyLevel.split("|").length - 2]
              // )}, ${0.1 * MyLevel.split("|").length})`,

              // marginLeft: `${0.5 * (MyLevel.split("|").length - 1)}rem`,
              // border:"1px solid white"
            }}
          >
            {!Level.includes("|") && index === 0 ? (
              <td
                // style={{ border:"1px solid red"}}
                className="digital-title"
                rowSpan={GetRowSpan(
                  props.Summary.channels[props.channel],
                  props.channel
                )}

              >
                <p
                  style={{
                    borderRight:
                      "1px solid " +
                      props.Summary.channels[props.channel][0].color,
                    color: props.Summary.channels[props.channel][0].color,

                  }}
                >
                  {/* <span>{props.channel}</span> */}
                  <span className="span-self">Self</span>

                </p>
              </td>
            ) : null}
            <td
              style={{

                position: "sticky", // Makes it sticky
                background: '#1c2427',
                height: '100%',
                left: 0, // Keeps it at the start
                zIndex: 1


              }}
            >
              <div
                style={{

                  // zIndex: 9999, // Ensures it stays on top of other elements
                }}
              >
                <td className="text-center text-colors"

                >
                  {"subchannel" in item ? (
                    !props.Levels.includes(MyLevel) ? (
                      <span
                        className="text-white text-colors fas fa-plus mr-2 custom-table-toggle-icon icon-class2"
                        style={{
                          cursor: "pointer", fontWeight: "bold", // Adjust the marginLeft here
                          marginLeft: `${0.5 * (MyLevel.split("|").length - 1)}rem`,
                        }}

                        onClick={() => {
                          setDirection("+");
                          props.AddLevel(MyLevel);
                        }}
                      >
                        {/* + */}
                      </span>
                    ) : (
                      <span
                        className="text-white text-colors fas fa-minus mr-2 custom-table-toggle-icon icon-class2"
                        style={{
                          cursor: "pointer", fontWeight: "bold",
                          marginLeft: `${0.5 * (MyLevel.split("|").length - 1)}rem`,
                        }}
                        onClick={() => {
                          setDirection("+");
                          props.RemoveLevel(MyLevel);
                        }}
                      >
                        {/* - */}
                      </span>
                    )
                  ) : <span
                    style={{
                      marginLeft: `${0.52 * (MyLevel.split("|").length - 1)}rem`,
                    }}
                  >&nbsp;&nbsp;</span>}
                </td>
                <td
                  className="text-left text-colors"
                  style={{
                    color: item.color,
                    opacity: !props.AllowEdit ? 1 : 1,
                    // border:"1px solid red",

                    pointerEvents: !props.AllowEdit ? "all" : "none",
                    marginLeft: `${0.5 * (MyLevel.split("|").length - 1)}rem`,
                  }}
                >
                  {console.log("marginLeft", item.name, " ", 0.5 * (MyLevel.split("|").length - 1)) || item.name}
                </td>
              </div>
            </td>
            {props.AllowComp && <> <td
              className=""
              style={{
                opacity: !props.AllowEdit ? 1 : 1,
                pointerEvents: !props.AllowEdit ? "all" : "none",
              }}
            >
              {item.spends}
            </td>
              <td
                className="text-center text-colors"
                style={{
                  opacity: !props.AllowEdit ? 1 : 1,
                  pointerEvents: !props.AllowEdit ? "all" : "none",
                  // border:"1px solid red"
                }}
              >
                <span>{diff}</span>
              </td>
            </>}
            <td
              className="text-center text-colors text-white"
              style={{
                color: "var(--bs-theme-color)",
                opacity: !props.AllowEdit ? 1 : 1,
                pointerEvents: !props.AllowEdit ? "all" : "none",
              }}
            >
              {NewFixedDataLevel.spends}
            </td>
            {/* input */}
            <td
              className="text-center text-colors d-flex"
              style={{
                opacity: props.AllowEdit ? 1 : 0.7,
                pointerEvents:
                  props.AllowEdit && NewFixedDataLevel.fillflag
                    ? "all"
                    : "none",
              }}
            >
              {
                //   console.log(props.flattened[MyLevel.split("|").pop()][7])
              }
              {NewDataLevel.spends !== NewFixedDataLevel.spends ? (
                <span
                  className="mx-2 cursor-pointer primary-text"
                  onClick={() => {
                    // console.log("key while refresh: ", NewDataLevel.mykey);
                    props.setUserInputKey(NewDataLevel.mykey);
                    props.traverse(MyLevel, 0, false);
                    props.EditNewSpends(MyLevel, NewFixedDataLevel.spends);
                    props.setIsExecuted(true);
                  }}
                >
                  &#x21bb;
                </span>
              ) : (
                <span className="mx-2">&nbsp;&nbsp;</span>
              )}

              <input
                type="text"
                className={
                  NewDataLevel.spends !== NewFixedDataLevel.spends
                    ? "cm-filter-title form-control text-colors"
                    : "cm-filter-title form-control text-colors"
                }
                style={{
                  // height: "20px",
                  width: "80px",
                  // fontSize: "10px",
                  padding: "0",
                  textAlign: "center",
                }} // Corrected style object syntax
                value={NewDataLevel.spends}
                // value={props.flattened[MyLevel.split("|").pop()][7]}
                onChange={(e) => {
                  props.EditNewSpends(MyLevel, e.target.value);
                }}
                onBlur={(e) => {
                  // props.EditNewSpendsOnBlur(props.channel, ChannelData.index, e.target.value);
                  if (NewDataLevel.spends !== parseFloat(e.target.value)) {
                    props.EditNewSpendsOnBlur(
                      MyLevel,
                      parseFloat(e.target.value)
                    );
                  }
                }}
              />
            </td>
            {/* range input */}
            <td
              style={{
                opacity: props.AllowEdit ? 1 : 0.5,
                pointerEvents:
                  props.AllowEdit && NewFixedDataLevel.fillflag
                    ? "all"
                    : "none",
                minWidth: "200px",
              }}
            >
              <div className="">
                <input
                  type="range"
                  className="input_range"
                  min={NewDataLevel.min_val}
                  max={NewDataLevel.max_val}
                  step={0.1}
                  value={NewDataLevel.spends}
                  onChange={(e) => {
                    props.EditNewSpends(MyLevel, e.target.value);
                  }}
                  onBlur={(e) => {
                    props.EditNewSpendsOnBlur(
                      MyLevel,
                      parseFloat(e.target.value)
                    );
                    console.log("item.fillflag: ", item.mykey, item.fillflag);
                  }}
                  // Custom inline styles
                  style={{
                    width: "100%", // Full width of the slider
                    appearance: "none", // Remove default browser styles
                    height: "5px", // Custom height
                    // backgroundColor: "black", // Rail background color
                    borderRadius: "5px", // Rounded edges
                    outline: "none",
                    padding: "0",
                    margin: "0",

                    // boxShadow:
                    //   "inset 2px 2px 4px #1a1a1a, inset -2px -2px 4px #3a3a3a", // Shadow for the rail
                    cursor: "pointer",
                    background: `linear-gradient(to right, ${theme === "dark" ? "#d6ff41" : "#00B4D8"
                      } 0%, ${theme === "dark" ? "#d6ff41" : "#00B4D8"
                      } ${(NewDataLevel.spends - NewDataLevel.min_val) /
                      (NewDataLevel.max_val - NewDataLevel.min_val) * 100}%, ${theme === "dark" ? "#3e5056" : "#caf0f8"} ${(NewDataLevel.spends - NewDataLevel.min_val) /
                      (NewDataLevel.max_val - NewDataLevel.min_val) * 100
                      }%, ${theme === "dark" ? "#3e5056" : "#caf0f8"} 100%)`,
                  }}
                  // Custom thumb styles (with pseudo-element styles)
                  onMouseOver={(e) => {
                    // e.target.style.boxShadow =" none "
                    //   "2px 2px 4px #d6ff41, -2px -2px 4px #d6ff41"; // Thumb hover shadow
                  }}
                  onMouseOut={(e) => {
                    // e.target.style.boxShadow = "none"; // Reset shadow when the mouse leaves
                  }}
                />

                {/* <CustomSlider
                  min={NewDataLevel.min_val}
                  max={NewDataLevel.max_val}
                  step={0.01}
                  value={NewDataLevel.spends}
                  onChange={(e, newValue) => {
                    // Log the new value when the slider is being dragged
                    console.log("Slider value changing:", newValue);
 
                    // Update the spends value
                    props.EditNewSpends(MyLevel, newValue);
                  }}
                  onChangeCommitted={(e, newValue) => {
                    // Log the final value when the slider thumb is released
                    console.log("Slider value committed:", newValue);
 
                    // Update the spends value on blur
                    props.EditNewSpendsOnBlur(MyLevel, parseFloat(newValue));
 
                    // Log additional information for debugging
                    console.log("item.fillflag:", item.mykey, item.fillflag);
                  }}
                //   valueLabelDisplay="auto" // Shows the current value in a label when moving the slider
                /> */}

                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0"
                  // style={{ fontSize: "10px" }}
                  >
                    {NewDataLevel.min_val}
                  </p>
                  <p className="m-0"
                  //  style={{ fontSize: "10px" }}
                  >
                    {NewDataLevel.max_val}
                  </p>
                </div>
              </div>
            </td>
          </tr>
          {"subchannel" in item && props.Levels.includes(MyLevel)
            ? GetChannelData(
              item.subchannel,
              NewFixedDataLevel.subchannel,
              NewDataLevel.subchannel,
              MyLevel
            )
            : null}
        </React.Fragment>
      );
    });
  };

  return (
    <>

      {GetChannelData(
        props.Summary.channels[props.channel],
        props.NewFixedData?.channels[props.channel],
        props.NewData.channels[props.channel],
        props.channel
      )}
    </>
  );
};

export default Channel;