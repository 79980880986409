import { styled } from "@mui/material/styles";

const InputText = styled("textarea")({
  height: "25px",
  outline: "none",
  border: "none",
  // backgroundColor: "#1c2427",
  borderRadius: "10px",
  // color: "white",
  // fontSize: "1.1rem",
  padding: "0.4rem 1rem 0 1rem",
  resize: "none",
  fontFamily: "sans-serif",
   lineHeight: '1.5',
  flex: 1
});

export default InputText;
