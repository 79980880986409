import React from 'react'

const Allocation = (props) => {

    const getHeight = (AllocationData, y) => {
        var Total = 0
        for (var i = 0; i < AllocationData.length; i++) {
            Total += AllocationData[i].y
        }
        
        // const Total = AllocationData.reduce(
        //     (previousValue, currentValue) => parseFloat(previousValue.y) + parseFloat(currentValue.y)
        // );
        return y / Total * 100
    }

    const theme = localStorage.getItem("theme");
    console.log("props.EditedAllocation",props.EditedAllocation)

    return (
        <div className={props.AllowEdit ? "allocation-main-div col-xs-6 col-md-6 col-lg-6 col-xl-2" : "allocation-main-div col-xs-6 col-md-6 col-lg-6 col-xl-1"}
        style={{height:'calc(100%)'}}
        
        >
            <div 
            // className="card mb-0 optimizer-table-height"
            className="card card-min-height optimizer-table-height card-body brand-card pt-2 "
            >
                <div className="card-body" style={{padding:0}}>
                    <div className="row">
                        <div className="col-sm-12 text-center p-0">
                            <h3 className="data-spends">Allocation</h3>
                        </div>
                    </div>
                    <div className="justify-content-center allocation-div" >
                        <div className="col-sm-6 text-center" >
                            {
                                props.AllowEdit ? <h3 className="data-spends  px-2" style={{ wordWrap: "normal" }}>Channel</h3> : null
                            }
                            <div className="bar-chart d-flex justify-content-center">

                                <div className="allcation-layout" >
                                    {
                                        props.SuggestedAllocation.map((item, index) => {
                                            return (
                                                <div
                                                    className="channel"
                                                    key={index}
                                                    title={item.name}
                                                    style={{
                                                        height: getHeight(props.SuggestedAllocation, item.y) + '%',
                                                        // background: item.color,
                                                        background:  theme === "dark" ? item.color : "#0a2472",
                                                        fontSize:'10px',
                                                    }} ><span>{parseFloat(item.y).toFixed(2)}</span></div>
                                            )
                                        })
                                    }
                                    {/* <div className="Channel-1" ></div>
                                    <div className="Channel-2" ></div> */}
                                </div>
                            </div>
                        </div>

                        {
                            props.AllowEdit ?
                                <div className="col-sm-6 text-center">
                                    <h3 className="data-spends  px-2">User</h3>
                                    <div className="bar-chart d-flex justify-content-center">
                                        <div className="allcation-layout" >
                                            {props.EditedAllocation.map((item, index) => {
                                                console.log("item colorrrr", item.color)
                                                return (
                                                    <>
                                                    <div
                                                        className="channel"
                                                        key={index}
                                                        title={item.name}
                                                        style={{
                                                            height: getHeight(props.EditedAllocation, item.y) + '%',
                                                            background: item.color,
                                                            // background:'#0a2472',
                                                            fontSize:'10px',
                                                            // border:"1px solid red",
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        </div>
                                                        <div className='col-sm-12'>
                                                        <span className='channel allocation-edit-text' style={{fontSize:'10px',textAlign:'center',padding:'3px'}}>
                                                            {parseFloat(item.y).toFixed(2)}
                                                        </span>
                                                    </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>

                                </div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allocation