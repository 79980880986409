import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Added useLocation to track route changes
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Removed unused FaCheckCircle

const MenuNavigator = () => {
  const Analyse1 = JSON.parse(localStorage.getItem("Analyse")) || undefined;
  const menuItems = Analyse1?.Menubar || [];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation(); // To track changes in route

  // Flatten the menu structure into a single list for easier navigation
  const flattenedMenu = menuItems.flatMap((item) =>
    item.children && item.children.length > 0
      ? item.children.map((child) => ({
          id: child.id,
          name: child.name,
          new_id: child.new_id,
          isChild: true,
        }))
      : [{ id: item.id, name: item.name, new_id: item.new_id, isChild: false }]
  );

  // Function to handle left (previous) navigation
  const handleLeftNavigation = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : flattenedMenu.length - 1;
      navigate(`/${flattenedMenu[newIndex].new_id}`); // Navigate to the new item
      return newIndex;
    });
  };

  // Function to handle right (next) navigation
  const handleRightNavigation = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex < flattenedMenu.length - 1 ? prevIndex + 1 : 0;
      navigate(`/${flattenedMenu[newIndex].new_id}`); // Navigate to the new item
      return newIndex;
    });
  };

  // Sync current item with the URL path
  useEffect(() => {
    const currentPath = location.pathname.split("/")[1]; // Get the current path
    const newIndex = flattenedMenu.findIndex((item) => item.new_id === currentPath);
    if (newIndex !== -1) {
      setCurrentIndex(newIndex); // Update currentIndex based on the route
    }
  }, [location.pathname, flattenedMenu]);

  // Get the current menu item to display based on currentIndex
  const currentItem = flattenedMenu[currentIndex];
  const isActive = window.location.pathname === `/${currentItem.new_id}`;

  return (
    <div className="menu-navigator">
        <div>
      <button className="nav-button left" onClick={handleLeftNavigation}>
        <FaArrowLeft className="text-colors"/>
      </button>
      </div>
      
      <div key={currentItem.id} className="menu-item">
        <span
          className={`h-menu-link ${isActive ? "active" : ""}`}
        >
        {currentItem.name}
        </span>
      </div>
      <div>
      <button className="nav-button right" onClick={handleRightNavigation}>
        <FaArrowRight className="text-colors"/>
      </button>
      </div>
    </div>
  );
};

export default MenuNavigator;
