import React, { useState, useEffect } from "react";
import axios from "../Axios";
import Sankey from "../Components/Sankey";
import Loader from "../Components/Loader";

const KpiPrioritization = () => {
  const [loader, setLoader] = useState(false);
  const [sankeyData, setSankeyData] = useState([]);
  const [nodeData, setNodeData] = useState([]);
  const [levelData, setLevelData]= useState([]);
  const [originalSankeyData, setOriginalSankeyData] = useState([]);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  // const arr = ["Level-1", "Level-2", "Level-3", "Level-4"];

  useEffect(() => {
    const fetchData = () => {
      setLoader(true);

      axios.post('/get_kpi_prioritization/', {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        theme : localStorage.getItem("theme")
      })
      .then(response => {
        // Extract data according to the given response structure
        console.log("respons1234e", response)
        const sankeyLinks = response.data.data.data.data;  // Array of links between nodes
        const sankeyNodes = response.data.data.data.nodes; // Array of nodes with properties
        const levelNames = response.data.data.data.level_name;
        
        setSankeyData(sankeyLinks);
        setNodeData(sankeyNodes);
        setOriginalSankeyData(sankeyLinks);
        setLevelData(levelNames);
      })
      .catch(error => {
        console.error("Error fetching KPI prioritization data:", error);
      })
      .finally(() => {
        setLoader(false);
      });
    };

    fetchData();
  }, []);  // Removed dependencies to avoid re-fetching on state changes

  return (
    <div className="skewb-div">
      {loader && <Loader />}
      <div className="main-content-div">
        <div className="page-content-div">
          <div className="card card-min-height optimizer-table-height card-body brand-card pt-4">
            <div className="text-colors cm-filter-title">
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                marginRight: '12px'
              }}>
                {levelData.map((item, index) => (
                  <div 
                  // className="cm-filter-title" 
                  key={index} style={{ textAlign: "center" }}>
                    {item}
                  </div>
                ))}
              </div>
              <div 
              // style={{border:"1px solid red", height:"calc(100% - 80px)"}}
              >
              <Sankey originalSankeyData={originalSankeyData} setOriginalSankeyData={setOriginalSankeyData} sankeyData={sankeyData} nodeData={nodeData} setSankeyData={setSankeyData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiPrioritization;
