import React from 'react'
import Download from "../images/download-icon.png";

const FooterOptimise = (props) => {
    return (
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                {/*<th></th>
                <th></th> */}
                {/* <th></th> */}
                <th colSpan="5">
                    <div className='d-flex justify-content-sm-start gap-2 mb-2s'>
                        {JSON.stringify(props.NewFixedData) === JSON.stringify(props.NewData) ? (
                            // <button className="btn-class-clicked" type="button" style={{ margin: 0 }} disabled={true}>
                            //     Re-Optimise
                            // </button>
                            null
                        ) : (
                            <button
                                onClick={() => {
                                    props.Optimise()
                                    props.setRe(1);
                                }}
                                className="btn-class"
                                type="button"
                                // style={{ padding: '5px 10px' }}
                            >
                                Re-Optimise
                            </button>
                        )}
                        <button
                            className="btn-class"
                            type="button"
                            // onClick={() => props.AllowEdit ?  props.setSaveResult(true) : ""}
                            onClick={() => props.setSaveResult(true)}
                            // style={{ padding: '5px 10px' }}
                        >
                            Save Result
                        </button>

                        


                    </div>
                </th>
            </tr>
        </tfoot>
    )
}

export default FooterOptimise