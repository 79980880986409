import React, { useEffect, useState, useRef } from "react";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import axiosDownload from "../Axios"; // download url will be change 
import ItemWise from "../Components/ItemWise";
// import AggregateTotalView from "../Components/AggregateTotalView";
import Loader from "../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../images/download-icon.png";
import { Link } from "react-router-dom";
import CustomSelect from "../Components/CustomSelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllHeirarchyFilter from "../Components/HeirarchyFilter";
// import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import ChannelFilter from '../Components/ChannelFilter';
import axiosChartDownload from "../Axios"; // download url will be change 
import { Tooltip } from 'react-tooltip'
import InfoIcon from "../images/icons8-info.svg";


import LightThemeHexCode from "../Components/LightThemeColorCode";
import downloadFlag from "../DownloadFlag";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");

  const [Summary, setSummary] = useState([]);
  const [ChartHeight, setChartHeight] = useState('');
  const [Notes, setNotes] = useState("");

  const [FooterNotes, setFooterNotes] = useState('');

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);
  const IsAllEnabled = 0;

  //Filters
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const CampaignAnalyticFilter = JSON.parse(localStorage.getItem("CampaignAnalyticFilter"));
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [Chartdata, setChartdata] = useState({});

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const Fixed = localStorage.getItem("AnalyticFilter") ? AnalyticFilter.Fixed : [];

  //Comp filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  const [FilterTabShow, setFilterTabShow] = useState(false);

  const [changeFilter, setChangeFilter] = useState(false);

  const [ShowSummary, setShowSummary] = useState(0)
  const [ResetFilterFlag, setResetFilterFlag] = useState(false)
  const [categoryDataFetched, setCategoryDataFetched] = useState(false);
  // console.log("categoryDataFetched: ", categoryDataFetched);


  const userName = localStorage.getItem("NameSkewb");

  // New states..............
  const [xAxis, setXAxis] = useState([]);
  // console.log("xAxis: ", xAxis);
  const [yAxis, setYAxis] = useState([]);
  const [xAxisTitle, setXAxisTitle] = useState("");
  const [yAxisTitle, setYAxisTitle] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const [legends, setLegends] = useState([]);
  const [showOpenLevel, setShowOpenLevel] = useState(0);

  // console.log("showOpenLevel: ", showOpenLevel);





  useEffect(() => {
    const url = window.location.href;
    const text = url.split('/');
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse])

  useEffect(() => {
    const localAnalyticFilter = JSON.parse(localStorage.getItem('StoreAnalyticFilter'));
    const localCampaignAnlayticFilter = JSON.parse(localStorage.getItem('StoreCampaignAnalyticFilter'));
    setAllFilters(localAnalyticFilter.AllFilters);
    setActiveFilters(localAnalyticFilter.ActiveFilters);
    setAnalyticFilterFlag(false);
    setCompActiveFilters(localCampaignAnlayticFilter.CompActiveFilters);
    setCompAllFilters(localCampaignAnlayticFilter.CompAllFilters)
  }, [Analyse.Brand, Analyse.KPI]);


  useEffect(() => {
    axios
      .post("get_synergy_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs);
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab);
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    axios
      .post("get_timeframes/", {
        // page: ActiveTab !== "AggregateTotalView" ? "synergy_item" : "synergy_aggr",
        page: "synergy_item",
        brand: Analyse.Brand,
        kpi: Analyse.KPI
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          // setFetchCall(true)
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);


  useEffect(() => {
    if (timeframe) {
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "synergy",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory('');
            }
            setFetchCall(true);
            setCategoryDataFetched(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
      // setFetchCall(true);
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, timeframe])


  // old data api call.............................................
  useEffect(() => {
    setLoader(true);
    // console.log("inside useeffect synergy effects")
    if (timeframe && ActiveTab && FetchCall && categoryDataFetched && JSON.stringify(Chartdata) !== "{}") {
      // console.log("inside api call synergy effects")
      axios
        .post("get_synergy_effects/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          // tab: ActiveTab !== "AggregateTotalView" ? "item" : "aggr",
          tab: ActiveTab,
          timeframe: timeframe,
          category: ChannelCategory,
          chartdata: Chartdata,
          is_all_enabled: IsAllEnabled,
          theme: localStorage.getItem("theme"),
          LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

        })
        .then((response) => {
          // console.log(response);
          if (response.data.error !== 1) {
            setXAxis(response.data.data.xaxis);
            setYAxis(response.data.data.yaxis);
            setXAxisTitle(response.data.data.xtitle);
            setYAxisTitle(response.data.data.ytitle);
            setChartTitle(response.data.data.charttitle);
            setLegends(response.data.data.legends);
            setFooterNotes(response.data.data.footernotes);
            setSummary(response.data.data.summary);
            setShowSummary(response.data.data.show_summary);
            setShowOpenLevel(response.data.data.show_level);
          } else {
            toast.error(response.data.erroMsg);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [categoryDataFetched, Analyse.Brand, Analyse.KPI, ActiveTab, timeframe, ChannelCategory, FetchCall, Chartdata, IsAllEnabled]);

  // New api call start.....................................................
  // useEffect(() => {
  //   const fetchData = async () => {
  //      try {
  //       setLoader(true);
  //       const response = await axios.post("get_synergy_effects/", { 
  //         brand: Analyse.Brand,
  //         kpi: Analyse.KPI,
  //         tab: ActiveTab,
  //         timeframe: timeframe,
  //         category: ChannelCategory,
  //         chartdata: Chartdata,
  //         is_all_enabled: IsAllEnabled,
  //         theme: localStorage.getItem("theme"),
  //         LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

  //        });
  //       //  console.log("response.data.data: ", response.data.data);
  //        setXAxis(response.data.data.xaxis);
  //        setYAxis(response.data.data.yaxis);
  //        setXAxisTitle(response.data.data.xtitle);
  //        setYAxisTitle(response.data.data.ytitle);
  //        setChartTitle(response.data.data.charttitle);
  //        setLegends(response.data.data.legends);
  //        setFooterNotes(response.data.notes);
  //        setSummary(response.data.data.summary);
  //        setShowSummary(response.data.data.show_summary);
  //        setLoader(false);
  //      } catch {
  //         setLoader(false);
  //      }       
  //   }


  //   if(timeframe && ActiveTab && FetchCall && categoryDataFetched && JSON.stringify(Chartdata) !== "{}") {
  //     fetchData();
  //   }
  // }, [categoryDataFetched, Analyse.Brand, Analyse.KPI, ActiveTab, timeframe, ChannelCategory, FetchCall, Chartdata, IsAllEnabled])
  // New api call end.......................................................
  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("get_synergy_effects/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        // tab: ActiveTab !== "AggregateTotalView" ? "item" : "aggr",
        tab: ActiveTab,
        timeframe: timeframe,
        category: ChannelCategory,
        chartdata: Chartdata,
        is_all_enabled: IsAllEnabled,
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],
        download: 1
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };


  const DownloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await axiosDownload.post("get_synergy_effects/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        // tab: ActiveTab !== "AggregateTotalView" ? "item" : "aggr",
        tab: ActiveTab,
        timeframe: timeframe,
        category: ChannelCategory,
        chartdata: Chartdata,
        is_all_enabled: IsAllEnabled,
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],
        download: 1,
        download_type: "PDF",
      });

      // console.log("excel: ", response.data);
      const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
        images: [],
        document: {
          table1_data: response.data.table1_data,
          titlename: "Cross Channel Synergy",
          footer_content: "Private & Confidential",
          table_heading1: "Cross Channel Synegry",
          username: userName,
          timeframe: timeframe,
          start_date: "",
          end_date: "",
          category: "",
          brand: Analyse.Brand,
          theme : localStorage.getItem("theme"),
          kpi: Analyse.KPI,
          chartdata: Chartdata,
          tabname: "synergy"
        },
      });
      if (pdfResponse.data.error === 1) {
        toast.error(pdfResponse.data.erroMsg);
        setLoader(false);
      } else {
        // console.log("response pdf: ", pdfResponse.data);
        setLoader(false);
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = pdfResponse.data.pdf_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error while generating pdf:", error);
    }
  };

  //   // When Activefilter change : prepare chartdata for input of get api call
  //   useEffect(() => {
  //     const chartdata = {};
  //     if (Fixed.length !== 0) {
  //       for (var r = 0; r < Fixed.length; r++) {
  //         const Filter = [];
  //         Filter.push(Fixed[r].value)
  //         chartdata[Fixed[r].id] = {
  //           selection: [],
  //           Filter: Filter,
  //         };
  //       }
  //     }

  //     if (ActiveFilters.length !== 0) {
  //       for (var i = 0; i < ActiveFilters.length; i++) {
  //         const selection = [];
  //         if ("Selected" in ActiveFilters[i]) {

  //           if (ActiveFilters[i]["Selected"].length > 0) {
  //             setResetFilterFlag(true)
  //           }

  //           for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
  //             var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
  //             selection.push(temp_arr[temp_arr.length - 1]);
  //           }
  //         }

  //         // chartdata[ActiveFilters[i].name] = {
  //         //   selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
  //         //   // Filter: selection,
  //         //   Filter: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].Filter : [],
  //         // };

  //         chartdata[ActiveFilters[i].name] = {
  //           selection: [],
  //           // Filter: selection,
  //           Filter: [],
  //         };
  //       }
  //     }

  //     for (var k = 0; k < CompActiveFilters.length; k++) {
  //       const selection = [];
  //       if ("Selected" in CompActiveFilters[k]) {
  //         if (CompActiveFilters[k]["Selected"].length > 0) {
  //           setResetFilterFlag(true)
  //         }
  //         for (var l = 0; l < CompActiveFilters[k].Selected.length; l++) {
  //           selection.push(CompActiveFilters[k].Selected[l].value);
  //         }
  //       }
  //       chartdata[CompActiveFilters[k].name] = {
  //         selection: [],
  //         Filter: selection,
  //       };
  //     }

  //     setChartdata(chartdata);
  //     setFetchCall(true);
  //     setSelectedCheckboxes([]);
  //     setSelectedCheckboxesC([]);
  //   }, [ActiveFilters, CompActiveFilters]);

  useEffect(() => {
    console.log("resultt first time before ");
    if (changeFilter === false) {
      const chartdata = JSON.parse(localStorage.getItem('Chartdata'));
      console.log("resultt first time ", chartdata);
      setChartdata(chartdata);
      // setIsChartdataReady(true);
      setFetchCall(true);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesC([]);
    }
  }, [Analyse.Brand, Analyse.KPI]);

  //HEIRARCHY FILTERS 

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let filter = AllFilters[index];

    console.log("Add H")
    // Assuming you want to select the first filter by default as in AddCompFilter
    // filter.Selected = [filter?.filters[0]];

    ActiveFiltersTemp.push(filter);
    setActiveFilters(ActiveFiltersTemp);
    setFilterTabShow(true);

    // Update the localStorage with the new filter data
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };



  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    updateChartData();
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    updateChartData();
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }





  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [FetchCall])


  const [HFilters, setHFilters] = useState([]);
  console.log("HFilters: ", HFilters);
  console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(Chartdata).filter(key => !key.startsWith('C'));



  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
          const response = await axios.post(
            "hieracrhy_data/",
            { hierarchy_list: filteredKeys, brand: Analyse.Brand }
          );

          const data = response.data;
          // console.log(data);
          setHFilters(data.data);
          // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchHierarchyFilters();
  }, [Chartdata, Analyse.Brand])

  const [CFilters, setCFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeysC = Object.keys(Chartdata).filter(key => !key.startsWith('H'));



  useEffect(() => {
    const fetchChannelFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
          const response = await axios.post(
            "channel_hierarcy/",
            { channel_list: filteredKeysC, brand: Analyse.Brand }
          );

          const data = response.data;
          // console.log(data);
          setCFilters(data.data);
          // localStorage.setItem("channelFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchChannelFilters();
  }, [Chartdata, Analyse.Brand])

  const updateChartData = (changeFilter = [], channelFilter = []) => {
    // Initialize the result object
    const result = {};
    console.log("resultt updatedChartData");
    const filterdata = JSON.parse(localStorage.getItem('AnalyticFilter'));
    const ChannelFilterData = JSON.parse(localStorage.getItem('CampaignAnalyticFilter'));

    // Populate the result object with Fixed filters
    filterdata.Fixed.forEach((item) => {
      result[item.id] = {
        selection: [],
        Filter: [item.value]
      };
    });

    if (changeFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      filterdata.ActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: []
          };
        }
      });
    }
    else {
      changeFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: []
          };
        }
      });
    }

    if (channelFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      ChannelFilterData.CompActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: []
          };
        }
      });
    }
    else {
      channelFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: []
          };
        }
      });

    }
    console.log("resultt ", result, " ", Chartdata);
    setFetchCall(true);
    setChartdata(result);
    setChangeFilter(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
  }


  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setIsExpanded(false);
      } else {
        setIsExpanded(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call it initially to set the correct state based on the initial window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const refChartDiv = useRef(null);

  const [windowHeight, setWindowHeight] = useState(0); // Default height value
  useEffect(() => {

    if (refChartDiv.current) {

      const calculatedHeight = window.innerHeight - refChartDiv.current.getBoundingClientRect().top - 100;
      // refChartDiv.current.style.background="black";
      console.log("Chart Height", window.innerHeight, "    ", refChartDiv.current.getBoundingClientRect().top);
      window.innerWidth < 1000 ? setWindowHeight(Math.max(500, calculatedHeight)) : setWindowHeight(Math.max(0, calculatedHeight));
    }

  }, [refChartDiv.current])

  return (
    <div className="skewb-div">
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      {/* <Topbar />
      <Menubar /> */}
      <div className="main-content-div">
        <div className="page-content-div">
          <div className="select-filter-dropdown-div">
            <div className="filter-dropdown" onClick={() => setIsExpanded(!isExpanded)}
            >
              <span className="text-small"><i
                className="fas fa-filter"
                style={{ marginRight: "5px", fontSize: '.8rem', }} // Adds spacing and adjusts icon size
              ></i> Select Filter</span>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontSize: '.8rem',
                  cursor: 'pointer',
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                  transition: 'transform 0.3s ease'
                }}
              >
                ▼
              </button>
            </div>
            {isExpanded &&

              <div className="filter-class">
                <AllHeirarchyFilter OnlyHeirarchyFilter={false} Fixed={Fixed} ActiveFilters={ActiveFilters} AllFilters={AllFilters} AddHeirarchyFilter={AddHeirarchyFilter} RemoveHeirarchyFilter={RemoveHeirarchyFilter} IsInAllHeirarchyFilters={IsInAllHeirarchyFilters} Chartdata={Chartdata} setChartdata={setChartdata} setFetchCall={setFetchCall} HFilters={HFilters}
                  setActiveFilters={setActiveFilters}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes} />
                <ChannelFilter CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} AddCompFilter={AddCompFilter} RemoveCompFilter={RemoveCompFilter} IsInAllCompFilters={IsInAllCompFilters} Chartdata={Chartdata} setChartdata={setChartdata} setFetchCall={setFetchCall}
                  setCompActiveFilters={setCompActiveFilters}
                  CFilters={CFilters}
                  selectedCheckboxesC={selectedCheckboxesC}
                  setSelectedCheckboxesC={setSelectedCheckboxesC}
                />

              </div>
            }</div>



          <div
            className="container-fluid pt-1"
            style={{ paddingTop: "10px" }}
          >
            <div className="row row-div-class">
              {/* {
                false ?
                  <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onCompChangeFilter} onChangeFilter={onChangeFilter} onDragEnd={onDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onCompDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed}
                    HFilters={HFilters}
                    setActiveFilters={setActiveFilters}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                  />
                  : null
              } */}
              <div className={parseInt(ShowSummary) === 1 ? "col-xl-9 row-div-class" : "col-xl-12 row-div-class"}>
                <div
                  className="card card-min-height optimizer-table-height card-body brand-card pt-2"
                // className="card card-min-height synergy-table-height"
                //  style={{ height: `${window.innerHeight < 850 ? window.innerHeight * 0.82 : window.innerHeight * 0.87}px`}}
                >
                  {/* <div className="card-body brand-card ef-drivers " ref={refGraphDiv}  */}
                  <div className="ef-drivers " ref={refGraphDiv}
                    // padding: '10px'
                    style={{ background: 'transparent' }}>
                    <div className="row align-items-center justify-content-sm-between">
                      <div className="col-sm-4 d-flex gap-2">
                        <CustomSelect
                          value={timeframe}
                          onChange={(e) => {
                            setTimeframe(e.target.value);
                            setChannelCategory("");
                            // setCategoryFlag(true);
                          }}
                          options={TimeFramesAll.map(item => ({ key: item.key, value: item.value }))}
                          isDetailPopUp={false}
                        />

                        {AllChannelCategory.length !== 0 && AllChannelCategory.length > 1 ? (



                          <CustomSelect
                            value={ChannelCategory}
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                            }}
                            options={AllChannelCategory.map(item => ({ key: item.value, value: item.label }))}
                            isDetailPopUp={false}
                          />


                        ) : null}
                      </div>
                      <div className="col-sm-5">
                        <p className="card-title-lable" style={{
                          paddingLeft: "95px"
                        }}>{chartTitle}</p>
                      </div>

                      {/* <div className="col-sm-3 d-flex align-items-center justify-content-sm-end"> */}
                      <div className="col-sm-3 d-flex align-items-center justify-content-end">
                        {Tabs.length > 1 && Tabs.map((item) => {
                          return (
                            <p
                              key={item.tab}
                              // style={{ cursor: "pointer" }}
                              className={
                                ActiveTab === item.tab
                                  ? "m-0 px-2 mx-2 lt-st custom-input-box tab-class-clicked"
                                  : "m-0 px-2 mx-2 text-lable custom-input-box tab-class-unclicked"
                              }
                            // onClick={() => {
                            //   setActiveTab(item.tab);
                            //   setXAxis([]);
                            //   setYAxis([]);
                            //   setXAxisTitle("");
                            //   setYAxisTitle("");
                            //   setChartTitle("");
                            // }}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}

                        {
                          Notes !== "" ?
                            <>
                              <p className="m-0 icon-class"
                                id='notesdiv'
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '10px', height: 'max-content' }}>
                                <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-info-circle icon-fill" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                              </p>

                              <Tooltip anchorSelect="#notesdiv" clickable>
                                {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                                <div
                                  className="api-notes"
                                  style={{ height: 'max-content', color: 'white' }}
                                  dangerouslySetInnerHTML={{ __html: Notes }}
                                >
                                </div>
                              </Tooltip>
                            </> : null
                        }

                        {/* <p className="m-0 px-2" title="Download"
                          onClick={() => DownloadData()} style={{ cursor: "pointer" }}>
                          <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                          </svg>
                        </p> */}

                        {/* download start */}
                        {(downloadFlag.data || downloadFlag.pdf) && <span className="flex-shrink-0 dropdown">
                          <Link
                            to="#"
                            className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                            id="dropdownUser2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <p
                              className="m-0 icon-class icon-fill"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "10px",
                              }}
                            >
                              <svg
                                src={Download}
                                title="Download"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                class="bi bi-download "
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </p>
                          </Link>
                          <ul
                            className="background-colors dropdown-menu text-small shadow"
                            aria-labelledby="dropdownUser2"
                          >

                            {downloadFlag.data && <li onClick={(e) => { }}>
                              <Link className="dropdown-item" onClick={(e) => DownloadData(e)} >Download Data</Link>
                            </li>}
                            {downloadFlag.pdf && <li onClick={(e) => { }}>
                              <Link className="dropdown-item" onClick={(e) => DownloadPDF(e)} >Download PDF</Link>
                            </li>}
                          </ul>
                        </span>}
                        {/* download end */}
                      </div>
                    </div>
                    <div ref={refChartDiv}
                    // style={{ height: "100%" }} 
                    >
                      {ActiveTab === "item" && windowHeight > 0 ? (
                        <ItemWise
                          height={ChartHeight}
                          xAxis={xAxis} //
                          yAxis={yAxis}
                          xAxisTitle={xAxisTitle}
                          yAxisTitle={yAxisTitle}
                          chartTitle={chartTitle}
                          windowHeight={windowHeight - 10}
                          legends={legends}
                          showOpenLevel={showOpenLevel}
                          setShowOpenLevel={setShowOpenLevel}
                        />
                      ) : (
                        ""
                      )}
                      {ActiveTab === "aggr" && windowHeight > 0 ? (
                        <ItemWise
                          height={ChartHeight}
                          xAxis={xAxis} //
                          yAxis={yAxis}
                          xAxisTitle={xAxisTitle}
                          yAxisTitle={yAxisTitle}
                          chartTitle={chartTitle}
                          windowHeight={windowHeight - 10}
                          legends={legends}
                          showOpenLevel={showOpenLevel}
                          setShowOpenLevel={setShowOpenLevel}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {
                parseInt(ShowSummary) === 1 ?
                  <div className="col-xl-3">
                    <div className="card card-min-height synergy-table-height">
                      <div className="card-body brand-card">
                        <div className="row align-items-center justify-content-sm-between">
                          <h3 className="data-spends my-2">Summary</h3>
                          <div className="spends-list my-2">
                            <ol>
                              {Summary ? Summary.map((item, index) => {
                                return (
                                  <li key={index} className="spends-list-li">
                                    {item}
                                  </li>
                                );
                              }) : ""
                              }
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {
                  FooterNotes !== "" ?
                    <>
                      <i
                        className="fas fa-mail-bulk chart_info_icon footer-notes-icon  icon-class  icon-fill"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '15px', marginLeft: '6px', marginBottom: '10px' }}
                        id="clickable"
                      ></i>
                      <Tooltip anchorSelect="#clickable" clickable>
                        {/* <div className="footer-notes">
                          {FooterNotes}
                        </div> */}
                        <div
                          className="footer-notes"
                          style={{ height: 'max-content' }}
                          dangerouslySetInnerHTML={{ __html: FooterNotes }}
                        >
                        </div>
                      </Tooltip>
                    </>
                    : null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
