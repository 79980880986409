import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  Grid,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SingleCascader from "./SingleCascader";
import MultiCascaderNew from "./MultiCascaderNew";
import CloseIcon from "@mui/icons-material/Close";
import { fetchData } from "../utils/data";
import Loader from "../components/Loader";
import Backdrop from "@mui/material/Backdrop";
import CancelIcon from "@mui/icons-material/Cancel";

import Plot from "./Plot";

function base64ToBlob(base64, mime) {
  const byteChars = atob(base64);
  const byteNumbers = Array.from(byteChars, (char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}

// React component to display the image
// React component to display the image
const Base64Image = ({ base64, mime }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (base64 && mime) {
      const blob = base64ToBlob(base64, mime);
      const url = URL.createObjectURL(blob);
      setImageSrc(url);

      // Clean up the object URL when the component unmounts
      return () => URL.revokeObjectURL(url);
    }
  }, [base64, mime]);

  return (
    <div>
      {imageSrc ? (
        <img width={"70%"} src={imageSrc} alt="plotted_data" />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const Response = ({
  outputs,
  setIsPopUpOpen,
  setFeedbackPayload,
  setPopupId,
  setLogs,
  query,
  tabId,
  fetchDataTab,
}) => {

  // console.log("chant inside response: ", outputs);
  // console.log("fetchData",fetchData);
  const mimeType = "image/png";
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [questionAsk, setQuestionAsk] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [kpiData, setKpiData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [channelData, setChannelData] = useState([]);

  useEffect(() => {
    if (tabId != "") {
      const loadData = async () => {
        console.log("loadData", tabId);
        try {
          const result = await fetchData(query, tabId); // Replace 'your-endpoint' with the actual endpoint
          // console.log("result",result);
          setData(result);
        } catch (error) {
          // setError(error.message);
        } finally {
          // setLoading(false);
        }
      };
      loadData();
    }
  }, [tabId]);

  const handleSubmit = async () => {
    // Log the data to the console
    setIsLoading(true);

    // Prepare the body of the request
    const requestBody = {
      kpi: [kpiData],
      brand: [brandData],
      channels: channelData,
      question: questionAsk,
      tab_id: tabId,
    };

    console.log("request_body, ", requestBody);
    try {
      const response = await fetch(
        `http://stagingnew.skewb.ai:8001/gpt_reset/`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      console.log("reset_data ", data);
      setIsLoading(false);
      setIsOpen(false); // Close the modal after submission
      setShouldFetch(true);
      fetchDataTab(tabId);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      return error;
    }
  };

  const handleClose = () => {
    setIsOpen(false); // Close the modal after submission
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [outputs]);

  const theme = localStorage.getItem("theme");

  return (
    <Stack
      ref={scrollRef}
      sx={{
        overflowY: "scroll",
        height: "86%",

        // flex: 1,
        border: "none",
        "&::-webkit-scrollbar": {
          width: "8px",
        },

        "&::-webkit-scrollbar-track": {
          background: theme === "dark" ? "#1C2427" : "#caf0f8",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme === "dark" ? "gray" : "#0a2472",
          borderRadius: "10px",
        },
      }}
    >
      {outputs?.length > 0 &&
        outputs.map((output) => {
          return (
            <Stack gap={1} sx={{ padding: "1rem" }}>
              {output?.error === 1 ? (
                <>
                  <Typography sx={{ color: "red" }}>
                    {output?.error_msg}
                  </Typography>
                  <Typography
                    className="theme-colors"
                    variant="h6"
                  // sx={{  
                  //   // color: "white",
                  //    fontWeight: 700 }}
                  >
                    {"You"}
                  </Typography>
                  <Typography sx={{
                    color: "white"
                  }}>
                    {output?.question}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="theme-colors"
                  // sx={{ 
                  //   //  color: "white", 
                  //    fontWeight: 700 }}
                  >
                    {"Skewb-GPT"}
                  </Typography>
                  {/* <Typography sx={{  color: "white" }}>
                  {output?.answer.text}
                </Typography> */}

                  {output.answer.map((ans) => {
                    return (
                      <Typography sx={{ color: "white" }}>
                        {ans?.text}
                      </Typography>
                    );
                  })}

                  <Typography sx={{ color: "white" }}>
                    {output?.answer.res_human.payload_human}
                  </Typography>

                  <Stack direction={"row"} gap={2} >
                    <ThumbUpIcon

                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 1,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      fontSize="small"
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <ThumbDownIcon
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 0,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      fontSize="small"
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    {/* <EmojiObjectsIcon
                    onClick={() => {
                      setIsPopUpOpen(true);
                      setPopupId(2);
                      setLogs(output?.answer.gpt_log);
                    }}
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px",
                      color: "#d6ff41",
                    }}
                  /> */}
                    <RestartAltIcon
                      onClick={() => {
                        {
                          console.log("output qeustions ", output.question);
                          setQuestionAsk(output?.question);
                          setIsOpen(true);
                          // setPopupId(3);
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                    />
                  </Stack>
                </>
              ) : (
                <Stack gap={1}>
                  <Typography
                    variant="h6"
                    className="theme-colors"
                    sx={{
                      // color: "themeColor",
                      fontWeight: 700
                    }}
                  >
                    {"You"}
                  </Typography>
                  <Typography
                    sx={{ color: "white", float: "right" }}
                  >
                    {output?.question}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="theme-colors"
                    sx={{
                      //  color: "themeColor",
                      fontWeight: 700
                    }}
                  >
                    {"Skewb-GPT"}
                  </Typography>
                  {/* <Typography sx={{  color: "white" }}>
                  {output?.answer.text}
                </Typography> */}

                  {output.answer.map((ans) => {
                    return (
                      <>
                        {ans.error === 1 ? (
                          <Typography sx={{ color: "red" }}>{ans.errorMsg}</Typography>
                        ) : (
                          <Stack gap={1}>
                            <Typography
                              sx={{ color: "white" }}
                            >
                              <div>
                                {ans.text.replace(/[#*]/g, '').split('\n').map((line, lineIndex) => (
                                  <Typography key={lineIndex} sx={{ color: "white" }}>
                                    {line || <br />} {/* Renders a line break for empty lines */}
                                  </Typography>
                                ))}
                              </div>
                            </Typography>
                            {ans?.isImage === true ? (
                              <Plot data={ans?.chart} />
                            ) : null}
                          </Stack>
                        )}
                      </>
                    );
                  })}

                  {output?.answer.isImage === true ? (
                    <Plot data={output?.answer.graph} />
                  ) : null}
                  <Typography sx={{ color: "white" }}>
                    {output?.answer?.res_human?.payload_human}
                  </Typography>
                  <Stack direction={"row"} gap={2} >
                    <ThumbUpIcon
                      className="icon-class icon-fill "
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 1,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <ThumbDownIcon
                      className="icon-class icon-fill"
                      onClick={() => {
                        {
                          setIsPopUpOpen(true);
                          setFeedbackPayload((prevPayload) => ({
                            ...prevPayload,
                            user_query: output.question,
                            thumbs_up: 0,
                            timestamp: output.timestamp,
                          }));
                          setPopupId(1);
                        }
                      }}
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    {/* <EmojiObjectsIcon
                    onClick={() => {
                      setIsPopUpOpen(true);
                      setPopupId(2);
                      setLogs(output?.answer.gpt_log);
                    }}
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px",
                      color: "#d6ff41",
                    }}
                  /> */}
                    <RestartAltIcon
                      className="icon-class icon-fill"
                      onClick={() => {
                        {
                          console.log("output qeustions ", output.question);
                          setQuestionAsk(output?.question);
                          setIsOpen(true);
                          // setPopupId(3);
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          );
        })}

      <Modal
        // keepMounted
        open={isOpen}

        onClose={() => {
          setIsOpen(false);
        }}
        // aria-labelledby="modal-title"
        // aria-describedby="modal-description"
        // Backdrop={backgroundColor:'transparent'},
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 5,
            sx: {
              //Your style here....
              backgroundColor: "transparent",
            },
          },
        }}
        hideBackdrop={true}
      >
        <Box
          className="card"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1c2427",
            color: "black",
            // border:'transparent',
            borderRadius: "8px",
            outline: 'none',
            height: "50%",
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '90%', sm: '70%', md: '50%' }, // Responsive width

            p: 2,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#d6ff41",
            }}
          >
            <CancelIcon  className="text-colors" sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography
            className="cm-filter-title "
            id="modal-title"
            variant="h3"
            component="h2"
            sx={{ color: "white" }}
          >
            Select Input Data
          </Typography>
          <Grid container rowSpacing={5} sx={{ mt: 2, p: 2 }}>
            <Grid item xs={12} sm={4}>

              <Typography variant="subtitle1" sx={{ color: "white", paddingLeft: 0, }}>
                <Typography
                  className="cm-filter-title text-colors"
                  variant="subtitle2"
                  // sx={{ color: "white" }}
                  style={{ fontSize: "50%" }}
                >
                  Select/Search KPI
                </Typography>
                <SingleCascader
                  data={data?.kpi_cascader}
                  match={"tea-green-jasmine"}
                  placeholder={"KPI"}
                  setGetBrand={setKpiData}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{ color: "white" }}>
                <Typography
                  className="cm-filter-title text-colors"
                  variant="subtitle2"
                  // sx={{ color: "white" }}
                  style={{ fontSize: "50%" }}
                >
                  Select/Search Brand
                </Typography>
                <SingleCascader
                  data={data?.brand_cascader}
                  match={"tea-green-jasmine"}
                  placeholder={"Brand"}
                  setGetBrand={setBrandData}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{ color: "white" }}>
                <Typography
                  className="text-colors cm-filter-title "
                  variant="subtitle2"
                  // sx={{ color: "white" }}
                  style={{ fontSize: "50%" }}
                >
                  Select/Search Channels
                </Typography>
                <MultiCascaderNew
                  data={data?.channel_cascader}
                  match={"tea-green-jasmine"}
                  placeholder={"Channel"}
                  setChannelData={setChannelData}
                />
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{ position: "absolute", bottom: "10px", left: "10px", mt: 1 }}
          >
            <Tooltip
              title={
                kpiData.length === 0 ||
                  brandData.length === 0 ||
                  channelData.length === 0
                  ? "Please select all three Data"
                  : ""
              }
            >
              {isLoading && <Loader />}
              <span>
                <Button
                  className="cm-filter-title btn-class"
                  onClick={() => {
                    handleSubmit();
                  }}
                  // variant="outlined"
                  disabled={
                    kpiData.length === 0 ||
                    brandData.length === 0 ||
                    channelData.length === 0
                  }
                  sx={{
                    outline: 'none',
                    border: 'none',
                    // background: '#1c2427',
                    fontSize: '12px !important',
                    // color: 'white',
                    lineHeight: '4px',

                  }}
                >
                  Submit
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Modal>

    </Stack>
  );
};

export default Response;
