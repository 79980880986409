import { useState } from "react";
import { Stack, Typography, Box, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
const Aside = ({ tabs, setTabId, activeTab, setActiveTab, credits }) => {
  const [isOpen, setIsOpen] = useState(false); // state for sidebar visibility

  const toggleDrawer = () => {
    setIsOpen(!isOpen); // toggle sidebar open and close
  };

  const drawerContent = (
    <Stack
      className="background-colors text-colors"
      sx={{
        height: "100%",
        padding: "1rem",
        // width: 250, // Define width for the drawer content
        // backgroundColor: "#1c2427", // Set background color to match the design
        // color: "white", // Text color to ensure it's visible
      }}
    >
      <IconButton
      className="gpt-tab-closebtn"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1301, // Ensure close button stays above other content
          color: "white",
        }}
        onClick={toggleDrawer}
      >
        <CloseIcon  className="gpt-tab-closebtn" sx={{ fontSize: "2rem" }} />
      </IconButton>

      <Stack sx={{ height: "88%" }}>
        <Box component={"span"}>
          {/* <Typography
            component={"span"}
            variant="h5"
            sx={{
              backgroundColor: "#3e5056",
              padding: "0.5rem",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            New Chat
          </Typography> */}
        </Box>
        <Stack sx={{ marginTop: "1rem", padding: "1rem 0", gap: 1 }}>
          {tabs &&
            tabs.map((tab) => {
              return (
                <Typography
                  className={
                    activeTab === tab.tab_id ? "tab-class-clicked" : "tab-class-unclicked"
                  }
                  onClick={() => {
                    setTabId(tab.tab_id);
                    setActiveTab(tab.tab_id);
                    setIsOpen(false); // Close the drawer when a tab is selected
                  }}
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "#263238",
                    padding: "0.25rem 0.5rem",
                    height: "max-content !important",
                    cursor: "pointer",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#1c2427",
                    },
                  }}
                  // variant="h6"
                  key={tab.tab_id}
                >
                  {tab.tabname}
                </Typography>
              );
            })}
        </Stack>
      </Stack>
      <Stack
        sx={{ height: "12%" }}
        direction={"row"}
        justifyContent={"end"}
        gap={2}
      >
        <Typography className="text-colors" variant="h6">Credits:</Typography>
        <Stack direction={"row"} gap={1}>
          <Typography
            // variant="h6"
            sx={{
              color: credits?.remaining_credits > 0 ? "rgb(3, 255, 3)" : "red",
            }}
          >
            {credits?.remaining_credits?.toFixed(2)}
          </Typography>
          <Typography 
          // variant="h6"
          >{"/"}</Typography>
          <Typography
          //  variant="h6"
           >{credits?.total_credits}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      {/* Hamburger button for mobile view */}
      {!isOpen && <IconButton
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          padding:0,
          top: -10,
          left: 0,
          zIndex: 1301, // Ensure it stays above other content
        }}
        onClick={toggleDrawer}
      >
        <MenuIcon sx={{ fontSize: "2rem", color: "white" }} />
      </IconButton>}

      {/* Drawer for mobile */}
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        sx={{ display: { xs: "block", sm: "none" },
        position: "absolute",
        top: 0,
        left: 0,
       }}
        PaperProps={{
          sx: {
            backgroundColor: "#1c2427", // Ensures the drawer has the right background
            color: "white", // Ensures text is visible
          },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Sidebar for larger screens */}
      <Stack
        sx={{
          height: "100%",
          padding: "1rem",
          
          borderRight: "3px solid #3e5056",
          display: { xs: "none", sm: "flex" },
        }}
      >
        {drawerContent}
      </Stack>
    </>
  );
};

export default Aside;
