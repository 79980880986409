
import React, { useState, useEffect, useRef } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import configDriven from "../ConfirgDriven";
import _ from 'lodash';

const Cascader = ({
  data,
  searchValue,
  setSearchValue,
  selectedPath,
  setSelectedPath,
  setIdList,
  pathName,
  selectedCheckboxes,
  setSelectedCheckboxes,
  flattenedData,
  searchResults,
  enableFetching
}) => {
  const displayNames = JSON.parse(localStorage.getItem("displayNames"));
  const theme = localStorage.getItem("theme");
  const returnDisplayName = (id) => {
      if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
          return displayNames[id];
      } else {
          return id;
      }
  };
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExpandIcon, setSelectedExpandIcon] = useState(null);

  // Handle click on category button
  const handleCategoryButtonClick = (categoryId) => {
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // Handle click on expand icon (+ or -)
  const handleExpandIconClick = (categoryId) => {
    if (categoryId === selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
    } else {
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    }

    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // const handleSearchResultCheckboxClickWrapper = (result)=>{
  //   handleSearchResultCheckboxClick(result);
  // };

  const selectedIds = Object.keys(selectedCheckboxes).filter((id) => {
    if (id.endsWith("-all")) {
      return false;
    }
    if (id.includes("all") && !id.includes("-all")) {
      return false;
    }
    return selectedCheckboxes[id];
  });

  //   useEffect(() => {
  //     console.log("Selected IDs:", selectedIds);
  //   }, [selectedIds]);

  //   useEffect(() => {
  //   console.log("SearchResults: ", searchResults);
  // }, [searchResults]);

  const handleCheckboxClick = (categoryId) => {
    enableFetching();
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
    // setSearchValue("");
    setSelectedCheckboxes((prev) => {
      const isChecked = prev[categoryId];
      const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };

      const updateChildrenCheckboxes = (parentId, parentChecked) => {
        const children = flattenedData.filter(
          (item) => item.parentId === parentId
        );
        children.forEach((child) => {
          updatedCheckboxes[child.id] = parentChecked;
          updateChildrenCheckboxes(child.id, parentChecked);
        });
      };

      const updateParentCheckboxes = (childId) => {
        const childItem = flattenedData.find((item) => item.id === childId);
        if (childItem) {
          const parentId = childItem.parentId;
          if (parentId) {
            const siblings = flattenedData.filter(
              (item) => item.parentId === parentId && !item.id.endsWith("-all")
            );
            const allSiblingsChecked = siblings.every(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const someSiblingsChecked = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const anySiblingsIndeterminate = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
            );

            if (allSiblingsChecked) {
              updatedCheckboxes[parentId] = true;
            } else if (anySiblingsIndeterminate || someSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else {
              updatedCheckboxes[parentId] = false;
            }

            if (parentId.endsWith("-all")) {
              updatedCheckboxes[parentId.replace("-all", "")] =
                allSiblingsChecked;
            } else {
              updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
            }

            if (parentId !== "all") {
              updateParentCheckboxes(parentId);
            } else {
              const rootLevelItems = flattenedData.filter(
                (item) => !item.parentId
              );
              const allRootItemsUnchecked = rootLevelItems.every(
                (item) => updatedCheckboxes[item.id] === false
              );
              const someRootItemsChecked = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === true
              );
              const anyRootItemsIndeterminate = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === "indeterminate"
              );

              if (allRootItemsUnchecked) {
                updatedCheckboxes["all"] = false;
              } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                updatedCheckboxes["all"] = "indeterminate";
              } else {
                updatedCheckboxes["all"] = true;
              }
            }
          }
        }
      };

      updateChildrenCheckboxes(categoryId, !isChecked);

      if (categoryId === "all") {
        flattenedData.forEach((item) => {
          updatedCheckboxes[item.id] = !isChecked;
          updateChildrenCheckboxes(item.id, !isChecked);
        });
      } else if (categoryId.endsWith("-all")) {
        const categoryLevel = categoryId.replace("-all", "");
        flattenedData.forEach((item) => {
          if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
            updatedCheckboxes[item.id] = !isChecked;
            updateChildrenCheckboxes(item.id, !isChecked);
          }
        });

        flattenedData.forEach((item) => {
          if (item.id.endsWith("-all")) {
            const levelPrefix = item.id.replace("-all", "");
            const levelItems = flattenedData.filter(
              (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
            );
            const allLevelItemsChecked = levelItems.every(
              (i) => updatedCheckboxes[i.id] === true
            );
            const someLevelItemsChecked = levelItems.some(
              (i) => updatedCheckboxes[i.id] === true
            );
            const anyLevelItemsIndeterminate = levelItems.some(
              (i) => updatedCheckboxes[i.id] === "indeterminate"
            );

            if (allLevelItemsChecked) {
              updatedCheckboxes[item.id] = true;
            } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else {
              updatedCheckboxes[item.id] = false;
            }
          }
        });
      }

      if (categoryId === "all") {
        const rootLevelItems = flattenedData.filter((item) => !item.parentId);
        const allRootItemsUnchecked = rootLevelItems.every(
          (item) => updatedCheckboxes[item.id] === false
        );

        if (allRootItemsUnchecked) {
          updatedCheckboxes["all"] = false;
        }
      }
      updateParentCheckboxes(categoryId);

      return updatedCheckboxes;
    });
  };

  const getCheckboxIcon = (categoryId) => {
    if (selectedCheckboxes[categoryId] === "indeterminate") {
      return (
        <IndeterminateCheckBoxIcon
          sx={{ color: (theme === "dark"?"#d6ff41":"#0a2472"), fontSize: "16px" }}
        />
      );
    }
    return selectedCheckboxes[categoryId] ? (
      <CheckBoxIcon sx={{ color: (theme === "dark"?"#d6ff41":"#0a2472"), fontSize: "16px" }} />
    ) : (
      <CheckBoxOutlineBlankIcon sx={{ color: (theme === "dark"?"#d6ff41":"#0a2472"), fontSize: "16px" }} />
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        className="background-colors"
        style={{
          padding: "8px 0",
          // height: "385px",
          // backgroundColor: "#1C2427",
        }}
      >
        {data.map((category) => (
          <div style={{ marginBottom: "4px", width: "100%" }} key={category.id}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  color: selectedPath.includes(category.id) ? "black" : "#E6E6FA",
                  padding: "0 10px",
                  // fontSize: "10px",
                  cursor: "pointer",
                  backgroundColor: selectedPath.includes(category.id)
                    ? (theme ==="dark"?"#4A808080":"#ADE8F4")
                    : selectedExpandIcon === category.id
                      ? (theme ==="dark"?"#4A808080":"#ADE8F4")
                      : (theme === "dark"?"#1C2427":"#f7feff"),
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => handleCategoryButtonClick(category.id)}
                className="cm-filter-title"
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxClick(category.id);
                  }}
                  style={{ marginRight: "8px" }}
                >
                  {getCheckboxIcon(category.id)}
                </div>
                <span
                  className="text-colors"
                  style={{ flexGrow: 1, textAlign: "left" }}
                >
                   {returnDisplayName(category.id.split('-').pop())}
                </span>

                {category.children.length > 0 && (
                  <span
                    // className="theme-colors"
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      textAlign: "right",
                      // color: "#D6FF41",
                      color:theme ==="dark"?"#d6ff41":"#0a2472",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandIconClick(category.id);
                    }}
                  >
                    {selectedExpandIcon === category.id ? "−" : "+"}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {data.map(
          (category) =>
            selectedCategory === category.id &&
            category.children && (
              <div key={category.id}>
                <Cascader
                  data={category.children}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                  setIdList={setIdList}
                  pathName={pathName}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  flattenedData={flattenedData}
                  searchResults={searchResults}
                  enableFetching={enableFetching}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

const CascaderWrapper = ({
  data,
  Chartdata,
  setChartdata,
  setFetchCall,
  selectedCheckboxes,
  setSelectedCheckboxes,
  fiexedFilters,
  ActiveFilters,
  reset,
  setReset,
  isFirstVisit,
  setIsFirstVisit,
  fetchData,
  enableFetching,
  setHierarchySelectionBreadCrumb,
  scrollRef,
  setLevel,

}) => {
  // console.log("data cascader Chartdata: ", Chartdata);
  const [searchValue, setSearchValue] = useState("");
  const [isCascaderVisible, setIsCascaderVisible] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const cascaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchListIconRef = useRef(null);
  const toggleCascaderRef = useRef(null);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [pathName, setPathName] = useState("");
  const searchResultsContainerRef = useRef(null);
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);
  const [SelectAllIcon, setSelectAllIcon] = useState(true);
  const [checkedList, setCheckedList] = useState([]);

  const displayName = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
      if (configDriven.show_display_name && displayName?.hasOwnProperty(id)) {
          return displayName[id];
      } else {
          return id;
      }
  };

  //   console.log("checkedList: ", checkedList);

  const selectedIds = Object.keys(selectedCheckboxes)
    .filter((id) => selectedCheckboxes[id] !== false)
    .filter((id) => {
      return !id.endsWith("-all") && id !== "all";
    });

  useEffect(() => {
    console.log("Fetching data... seleectid", selectedIds);
    setCheckedList(selectedIds);

    // console.log("checkedList: ",checkedList);
  }, [selectedCheckboxes]);
  // setCheckedList(selectedIds);

  function flattenDataToObject(data) {
    const result = [];

    const traverse = (data, parentId = null) => {
      // console.log("items traverse: ", items);
      data?.forEach((item) => {
        const { id, name, children } = item;
        result.push({ id, name, parentId });

        if (children && children.length > 0) {
          traverse(children, id);
        }
      });
    };

    traverse(data);
    return result;
  }

  const flattenedData = flattenDataToObject(data);
  //console.log(flattenedData);
  // function constructCategoryPath(newPath, flattenedData) {
  //   return newPath.map((key) => flattenedData[key]);
  // }
  const flattenHierarchy = (node) => {
    const childrenIds = node.children.map(child => child.id);
    return [node.id, ...node.children.flatMap(flattenHierarchy)];
  };
 
  const findAllParentWithSelectedChildren = (selectedCheckboxes, data) => {
    const selectedKeys = [];
 
    const traverse = (node) => {
      const childrenIds = node.children.map(child => child.id);
     
      // If the node has children, check if all children are selected
      if (childrenIds.length > 0) {
        const allChildrenSelected = childrenIds.every(childId => selectedCheckboxes[childId] === true);
        if (allChildrenSelected) {
          selectedKeys.push(node.id);
        } else {
          // If not all children are selected, traverse the children
          node.children.forEach(traverse);
        }
      } else {
        // If no children, just add the current node if it's selected
        if (selectedCheckboxes[node.id] === true) {
          selectedKeys.push(node.id);
        }
      }
    };
 
    data.forEach(traverse);
    return selectedKeys;
  };
  const storedDisplayNames = localStorage.getItem('displayNames');
  const displayNames = JSON.parse(storedDisplayNames);
  // console.log("displayNames", displayNames);
 
  const chartRef = useRef(null);
 
  // Utility function to get display name
  const getDisplayName = (key) => {
    // Split key by '-'
    const parts = key.split("-");
    // Map each part using displayNames and join them with a -
    return parts.map(part => displayNames[part] || part).join("/");
  };
 
  useEffect(() => {
    const trueKeys =  findAllParentWithSelectedChildren(selectedCheckboxes, data);
    const trueKeysWithoutAll = trueKeys.filter(key => key !== "all");
    // Map each key through the displayNames function
    const transformedKeys = trueKeysWithoutAll.map(key => getDisplayName(key));
 
    // Join the transformed keys with a comma
    const breadcrumbString = transformedKeys.join(', ');
 
    // Update the state with the transformed breadcrumb string
    if(setHierarchySelectionBreadCrumb!==undefined)
    setHierarchySelectionBreadCrumb(breadcrumbString);
  }, [selectedCheckboxes]);

  function getTeaList(idList, flattenedData) {
    return idList.map((key) => flattenedData[key]);
  }

  useEffect(() => {
    const result = getTeaList(idList, flattenedData);
    setNameList(result);
  }, [idList]);

  useEffect(() => {
    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, [nameList]);

  // useEffect(() => {
  //   const pathSegments = match.split("-");
  //   const newPath = pathSegments.map((segment, index) =>
  //     pathSegments.slice(0, index + 1).join("-")
  //   );
  //   // setSelectedPath(newPath);
  //   // console.log(newPath);
  //   // const result = getTeaList(newPath, flattenedData);
  //   // setNameList(result);

  //   // if (nameList) {
  //   //   const names = nameList.join(" / ");
  //   //   setPathName(names);
  //   //   //setSearchValue(names);
  //   // } else {
  //   //   setPathName("");
  //   //   setSearchValue("");
  //   // }
  // }, []);

  // Toggle visibility of cascader
  const toggleCascaderVisibility = (event) => {
    event.stopPropagation();
    setIsCascaderVisible((prevState) => !prevState);
  };

  // Handle search input change
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      setSearchValue("");
      setIsDropDownVisible(false);
    } else {
      const matchingResults = findMatchingResults(data, searchTerm);
      setSearchResults(matchingResults);
      setIsDropDownVisible(true);
    }
  };

  // const handleSearchResultClick = (result) => {
  //   const pathSegments = [];
  //   const idSegments = result.id.trim().toLowerCase().split("-");
  //   for (let i = idSegments.length; i > 0; i--) {
  //     pathSegments.push(idSegments.slice(0, i).join("-"));
  //   }

  //   result.path.forEach((segment) => {
  //     const segments = segment.trim().toLowerCase().split("-");
  //     for (let i = segments.length; i > 0; i--) {
  //       pathSegments.push(segments.slice(0, i).join("-"));
  //     }
  //   });

  //   const n = pathSegments.length / 2;
  //   pathSegments.splice(-n);

  //   const pathName = result.path.join(" / ");
  //   setSearchValue(pathName);
  //   setSelectedPath(pathSegments);
  //   setIsDropDownVisible(false);
  // };

  // Find matching results based on search term
  const findMatchingResults = (data, searchTerm) => {
    const matchingResults = [];
    const traverse = (children, path) => {
      children.forEach((item) => {
        const currentPath = path ? `${path} / ${item.name}` : item.name;
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          matchingResults.push({ path: currentPath.split(" / "), id: item.id });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };
    traverse(data, "");
    return matchingResults;
  };
  // console.log("searchResults ", searchResults);
  // function for handling the click on the All option present in the SearchResult List

  // const getIdsNotInSearchResults = (selectedCheckBoxes, searchResults) => {
  //   const searchResultsIds = searchResults.map((result) => result.id);
  //   const selectedCheckBoxesIds = Object.keys(selectedCheckBoxes);

  //   return selectedCheckBoxesIds.filter((id) => !searchResultsIds.includes(id));
  // };

  const handleSearchResultAllClick = (event) => {
    event.stopPropagation(); // Prevent event bubbling

    // Iterate through search results
    searchResults.forEach((result) => {
      // Simulate checkbox click for each result
      if (!selectedIds.includes(result.id)) {
        // Only process if not already selected
        handleSearchResultCheckboxClick(event, result); // Call the click handler
      }
    });
  };

  const handleSetSelectedCheckBoxes = (categoryId) => {
    setSelectedCheckboxes((prev) => {
      const isChecked = prev[categoryId];
      const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };

      const updateChildrenCheckboxes = (parentId, parentChecked) => {
        const children = flattenedData.filter(
          (item) => item.parentId === parentId
        );
        children.forEach((child) => {
          updatedCheckboxes[child.id] = parentChecked;
          updateChildrenCheckboxes(child.id, parentChecked);
        });
      };

      const updateParentCheckboxes = (childId) => {
        const childItem = flattenedData.find((item) => item.id === childId);
        if (childItem) {
          const parentId = childItem.parentId;
          if (parentId) {
            const siblings = flattenedData.filter(
              (item) => item.parentId === parentId && !item.id.endsWith("-all")
            );
            const allSiblingsChecked = siblings.every(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const someSiblingsChecked = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const anySiblingsIndeterminate = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
            );

            if (allSiblingsChecked) {
              updatedCheckboxes[parentId] = true;
            } else if (anySiblingsIndeterminate || someSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else {
              updatedCheckboxes[parentId] = false;
            }

            if (parentId.endsWith("-all")) {
              updatedCheckboxes[parentId.replace("-all", "")] =
                allSiblingsChecked;
            } else {
              updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
            }

            if (parentId !== "all") {
              updateParentCheckboxes(parentId);
            } else {
              const rootLevelItems = flattenedData.filter(
                (item) => !item.parentId
              );
              const allRootItemsUnchecked = rootLevelItems.every(
                (item) => updatedCheckboxes[item.id] === false
              );
              const someRootItemsChecked = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === true
              );
              const anyRootItemsIndeterminate = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === "indeterminate"
              );

              if (allRootItemsUnchecked) {
                updatedCheckboxes["all"] = false;
              } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                updatedCheckboxes["all"] = "indeterminate";
              } else {
                updatedCheckboxes["all"] = true;
              }
            }
          }
        }
      };

      updateChildrenCheckboxes(categoryId, !isChecked);

      if (categoryId === "all") {
        flattenedData.forEach((item) => {
          updatedCheckboxes[item.id] = !isChecked;
          updateChildrenCheckboxes(item.id, !isChecked);
        });
      } else if (categoryId.endsWith("-all")) {
        const categoryLevel = categoryId.replace("-all", "");
        flattenedData.forEach((item) => {
          if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
            updatedCheckboxes[item.id] = !isChecked;
            updateChildrenCheckboxes(item.id, !isChecked);
          }
        });

        flattenedData.forEach((item) => {
          if (item.id.endsWith("-all")) {
            const levelPrefix = item.id.replace("-all", "");
            const levelItems = flattenedData.filter(
              (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
            );
            const allLevelItemsChecked = levelItems.every(
              (i) => updatedCheckboxes[i.id] === true
            );
            const someLevelItemsChecked = levelItems.some(
              (i) => updatedCheckboxes[i.id] === true
            );
            const anyLevelItemsIndeterminate = levelItems.some(
              (i) => updatedCheckboxes[i.id] === "indeterminate"
            );

            if (allLevelItemsChecked) {
              updatedCheckboxes[item.id] = true;
            } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else {
              updatedCheckboxes[item.id] = false;
            }
          }
        });
      }

      if (categoryId === "all") {
        const rootLevelItems = flattenedData.filter((item) => !item.parentId);
        const allRootItemsUnchecked = rootLevelItems.every(
          (item) => updatedCheckboxes[item.id] === false
        );

        if (allRootItemsUnchecked) {
          updatedCheckboxes["all"] = false;
        }
      }
      updateParentCheckboxes(categoryId);

      return updatedCheckboxes;
    });
  };

  // function for handling the check box in the searchResult
  const handleSearchResultCheckboxClick = (event, result) => {
    // console.log("selectedIds", selectedIds);
    event.stopPropagation();
    //  setSearchValue("");

    const updatedResults = searchResults.map((r) =>
      r.id === result.id ? { ...r, checked: !r.checked } : r
    );
    setSearchResults(updatedResults);

    // Update selectedCheckboxes state for cascader

    const categoryId = result.id;
    handleSetSelectedCheckBoxes(categoryId);
  };

  // Handle click outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleCascaderRef.current &&
        toggleCascaderRef.current.contains(event.target)
      ) {
        if (isCascaderVisible) {
          setIsCascaderVisible(false);
        } // Toggle cascader visibility
        return;
      }

      // Check if the click is on the cascader container
      if (cascaderRef.current && cascaderRef.current.contains(event.target)) {
        setSearchValue("");
        setIsDropDownVisible(false);
        setIsCascaderVisible(true);
        return;
      }

      // Check if the click is on the search bar
      if (searchBarRef.current && searchBarRef.current.contains(event.target)) {
        return;
      }

      // Check if the click is on the dropdown
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }

      // Close both cascader and dropdown if clicking outside
      setSearchValue("");
      setIsCascaderVisible(false);
      setIsDropDownVisible(false);
    };

    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  // console.log("searchResults: ", searchResults);

  function deepEqual(obj1, obj2) {
    // if (obj1 === obj2) {
    //   // If both objects are identical by reference
    //   console.log("deepEqual obj match");
    //   return true;
    // }
  
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      // If either of the objects is not an object, or is null, they're not equal
      return false;
    }
  
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    // Check obj1 for keys not in obj2
    for (let key of keys1) {
      if (!keys2.includes(key)) {
        // If obj1 has an extra key and its Filter is not an empty array, return false
        if (obj1[key]?.Filter?.length > 0) {
          return false;
        }
      }
    }
  
    // Check obj2 for keys not in obj1 (same logic as above)
    // for (let key of keys2) {
    //   if (!keys1.includes(key)) {
    //     // If obj2 has an extra key and its Filter is not an empty array, return false
    //     if (obj2[key]?.Filter?.length > 0) {
    //       return false;
    //     }
    //   }
    // }
  
    // Now compare common keys
    for (let key of keys1) {
      if (keys2.includes(key)) {
        // Recursively check nested objects
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
    }
  
    return true;
  }
  
  

  const DrillAllTrends = () => {

    console.log("drillAllTrends")

    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    const keysArrayOfChartData = Object.keys(Chartdata);

    let ChartdataTrendsTemp = structuredClone(Chartdata);
    const prevChartData=structuredClone(Chartdata);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    const result = createArrays(keysArrayOfChartData.length);
    

    result.forEach((item) => {
      return item.push(...checkedList);
    });

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => key.split("-"));

      const filteredItemArray = itemArray.filter(
        (arr) => arr.length === index + 1
      );

      const joinedItemArray = filteredItemArray.map((arr) => arr.join("-"));

      return [...new Set(joinedItemArray)];
    });

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    const localChartData = JSON.parse(localStorage.getItem("Chartdata"));
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTrendsTemp.hasOwnProperty(skipId)) {
        // Make the filter array same as the filter array
        console.log("DRILLALLTRENDS CHARTDATA", localChartData[skipId].Filter);
        ChartdataTrendsTemp[skipId].Filter = localChartData[skipId].Filter;
      }
    });

    // keysArrayOfChartData
    //   .slice(0, keysArrayOfChartData.length - 1)
    //   .forEach((key, index) => {
    //     if (ChartdataTrendsTemp.hasOwnProperty(key)) {
    //       ChartdataTrendsTemp[key].Filter = payloadArray[index];
    //     }
    //   });

    console.log("payloadArray Multi ChartdataTrendsTemp", payloadArray);
    // if (JSON.stringify(fiexedFilters) !== "{}") {
    //   keysArrayOfChartData
    //     .filter((key) => key.startsWith("H")) // Filter keys that start with 'C'
    //     .forEach((key, index) => {
    //       if (ChartdataTrendsTemp.hasOwnProperty(key)) {
    //         ChartdataTrendsTemp[key].Filter = payloadArray[index];
    //         for (const key in fiexedFilters) {
    //           if (ChartdataTrendsTemp.hasOwnProperty(key)) {
    //             ChartdataTrendsTemp[key].Filter = [fiexedFilters[key]];
    //           }
    //         }
    //       }
    //     });
    // }

    // Check if fiexedFilters is not empty
    
      keysArrayOfChartData
        .filter((key) => key.startsWith("H")) // Filter keys that start with 'H'
        .forEach((key, index) => {
          // Skip the keys that are present in SkipData
          if (!SkipData?.some((skipItem) => skipItem.id === key)) {
            console.log("ChartdataTrendsTemp inside1 ",key,"  ",ChartdataTrendsTemp[key],"  ",payloadArray[index]," ",index);
            if (ChartdataTrendsTemp.hasOwnProperty(key)) {
              // Apply payloadArray to ChartdataTrendsTemp
              console.log("ChartdataTrendsTemp inside ",ChartdataTrendsTemp[key],"  ",payloadArray[index]," ",index);
              ChartdataTrendsTemp[key].Filter = payloadArray[index];

              // Apply fixedFilters if they exist
            //   for (const fixedKey in fiexedFilters) {
            //     if (ChartdataTrendsTemp.hasOwnProperty(fixedKey)) {
            //       ChartdataTrendsTemp[fixedKey].Filter = [fiexedFilters[fixedKey]];
            //     }
            //   }
            }
          }
        });
    
    // for (const key in fiexedFilters) {
    //   if (ChartdataTrendsTemp.hasOwnProperty(key)) {
    //     ChartdataTrendsTemp[key].Filter = [fiexedFilters[key]];
    //   }
    // }



    console.log("ChartdataTrendsTemp ", ChartdataTrendsTemp, "    ", Chartdata);

    console.log("Fetching data... DRILLALLTrends", checkedList);
    const DefaultLevel = JSON.parse(localStorage.getItem('DefaultLevel'));
    const isEqual = _.isEqual(ChartdataTrendsTemp,prevChartData);
    if (checkedList.length > 0 && !isEqual) {
      console.log("ChartdataTrendsTemp1 ", ChartdataTrendsTemp, "    ", Chartdata);
      setChartdata(ChartdataTrendsTemp);
      setFetchCall(true);
      // setLevel(DefaultLevel);
    }
    else {
      // const isEqual = deepEqual(Chartdata, localChartData);
      console.log("deepEqual ", !isEqual, "  ", Chartdata, " ", localChartData);
      if (!isEqual) {
        console.log("deepEqual 2", isEqual, "  ", Chartdata, " ", localChartData);
        setFetchCall(true);
        setChartdata(ChartdataTrendsTemp);
        setReset(false);
        // setLevel(DefaultLevel);
      }
    }

  };

  // useEffect(() => {
  //   if (fetchData) {
  //     // Your API call logic here
  //     console.log('Fetching data...',checkedList);
  //     if (checkedList.length >= 0) {
  //       if (checkedList.length === 0) {
  //         setReset(false);
  //       }
  //       DrillAllTrends();
  //     }
  //     // After fetching data, you can set fetchData to false again if needed
  //   }
  // }, [checkedList]);

  const [prevCheckedList,setPrevCheckedList]=useState(null);
  useEffect(() => {
    if(_.isEqual(prevCheckedList,checkedList)==false){
    console.log(" setIsFirstVisit checkedList ", checkedList);
    if (checkedList.length >= 0) {

      if (checkedList.length > 0) {
        setReset(true);
      }

      else if (checkedList.length === 0) {
        setReset(false);

      }
      DrillAllTrends();
      setPrevCheckedList(checkedList);

    }
  }
  }, [checkedList]);

  useEffect(() => {
    const handleScroll = () => {
      if (isCascaderVisible) {
        setIsCascaderVisible(false);
      }
    };
 
    // Add event listeners
  const container = scrollRef.current;
  if (container) {
    container.addEventListener('scroll', handleScroll);
  }
  window.addEventListener('scroll', handleScroll);
 
  // Clean up function
  return () => {
    if (container) {
      container.removeEventListener('scroll', handleScroll);
    }
    window.removeEventListener('scroll', handleScroll);
  };
  }, [isCascaderVisible]);

  // useEffect(()=>{
  //   console.log("setIsFirstVisit checkedList reset click ",reset);
  //   if (!reset && checkedList.length===0) {
      
  //     DrillAllTrends();
  //     // setReset(false);
  //     // setCheckedList([]);
  //   }


  // },[reset])
  const theme= localStorage.getItem("theme");

  return (
    <div className="col-sm-3" style={{ position: "relative" }}>
      <div ref={cascaderRef}>
        <div style={{ display: "flex", alignItems: "center", }}>
          <div
            // className="cm-filter-option-li"
            className="form-control tab-class-fixed"

            style={{
              display: "flex",
              // border: "1px solid #3e5056",
              borderRadius: "4px",
              // width: "200px",
              width: "110px",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              // marginBottom: "0.2rem",
              height: '20px',

              padding: "1px 8px",
              pointerEvents: ActiveFilters?.length === 0 ? "none" : "all"
            }}
          >
            <input
              ref={searchBarRef}
              id="search-bar"
              autocomplete="off"
              type="text"
              value={searchValue}
              onChange={handleSearchInputChange}
              placeholder="Search"
              // className="cm-filter-option-li"
              className="cm-filter-title"
              style={{
                fontFamily: "Inter",
                // flexGrow: 1,
                // marginBottom: "2px",
                // position: "relative",
                // padding: "0px 8px",
                // paddingRight: "30px",
                // fontSize: "10px",
                // lineHeight: "13px",
                // border: "1px solid red",
                borderRadius: "4px",
                // width: "160px",
                width: "80px",
                backgroundColor: "transparent",
                color: "#E6E6FA",
                outline: "none",
                border: "none"
              }}
            />
            <div
              ref={toggleCascaderRef}
              onClick={toggleCascaderVisibility}
              // className="cascader-toggle-button"
              className="text-colors cm-filter-title"
              style={{
                // position: "absolute",
                // right: "4px",
                // top: "37%",
                // transform: "translateY(-50%)",
                // fontSize: '8px',
                backgroundColor: "transparent",
                border: "none",
                // color: "#E6E6FA",
                cursor: "pointer",
              }}
            >
              {isCascaderVisible ? "▲" : "▼"}
            </div>
            {searchResults.length > 0 && isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "black",
                  // border: "1px solid #3E5056",
                  // border: "1px solid red",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 9999,
                  width: "200px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "#E6E6FA",
                }}
              >
                <div className="flex mx-1 my-1">
                  {/* <div onClick={handleSearchResultAllClick}>
                    {SelectAllIcon ? (
                      <CheckBoxOutlineBlankIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div> */}
                  {/* <div
                    style={{
                      fontSize: "15px",

                      text: "bold",
                    }}
                  >
                    Select All
                  </div> */}
                </div>
                {searchResults.map((result, index) => (
                   result.id.split('-').pop()!=='all' ? 
                  <div
                    key={index}
                    ref={searchResultsContainerRef}
                    style={{
                      padding: "4px",
                      cursor: "pointer",
                      display: "flex",
                      className: "search-result",
                      // border: "1px solid red"
                    }}
                  // onClick={() => handleSearchResultClick(result)}
                  >
                    <div
                      ref={searchListIconRef}
                      className="search-result-icon"
                      onClick={(event) =>
                        handleSearchResultCheckboxClick(event, result)
                      }
                    >
                      {selectedIds.includes(result.id) ? (
                        <CheckBoxIcon
                          sx={{ color: "#d6ff41", fontSize: "16px" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          sx={{ color: "#d6ff41", fontSize: "16px" }}
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: "4px", fontSize: "14px" }}>
                    {[getDisplayName(result.id)].map((part, index,arr) => {
                        console.log("parts multi ",part," ",result);
                        const regex = new RegExp(`(${searchValue})`, "gi");
                        const parts = part.split(regex);
                        
                        return (
                          <span key={index}>
                            {parts.map((p, i) => (
                              <span
                                key={i}
                                style={{
                                  color:
                                    p.toLowerCase() ===
                                      searchValue.toLowerCase()
                                      ? "#D6FF41"
                                      : "#E6E6FA",
                                  fontWeight:
                                    p.toLowerCase() ===
                                      searchValue.toLowerCase()
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {p}
                              </span>
                            ))}
                            {/* {index < result.path.length - 1 && " / "} */}
                          </span>
                        );
                      })}
                    </div>
                  </div>:null
                ))}
              </div>
            ) : searchValue &&
              searchResults.length === 0 &&
              isDropDownVisible ? (
              <div
                className="background-colors"
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  // backgroundColor: "#1C2427",
                  border: "1px solid #3E5056",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "#E6E6FA",
                }}
              >
                No data
              </div>
            ) : null}
          </div>
        </div>

        {isCascaderVisible && (
          <div
            ref={cascaderRef}
            style={{
              position: "fixed",
              zIndex: 99999,
              backgroundColor: (theme==="dark"?"#3e5056":"#caf0f8"),
              padding: "0.25rem",
              borderRadius: "5px",
              border:(theme==="light"?"1px solid #0a2472":"none")

            }}
          >
            <Cascader
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              flattenedData={flattenedData}
              searchResults={searchResults}
              enableFetching={enableFetching}
            />
          </div>
        )}
      </div>
      {/* <div>hello world</div> */}
    </div>
  );
};

export default CascaderWrapper;