import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import accessibility from "highcharts/modules/accessibility";
import boost from "highcharts/modules/boost";
import configDriven from "../ConfirgDriven";
boost(Highcharts);  // Initialize Boost module for performance

accessibility(Highcharts);

const StackChart2 = (props) => {
  console.log("stack chart2: ", props);

  function getFormattedString(id, obj) {
    console.log("id ltstroi", id, "    ");
    if (!id || typeof id !== "string") {
      // If id is null, undefined, or not a string, return it as is
      return id;
    }

    if (configDriven.show_display_name) {
      // Split the id by hyphens
      const keys = id.split("-");

      // Map the keys to their corresponding values in the object
      const values = keys.map((key) => obj?.[key] ?? key);

      // Join the values with hyphens and return the result
      return values.join("-");
    } else {
      return id;
    }
  }

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNames")
  );

  const handleLoader=()=>{
    console.log("handleLoader");
    props.setLoader(false);
  }

  const chartOptions = {
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 5000, // Enable boost for series with more than 5000 points
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    chart: {
      type: "column",
      zoomType: "xy",
      resetZoomButton: {
        position: {
          align: 'center', // Align the button to the center horizontally
          verticalAlign: 'top', // Keep it at the top vertically
          x: 0, // No horizontal offset, keep it centered
          y: 10, // Optional: Adjust vertical offset if needed
        },
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
      backgroundColor: "rgba(0,0,0,0)",
      // height: window.innerHeight < 950 ? window.innerHeight * 0.68 : window.innerHeight * 0.73,
      height:props.windowHeight,
      events: {
        render: handleLoader,
      }
    },
    title: {
      text: props.charttitle,
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    xAxis: {
      categories: props.categories,
      title: props.xtitle,
      labels: {
        style: {
          color: localStorage.getItem("CurrentTheme") === "Dark" ? "#FFFFFF" : "#1C2427",
        },
        rotation: 0, // Set rotation to 0 for horizontal labels
        overflow: "justify", // Ensure labels are displayed properly without overlapping
        step: Math.ceil(props.categories.length / 10) // Show fewer labels for large datasets
      },
    },
    yAxis: {
      min: 0,
      gridLineColor: '#808080',
      // gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color: localStorage.getItem("CurrentTheme") === "Dark" ? "#FFFFFF" : "#1C2427",
        },
      },
    },
    legend: {
      labelFormatter: function () {
        return getFormattedString(this.name, displayNamesChart);
      },
      enabled: true, // Enables the legend
      layout: 'horizontal', // Set to horizontal layout to align at the bottom
      align: 'center', // Align the legend in the center horizontally
      verticalAlign: 'bottom', // Align the legend at the bottom
      itemStyle: {
        fontSize: '12px', // Customize the font size if needed
      },
      // Fixed height for the legend
      maxHeight: 70, // Set this to the fixed height you want in pixels
      // Enable scrolling for the legend if the content exceeds maxHeight
      navigation: {
        enabled: true, // This allows scrolling when legend exceeds the height
      },
    },
    
    plotOptions: {
      column: {
        stacking: "normal",
        // animation: true, // Disable animations for performance
        events: {
          load: function () {
            this.update({
              plotOptions: {
                column: {
                  animation: {
                    duration: 1000,
                    easing: "easeOutQuad",
                  },
                },
              },
            });
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            textOutline: "none", // Remove stroke
          },
        },
        borderWidth: 0,
        minPointWidth: 10, // Set a minimum width for the columns
        pointPadding: 0.1, // Adjust point padding to ensure spacing
        groupPadding: 0.1, // Adjust group padding if needed
        point: {
          events: {
            click: function () {
              if (!props.NotAllow.includes(this.series.name)) {
                props.DrillDown(this);
              }
            },
            mouseOver: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");

              window.document.getElementsByTagName("body")[0].style.cursor = "pointer";
              if (props.NotAllow.includes(label)) {
                window.document.getElementsByTagName("body")[0].style.cursor = "not-allowed";
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName("body")[0].style.cursor = "default";
            },
          },
        },
        // states: {
        //   inactive: {
        //     opacity: 1, // Set opacity when not hovered
        //     brightness: 0.5,
        //   },
        //   hover: {
        //     opacity: 1, // Full opacity on hover
        //     brightness: 1,
        //   },
        // },
      },

    },
    series: props.series.map(serie => ({
      ...serie,
      className:'stackChart2-series',
      turboThreshold: 10000, // Increase the threshold for better handling of large datasets
    })),
    tooltip: {
      formatter: function () {
        const SeriesList = [];
        function chnnellist(list) {
          if (typeof list === 'object' && list !== null) {
            if (list.name.split(",")[0] !== "") {
              SeriesList.push(list.name.split(",")[0]);
            }
            if ("parent" in list && list.parent !== undefined) {
              chnnellist(list.parent);
            }
          } else if (typeof list === 'string') {
            SeriesList.push(list.split(",")[0]);
          }
        }
        
        chnnellist(this?.x);

        let label = SeriesList?.reverse()?.join("-");
        const foundLabel = label?.split("-")?.pop();

        var series = this.series;
        var spends = series.userOptions.spends[this.point.index];
        var contribution = series.userOptions.contribution ? series.userOptions.contribution[this.point.index] : undefined;

        return (
          "<b>" + label + "</b><br/>" +
          getFormattedString(series.name, displayNamesChart) + ": " + this.y + "<br/>" +
          "Spends: " + spends + "<br/>" +
          "Contribution: " + contribution
        );
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
  };

  return (
    <div id="trends_chart">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default StackChart2;
