import React, { useState, useEffect } from 'react';
import '../assets/ManualCSS/CustomSelection.css';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const CustomSelect = ({ value, onChange, options = [], isDetailPopUp = true, barWidth }) => {
  console.log("options ", options);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  useEffect(() => {
    if (options.length > 0) {
      const initialIndex = options.findIndex(option => option.value === value);
      setSelectedIndex(initialIndex !== -1 ? initialIndex : 0);
    }
  }, [options, value]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (index) => {
    console.log("handleOptionClick", index);
    setSelectedIndex(index);
    onChange({ target: { value: index } });
    setIsOpen(false);
  };

  const displayedValue = options[selectedIndex]?.name || 'Select...';

  return (
    <div className="custom-select-container" tabIndex={0} onBlur={() => setIsOpen(false)}>
      <div
        className=" text-colors background-colors cm-filter-title tab-class-fixed custom-select"
        style={{
          width: barWidth === undefined ? '6.5rem' : `${barWidth}px`,
          // fontSize: isDetailPopUp === false ? '10px' : '12px',
          // height: isDetailPopUp === false ? '20px' : '26px',
        }}
        onClick={handleToggle}
      >
        {isDetailPopUp === true ? displayedValue : value}
        <span className="dropdown-icon">
          {isOpen ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
        </span>
      </div>
      {isOpen && options.length > 0 && (
        <div className="custom-options">
          {options.map((item, index) => (
            <div
              key={index}
              className={`cm-filter-title custom-option ${hoveredOption === index ? 'hovered-option' : ''} ${value === index ? 'selected-option' : ''}`}
              onMouseEnter={() => setHoveredOption(index)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleOptionClick(index)}
              style={{
                width: barWidth === undefined ? '6.5rem' : `${barWidth - 7}px`,
                // fontSize: "10px"
              }}
            >
              {item.name} {/* Display the value here */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
