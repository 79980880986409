import { useEffect } from "react";
// import axios from "../axios";
import domtoimage from "dom-to-image";
import axiosChartDownload2 from "../Axios2";
import axiosChartDownload from "../Axios";
import LightThemeHexCode from "../Components/LightThemeColorCode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const downloadImage = async (URL, downloadedImageName) => {
  // const URL = "https://stagingnew.skewb.ai:8000/api//static//chart_images/Buisness_Landscape___Sales_combined_20241106154300.png"
  console.log("url of image", URL);
  try {
    const response = await axiosChartDownload2.get(URL, {
      responseType: "arraybuffer",
      accept: "*",
    });

    const blob = new Blob([response.data], { type: "image/png" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${downloadedImageName}.png`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading image: ", error);
  }
};

const useDownloadData = (
  downloadedImageName,
  downloadingChartTitle,
  isDownloadingChart,
  chartContainerID,
  Fixed,
  ActiveFilters,
  setLoader,
  Analysebrand,
  timeframe,
  DefaultStartDate,
  DefaultEndDate,
  setIsDownloadingChart,
  AnalyseKPI,
  Chartdata
) => {
  console.log("Fixeddddddddddd: ", Fixed, "active", ActiveFilters);

  console.log("setIsDownloadingChart in the donwload",setIsDownloadingChart)

  useEffect(() => {
    if (isDownloadingChart) {
      console.log("useeffect called image download");
      console.log("chartContianerID",chartContainerID)
      const chartContainer = document.getElementById(chartContainerID);
      console.log("chartContainer",chartContainer)
      domtoimage
        .toPng(chartContainer)
        .then(function (dataUrl) {
          var string = "";
          for (var i = 0; i < Fixed.length; i++) {
            string += Fixed[i].id;
            if (i !== Fixed.length - 1) {
              string += ",";
            }
          }

          if (ActiveFilters.length > 0) {
            string += ",";
            for (var j = 0; j < ActiveFilters.length; j++) {
              string += ActiveFilters[j].name;
              if (ActiveFilters.length - 1 !== j) {
                string += ",";
              }
            }
          }

          // for (var j = 0; j < ActiveFilters.length; j++) {
          //   string += ActiveFilters[j].name;
          //   if (ActiveFilters.length - 1 !== j) {
          //     string += ",";
          //   }
          // }
          console.log("api is calling")
          setLoader(true);
          axiosChartDownload
            .post("download_chart/create-image/", {
              title: downloadingChartTitle,
              filter: Analysebrand,
              hierarchy: Chartdata,
              // hierarchy: string,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              base64_image: dataUrl.split(",")[1],
              brand: Analysebrand,
              kpi: AnalyseKPI,
              theme: localStorage.getItem("theme"),
              LightThemeColorCode: [
                localStorage.getItem("ColorCode"),
                LightThemeHexCode[localStorage.getItem("ColorCode")],
              ],
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                // console.log("response.data: ", response.data);
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = response.data.data.combined_graph_url;
                console.log("link.href: ", link.href);
                // downloadImage(link.href, downloadedImageName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setLoader(false);
              }
            })
            .catch((data) => {
              setLoader(false);
            });
        })
        .catch(function (error) {
          setLoader(false);
          console.error("Error while generating chart image:", error);
        });

      setIsDownloadingChart(false);
    }
  }, [
    downloadedImageName,
    downloadingChartTitle,
    chartContainerID,
    Fixed,
    setIsDownloadingChart,
    setLoader,
    isDownloadingChart,
    DefaultStartDate,
    DefaultEndDate,
    Analysebrand,
    ActiveFilters,
    timeframe,
    AnalyseKPI,
  ]);
};

export default useDownloadData;
