import React from 'react'

const PopUp = (props) => {
    return (
        <div className="chart-overlay">
            <div className="background-colors chart-popup" style={{ width : props.width ? props.width : "" , height:window.innerHeight < 850 ? window.innerHeight * 0.85 : window.innerHeight * 0.92 }}>
                <h5>{props.SelectedLabel}</h5>
                {props.children}
                <button className="close-popup background-colors text-colors" onClick={() => props.setShowPopup(false)}>&times;</button>
                {/* <div className="row">
                    <div className="col-sm-12"> */}
                {/* </div>
                </div> */}
            </div>
        </div>
    )
}

export default PopUp