import React, { useRef, useEffect, useState } from "react";
import MultiLineColumnChart from "../Components/MultiLineColumnChart1";
const TrendChart = (props) => {
  console.log("Trendcharts Props", props);
  const trendsRef = useRef(null);
  const [TrendsHeight, setTrendsHeight] = useState("");
  const [divWidth, setDivWidth] = useState(0); // State to store div width
  // console.log("TrendsHeight: ", TrendsHeight);

  useEffect(() => {
    if (trendsRef.current !== null) {
      setTrendsHeight(trendsRef.current.clientHeight - 100);
    }
  }, [props.fetchMixChart2]);

  useEffect(() => {
    // Function to calculate width
    const calculateWidth = () => {
      if (trendsRef.current) {
        const width = trendsRef.current.offsetWidth; // Get the width of the div
        setDivWidth(width); // Store width in state
      }
    };

    // Calculate width on mount
    calculateWidth();

    // Optional: Recalculate width on window resize
    window.addEventListener("resize", calculateWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", calculateWidth);
  }, []);

  // console.log("props.fetchMixChart2.data: ", props.fetchMixChart2);
  const handleToggleClick = () => {
    props.setToggle(!props.toggle); // Toggle the state
    // console.log("Toggled:", !toggle);
  };

  return (
    <div className="row" style={{}}>
      <div className="col-sm-12" style={{ height: "max-content" }}>
        {/* <p className="card-title-lable">&nbsp;</p> */}
        <div
          className="trends-chart-height custom-scroller"
          // id="trends_chart"
          key={props.toggle}
          ref={trendsRef}
          style={{ overflowX: "scroll" }}
        >
          {/* Toggle Button */}
          {props.toggle ? (
            <i
              className="fas fa-magnifying-glass-minus icon-class"
              style={{
                backgroundColor: "#1c2427",
                // border:"2px solid #d6ff41",
                color: "white",
                border: "none",
                fontSize: "10px",
                padding: "5px 5px",
                cursor: "pointer",
              }}
              onClick={handleToggleClick}
            ></i>
          ) : (
            <i
              className="fas fa-magnifying-glass-plus icon-class"
              style={{
                backgroundColor: "#1c2427",
                // border:"2px solid #d6ff41",
                color: "white",
                border: "none",
                fontSize: "10px",
                padding: "5px 5px",
                cursor: "pointer",
              }}
              onClick={handleToggleClick}
            ></i>
          )}
          <div 
          id="trends_chart"
          >
          {props.fetchMixChart2?.data.length > 0 && TrendsHeight && (
            // <MixChart
            //   height={TrendsHeight}
            //   TrendChartRef={props.TrendChartRef}
            //   ChartData={props.TrendChartdata}
            //   Downloadchart={props.Downloadchart}
            //   setDownloadchart={props.setDownloadchart}
            //   MixChart={props.mixChartData}
            // />
            <MultiLineColumnChart
              data={props.fetchMixChart2}
              TrendsHeight={TrendsHeight}
              divWidth={divWidth}
              toggle={props.toggle}
              setToggle={props.setToggle}
              windowHeight={props.windowHeight}
            />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendChart;
