import { useEffect, useRef } from "react";
import Plotly from "plotly.js-dist";


const Plot = ({ data }) => {
  const chartRef = useRef(null);
  const theme = localStorage.getItem("theme");
  useEffect(() => {
    const chartNode = chartRef.current;

    const layout = {
      title: "",
      height: 450,
      width: 700,
      // border:"1px solid #1c2427",
      paper_bgcolor: theme==="dark"?"black":"#f7feff", // Background color outside the plot
      plot_bgcolor: theme==="dark"?"black":"#f7feff",
      font: { color: theme==="dark"?"#fff":"black", },
      showlegend: false, // Turn off the legend
      xaxis: {
        title: {
          text: "",
        },
        type: "category",
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      bargap: 0.1, // Controls the gap between bars (0 = no gap, 1 = max gap)
      bargroupgap: 0.05, // Controls the gap between groups of bars (multiple series)
    };

    const config = {
      displaylogo: false, // Hide the "Produced by Plotly" watermark
    };

    // Apply #d6ff41 color to columns (bars) via the marker property
    const updatedData = data.map((trace) => ({
      ...trace,
      marker: { color: theme==="dark"?"#d6ff41":"#0a2472" }, // Set bar color
      // overtemplate: '<b>X:</b> %{x}<br><b>Y:</b> %{y}<extra></extra>',
    }));

    Plotly.newPlot(chartNode, updatedData, layout, config);

    // Cleanup on unmount
    return () => {
      Plotly.purge(chartNode);
    };
  }, [data]);

  return <div
  style={{
    border:"1px solid #1c2427",
    borderRadius:"5px",
    display:'flex',
    alignItems:"center",
    justifyContent:'center',
    width: "100%",
    overflowX: window.innerWidth < 1000 ? "auto" : "hidden", // Enable horizontal scrolling for mobile
  }}
   ref={chartRef} />;
};

export default Plot;
