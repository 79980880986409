import React from 'react'

const SaveResultAlert = (props) => {
  // console.log(props.Parameter)

  const d = new Date()
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const currentDate = d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + ' | ' + d.getHours() + ':' + d.getMinutes() + ' hrs'

  return (
    <>
      <div
        className="modal fade bs-save-result-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel "
        aria-hidden="true"
        style={{ display: "block" ,}}
      >
        <div className="modal-dialog modal-dialog-centered " >
          <div style={{padding:"0px"}} className="modal-content background-colors ">
            <div className="modal-body model-result-popup " >
              <div className="row">
                <div className="col-sm-12 mb-3" onClick={() => props.setSaveResult(false)}>
                  <i
                    className="ri-close-fill model-close f-right icon-class icon-fill"
                    data-bs-dismiss="modal "
                    aria-label="Close "
                  ></i>
                </div>
              </div>
              {
                props.SaveResponse === '' ? <>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <span className="model-result-popup-h3 cm-filter-title text-colors" style={{fontWeight:'normal'}}>Name :</span>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control cm-filter-title "
                        type="text "
                        placeholder="Plan name"
                        style={{outline:'none',border:'none'}}
                        value={props.result_name}
                        onChange={(e) => props.setResultName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 cm-filter-title text-colors" style={{fontWeight:'normal'}}>Optimized for :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 cm-filter-title"  style={{fontWeight:'normal'}}>
                        {
                          props.Parameter.optimization_type === 'budget' ? 'Fixed Budget' : 'Target Growth'
                        } - {props.Parameter.budget}
                      </h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 cm-filter-title text-colors " style={{fontWeight:'normal'}}>Date &amp; Time :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 cm-filter-title"  style={{fontWeight:'normal'}}>
                        {currentDate}
                      </h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 text-colors cm-filter-title " style={{fontWeight:'normal'}}>User :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 cm-filter-title"  style={{fontWeight:'normal'}}>{localStorage.getItem('NameSkewb')}</h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-12 text-center">
                      <button onClick={() => props.SaveOptimisation()} className="btn-class">Save</button>
                    </div>
                  </div>
                </> : <>
                  <h1 style={{ textAlign: 'center', color: 'lime' }}>{props.SaveResponse}</h1>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SaveResultAlert