import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import CustomEvents from "highcharts-custom-events";
import Boost from 'highcharts/modules/boost';
import axiosChartDownload from "../Axios"; // download url will be change 
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";
import configDriven from '../ConfirgDriven';
import { Height } from "@mui/icons-material";

HighchartsSunburst(Highcharts);
CustomEvents(Highcharts);
Boost(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
  var path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9
  ];
  return path;
};


const Sunburst = (props) => {


  console.log("Sunburst props", props);
  const refChart = useRef();

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  



  let RootNade = "";
  let options = {
    chart: {
      backgroundColor: "rgba(0,0,0,0)",
      // backgroundColor:  localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
      // height: window.innerWidth>600 ?window.innerHeight < 850 ? window.innerHeight * 0.75 : window.innerHeight * 0.82:props.height,
      height: window.innerWidth>600 ?props.windowHeight:props.height,
      // height:window.innerWidth<=600 ?props.windowHeight,
      className: "highchart-class",
      events: {
        load: function () {
          var chart = this;
          var plotWidth = chart.plotWidth;
          var plotHeight = chart.plotHeight;
          var centerX = chart.plotLeft + plotWidth / 2;
          var centerY = chart.plotTop + plotHeight / 2;
          var radius = Math.min(plotWidth, plotHeight) / 2;
      
          // Add a mousemove event listener on the chart container
          Highcharts.addEvent(chart.container, 'mousemove', function (event) {
            var rect = chart.container.getBoundingClientRect();
            var mouseX = event.clientX - rect.left;
            var mouseY = event.clientY - rect.top;
      
            // Calculate the distance from the center of the Sunburst
            var distance = Math.sqrt(
              Math.pow(mouseX - centerX, 2) + Math.pow(mouseY - centerY, 2)
            );
      
            // If the mouse is outside the Sunburst circle, hide the tooltip
            if (distance > radius) {
              chart.tooltip.hide();
            }
          });
        
        }}},
      
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 1,
      usePreAllocated: true,
      enabled: true, // Ensure the Boost module is enabled
      // Consider lowering these values if necessary:
      turboThreshold: 15000,
      allowForce: true, // Forces Boost even for complex series
      series: [{
        boostThreshold: 15000, // Points to start using the Boost module
      }]
    },

    plotOptions: {
      series: {
        turboThreshold: 15000,
        animation: false,
      },
    },

    series: [
      {
        type: "sunburst",
        className:'sunburst-charts',
        data: props.SunburstData,
        name: props.name,
        // name:"Root",
        allowDrillToNode: true,
        allowTraversingTree: true,
        allowPointSelect: true,
        cursor: "pointer",

        point: {

          events: {

            click: function (e) {
              // console.log(RootNade)
              // console.log(e.point.id)
              var series = refChart.current.chart.series[0];
              if (RootNade === e.point.id) {
                RootNade = e.point.parent;
                series.setRootNode(e.point.node.parentNode.id, true, {
                  trigger: "click",
                });
              } else {
                RootNade = e.point.id;
                series.setRootNode(e.point.id, true, { trigger: "click" });
              }

            },
          },
        },
        dataLabels: {

          formatter: function () {
            const displayName = returnDisplayName(this.point.name);
            return `${displayName} (${this.point.value})`;
          },
          filter: { property: "innerArcLength", operator: ">", value: 16 },
          style: {
            // fontWeight: 'normal',
            // color: '#ffffff',
            textShadow: false,
            textOutline: 'none',
          },
        },

        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: { property: "outerArcLength", operator: ">", value: 64 },
            },
            colorByPoint: true,
          },

        ],
      },
    ],
    tooltip: {
      formatter: function () {
        console.log("this.point", this.point)
        const displayName = returnDisplayName(this.point.name);
        let perc = "";
    
        // if ("point" in this && parseInt(props.KpiPerc) === 1) {
        if(true){
          perc = ` (${((this.point.node.val / this.point.node.parentNode.val) * 100).toFixed(1)}%)`;
        }
    
        return `${displayName} (${this.point.value})${perc}`;
      },
      // formatter: function () {
      //   var perc;
      //   if ("point" in this) {
      //     if (parseInt(props.KpiPerc) === 1) {
      //       perc =
      //         (
      //           (this.point.node.val / this.point.node.parentNode.val) *
      //           100
      //         ).toFixed(1) + "%";
      //     } else {
      //       perc = "";
      //     }
      //     // return (
      //     //   this.point.name +
      //     //   " (" +
      //     //   this.point.value.toFixed(props.DecimalPoint) +
      //     //   ") " +
      //     //   perc
      //     // );
      //   } else {
      //   }
      // },
      // shared: true,
      // useHTML: true,
      // borderColor: "#ffffff",
      // backgroundColor: "#ffffff",
      // borderRadius: 8,
    },
    breadcrumbs: {
      formatter: function (breadcrumb) {
        console.log("breadcrumbs sunburst ", breadcrumb);
        if (breadcrumb.level === 0) {
          return null; // Do not show the root node
        } else {
          // Get the formatted breadcrumb name
          let displayName = returnDisplayName(breadcrumb.levelOptions.name);
    
          // Ensure there's only one space around the separator
          displayName = displayName.replace(/\s\/\s/g, '/'); // Adjust spaces around the separator
          
          return displayName;
        }
      }
    },
    
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
        },
      },
    },
  };
  return (
    <div id="media_sunburst_chart" style={{marginTop:'10px'}}>
      <HighchartsReact
        ref={refChart}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default Sunburst;

