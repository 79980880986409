import { CssBaseline, Stack } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import Axios from "./Axios";

import Home from "./pages/Home";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";

const themecolors=localStorage.getItem("theme");

let theme = createTheme({
  palette: {
    mode: themecolors === "dark" ? "dark" : "light", // This sets the theme mode
    background: {
      default: "#1c2427"
    },
    themeColor: themecolors==="dark"?"#d6ff41":"#1c2427",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "white",
        },
      },
    },
  },

  typography: {
    htmlFontSize: 10,
    fontFamily: ["Nunito", "sans-serif"].join(","),
    h1: {
      fontSize: 16,
      "@media (max-width: 600px)": { // xs (mobile)
        fontSize: 14,
      },
      "@media (min-width: 600px) and (max-width: 900px)": { // sm (tablet)
        fontSize: 15,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": { // md (small laptop)
        fontSize: 16,
      },
      "@media (min-width: 1200px)": { // lg and xl (desktop)
        fontSize: 18,
      },
    },
    h2: {
      fontSize: 15,
      "@media (max-width: 600px)": {
        fontSize: 13,
      },
      "@media (min-width: 600px) and (max-width: 900px)": {
        fontSize: 14,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": {
        fontSize: 15,
      },
      "@media (min-width: 1200px)": {
        fontSize: 17,
      },
    },
    h3: {
      fontSize: 14,
      "@media (max-width: 600px)": {
        fontSize: 12,
      },
      "@media (min-width: 600px) and (max-width: 900px)": {
        fontSize: 13,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": {
        fontSize: 14,
      },
      "@media (min-width: 1200px)": {
        fontSize: 16,
      },
    },
    h4: {
      fontSize: 13,
      "@media (max-width: 600px)": {
        fontSize: 11,
      },
      "@media (min-width: 600px) and (max-width: 900px)": {
        fontSize: 12,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": {
        fontSize: 13,
      },
      "@media (min-width: 1200px)": {
        fontSize: 15,
      },
    },
    h5: {
      fontSize: 12,
      "@media (max-width: 600px)": {
        fontSize: 10,
      },
      "@media (min-width: 600px) and (max-width: 900px)": {
        fontSize: 11,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": {
        fontSize: 12,
      },
      "@media (min-width: 1200px)": {
        fontSize: 14,
      },
    },
    h6: {
      fontSize: 11,
      "@media (max-width: 600px)": {
        fontSize: 9,
      },
      "@media (min-width: 600px) and (max-width: 900px)": {
        fontSize: 10,
      },
      "@media (min-width: 900px) and (max-width: 1200px)": {
        fontSize: 11,
      },
      "@media (min-width: 1200px)": {
        fontSize: 13,
      },
    },
    body1: {
      fontSize: 10,
      "@media (max-width: 600px)": {
        fontSize: 10,
      },
      "@media (min-width: 600px) and (max-width: 1000px)": {
        fontSize: 12,
      },
      "@media screen and (min-width: 1199px)":{
        fontSize: 12,
      },
      "@media screen and (max-width: 1680px)":{
        fontSize: 15,
      },
      "@media screen and (min-width: 1680px)":{
        fontSize: 15,
      },
      "@media screen and (min-width: 1900px)":{
        fontSize: 16,
      },
      "@media screen and (min-width: 2048px)":{
        fontSize: 16,
      },
      "@media screen and (min-width: 3840px)":{
        fontSize: 20,
      },
      
    },
  },
  
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  // const [isLoading, setIsLoading] = useState(false);


  // Axios
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.data.error) {
        console.log(error.response.data.error);
      }
      return Promise.reject(error);
    }
  );

  return (
    <div className="skewb-div-gpt">
      {/* <Topbar /> */}
      {/* <Menubar /> */}

      <div className="main-content-div">
        <div className="page-content-div" >
          <div
            // className="card card-body" 
            className="card card-min-height optimizer-table-height card-body brand-card pt-2 gpt-main-card"
            style={{ marginTop: '10px' }}
          >

            <Stack
              justifyContent={"space-between"}
              flexGrow={1}
              sx={{ height: `${window.innerHeight < 850 ? window.innerHeight * 0.85 : window.innerHeight * 0.90}px` }}
            >
              <>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Home />
                </ThemeProvider>
              </>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;