import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);


class MixChart extends React.Component {

    render() {
        let graphData = [];
        let colors = ['#ff8383', '#ffe767', '#91bd6e']
        for (let index = 0; index < this.props.ChartData.series.length; index++) {
            const element = this.props.ChartData.series[index];
            const obj = {
                type: element.type,
                name: element.name,
                data: element.data,
                yAxis: element.yAxis,
                color: element.color ? element.color : colors[index] ? colors[index] : '',
                borderColor: '#1c2427',
                dataLabels: {
                    enabled: false,
                    allowOverlap: true,
                    formatter: function (e) {
                        return ("<div style='color:#ffffff;font-family:Inter;font-size:10px;'>" +
                            this.x +
                            "</div>")
                    },
                    useHTML: true
                },
                showInLegend: true,
            };
            graphData.push(obj);
        }

        let temp = this.props.ChartData.yAxis
        if (temp.length > 1) {
            temp[1].title.rotation = -90
        }
        const options = {
            title: {
                text: '',
                style: {
                    color: "#D6FF41",
                },
            },
            credits: {
                enabled: false,
            },
            chart: {
                // backgroundColor: localStorage.getItem("CurrentTheme") === "Dark" ? "#1c2427" : "",
                backgroundColor: 'rgba(0,0,0,0)',
                type: "line",
                zoomType: "xy",
                resetZoomButton: {
                    position: {
                        align: 'center', // Align the button to the center horizontally
                        verticalAlign: 'top', // Keep it at the top vertically
                        x: 0, // No horizontal offset, keep it centered
                        y: 10, // Optional: Adjust vertical offset if needed
                    },
                    theme: {
                        fill: "",
                        stroke: "#d6ff41",
                        style: {
                            color: "white",
                        },
                    },
                },
                // height: window.innerHeight < 850 ? window.innerHeight * 0.73 : window.innerHeight * 0.84,
                height: this.props.windowHeight,
                showAxes: true,
            },
            xAxis: {
                visible: true,
                lineWidth: 1,
                title: {
                    enabled: true,
                    text: '',
                    style: {
                        color: "#D6FF41",
                    },
                },
                labels: {
                    style: {
                        color: localStorage.getItem("CurrentTheme") === "Dark" ? "#FFFFFF" : "black",
                    },
                },
                categories: this.props.ChartData.xAxis.display_date,
                gridLineColor: '#808080',
            },
            yAxis: temp.map((yAxisItem) => ({
                ...yAxisItem,
                gridLineColor: '#808080'  // Set grid line color to grey for y-axis
            })),
            tooltip: {
                enabled: true,
                headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="padding:0">{series.name}: </td>' +
                    '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table></div>",
                shared: true,
                useHTML: true,
                borderColor: '#ffffff',
                backgroundColor: '#ffffff',
                borderRadius: 8
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: localStorage.getItem('CurrentTheme') === "Dark" ? "#fff" : "1c2427"
                },
                itemHoverStyle: {
                    color: localStorage.getItem('CurrentTheme') === "Dark" ? "#fff" : "1c2427"
                }
            },
            series: graphData,
            exporting: {
                enabled: false
            }
        };


        return (
            <>
                {graphData.length === this.props.ChartData.series.length ? <HighchartsReact highcharts={Highcharts} ref={this.props.ChartRef} options={options} /> : null}
            </>
        );
    }

};

export default MixChart;