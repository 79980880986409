import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const PopUp = ({ onClose, children, setLogs, setFeedbackValue, height, width }) => {
  
  console.log(height, width);
  const theme =localStorage.getItem("theme")
  return (
    <Box
      sx={{
        position: "fixed",
        top: 10,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 999,
        // border: "1px solid red",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          // width: "30vw",
          width: width,
          // height: "35vh",
          height: height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 99999999999,
          transform: "translate(-50%, -50%)",
          
          
          
        }}
      >
        <Box
          onClick={() => {
            onClose(false);
            setLogs("");
            setFeedbackValue("");
          }}
          sx={{
            position: "absolute",
            top: 3,
            right: 8,
            zIndex: 999999,
            cursor: "pointer",
            padding: "8px",
            color: "themeColor",
          }}
        >
          <CancelIcon className="theme-colors" sx={{ fontSize: "20px" }} />
        </Box>
        <Box
          sx={{
            background: theme === "dark"?"#1c2427":"#f7feff",

            margin:'10px',
            // borderRadius: "8px",
            width: "100%",
            height: "100%",
            position: "relative",
            
            
            borderRadius:'8px'
            
            // overflow: "scroll",
          }}
          // onClick={(e) => e.stopPropagation()}
        >
          
          {children()}
        </Box>
      </Box>
    </Box>
  );
};

export default PopUp;
