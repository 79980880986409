import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import axiosDownload from "../Axios"; // download url will be change
import LineChart from "../Components/LineChart";
import Loader from "../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../images/download-icon.png";
import CustomSelect from "../Components/CustomSelect";
import GCSingleCascader from "../Components/GCSingleCascader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryAdstock from "../Components/SummaryAdstock1";
import PopUp from "../Components/PopUp";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";
import axiosChartDownload from "../Axios"; // download url will be change
import domtoimage from "dom-to-image";
import downloadFlag from "../DownloadFlag";
import configDriven from "../ConfirgDriven";
import { ConstructionOutlined } from "@mui/icons-material";

const Main = () => {
  const refGraphDiv = useRef(null);
  const RowWidthRef = useRef(null);
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [ChannelAll, setChannelAll] = useState([]);
  // console.log("ChannelAll: ", ChannelAll);
  const [SelectedChannel, setSelectedChannel] = useState("");
  console.log("SelectedChannel: ", SelectedChannel);
  const [ROCData, setROCData] = useState({});
  // console.log("ROCData: ", ROCData);
  const [AdstockData, setAdstockData] = useState({});
  const [Summary, setSummary] = useState([]);
  const [FooterNotes, setFooterNotes] = useState("");

  const [ChartData, setChartData] = useState([]);
  // console.log("ChartData: ", ChartData);
  const [SelectedChartData, setSelectedChartData] = useState({});
  // console.log("SelectedChartData: ", SelectedChartData);
  const [FetchCall, setFetchCall] = useState(false);

  //Dropdown
  const [Fixed, setFixed] = useState([]);
  console.log("Fixed: ", Fixed);
  const [Sortable, setSortable] = useState([]);
  console.log("Sortable: ", Sortable);
  const [HeirarchyData, setHeirarchyData] = useState({});
  // console.log("HeirarchyData: ", HeirarchyData);
  const [Downloadchart, setDownloadchart] = useState(false);

  const [Notes, setNotes] = useState("");
  const [ShowSummary, setShowSummary] = useState(0);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [payloadChartData, setPayloadChartData] = useState({});
  const [tabName, setTabName] = useState([]);

  const [onChangeValue, setOnchangeValue] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredChannels, setFilteredChannels] = useState(ChannelAll);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null); // State to store any error
  const [matchId, setMatchId] = useState("");
  const scrollRef = useRef(null);
  const [newChartData, setNewChartData]= useState({});

  console.log("matchId: ", matchId);

  function findFirstAccessibleId(data) {
    function search(node) {
      if (node.access === 1) {
        return node.id;
      }

      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          const result = search(child);
          if (result) {
            return result;
          }
        }
      }

      return null;
    }

    for (let item of data) {
      const result = search(item);
      if (result) {
        return result;
      }
    }

    return null; // If no accessible id is found
  }

  useEffect(() => {
    // Define the URL and payload for the API request
    const url = "newroc_channel/";
    const payload = {
      brand: Analyse.Brand,
      kpi: Analyse.KPI,
    };

    // Function to make the API call
    const makePostRequest = async () => {
      try {
        const response = await axios.post(url, payload);
        setResponseData(response.data); // Store the response data in state

        setMatchId(findFirstAccessibleId(response.data.data));
        setSelectedChannel(findFirstAccessibleId(response.data.data));

      } catch (error) {
        setError(error); // Store any error in state
        console.error("Error making the POST request:", error);
      }
    };

    // Call the function
    makePostRequest();
  }, [Analyse.Brand, Analyse.KPI]);

  // console.log("tabName: ", tabName);

  const userName = localStorage.getItem("NameSkewb");

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    Sortable,
    setLoader,
    Analyse.Brand,
    "",
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI,
    newChartData,
  );

  useEffect(() => {
    setFetchCall(true);
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown/", {
        hirarchy: Analyse.Brand,
        page: "roc",
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response.data: ", response.data);
          var arr = [];
          for (var k = 0; k < response.data.data.sortable.length; k++) {
            var obj = response.data.data.sortable[k];
            obj["default_value"] =
              response.data.data.sortable[k]["options"][0]["value"];
            arr.push(obj);
          }
          setFixed(response.data.data.fixed);
          setSortable(arr);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand]);

  useEffect(() => {
    const controller = new AbortController();
    if (Fixed.length !== 0) {
      setLoader(true);
      setSelectedChannel("");
      // setHeirarchyData([]);
      axios
        .post(
          "get_roc_channels/",
          {
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            Hierarchy: HeirarchyData,
            filter_key: onChangeValue,
          },
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          if (response.data.error !== 1) {
            console.log("SelectedChannel resonse", response.data.data[0].key);
            setChannelAll(response.data.data);
            setFilteredChannels(response.data.data);
            setSelectedChannel(response.data.data[0].key);
            setFooterNotes(response.data.notes);
          } else {
            setChannelAll([]);
            // setSelectedChannel('');
            setFooterNotes("");
            // toast.error(response.data.erroMsg);
          }
          // setSelectedChannel("Influencer")
          setFetchCall(true);
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }

    return () => {
      controller.abort();
    };
  }, [Analyse.KPI, Analyse.Brand, Fixed, HeirarchyData, onChangeValue]);



  useEffect(() => {
    // const controller = new AbortController();
    console.log("useeffect called outside");
    console.log("SelectedChannel ", SelectedChannel, "    ", FetchCall);
    if (SelectedChannel !== "" && FetchCall) {
      console.log("useeffect called inside");
      setLoader(true);
      setROCData({});
      setAdstockData({});
      setSummary([]);

      let payload = {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        channel: SelectedChannel,
        theme: localStorage.getItem("theme"),
        LightThemeColorCode: [
          localStorage.getItem("ColorCode"),
          LightThemeHexCode[localStorage.getItem("ColorCode")],
        ],
      };

      for (const property in HeirarchyData) {
        payload[property] = HeirarchyData[property];
      }

      setNewChartData({
        channel: payload.channel, // Setting the channel key from payload
        ...HeirarchyData, // Adding all key-value pairs from HeirarchyData
      });

      axios
        .post("get_roc/", payload)
        .then((response) => {
          if (response.data.error === 1) {
            setROCData({});
            setSummary([]);
            setLoader(false);
            // toast.error(response.data.erroMsg);
            setFetchCall(false);
          } else {
            if (SelectedChannel === "All Channels") {
              setROCData(response.data.data);
              setChartData([]);
              setSelectedChartData({});
              setPayloadChartData(response.data.data);
              // setTabName(null);
            } else {
              setAdstockData(response.data.data.adstock);
              setChartData(response.data.data.chart_data);
              setSelectedChartData(response.data.data.chart_data[0]);
              setPayloadChartData(response.data.data);
              setTabName(response.data.data.chart_data[0].tab_name);
            }
            setSummary(response.data.data.summary);
            if (response.data.data.show_summary !== undefined) {
              setShowSummary(response.data.data.show_summary);
            }

            setFetchCall(false);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [SelectedChannel, Analyse.Brand, Analyse.KPI, HeirarchyData, FetchCall, matchId]);

  useEffect(() => {
    if (JSON.stringify(SelectedChartData) !== "{}") {
      setROCData(SelectedChartData.data);
    }
  }, [SelectedChartData]);

  console.log("selectedChannel", SelectedChannel);
  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("roc/", {
        data: payloadChartData,
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        download:1,
        // channel: SelectedChannel,
        // chartdata: HeirarchyData,
      })
      .then((response) => {
        // console.log("response roc: ", response.data);
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const [ShowPopup, setShowPopup] = useState(false);

  const OnChangeHandler = (index, value) => {
    setOnchangeValue(value);
    var sortable = [...Sortable];
    sortable[index].default_value = value;
    // setSortable(sortable);

    //Getting next levels
    let next_level_arr = [];
    for (var j = 0; j < sortable.length; j++) {
      if (j > index) {
        next_level_arr.push(sortable[j].level);
      }
    }
    const next_level = next_level_arr.toString().replaceAll(",", ";");

    //Getting befors selected  values
    let selection_value = [];
    var i = index - 1;
    while (i >= 0) {
      if (sortable[i]["default_value"]) {
        selection_value.push(
          sortable[i]["default_value"] === "all"
            ? ""
            : sortable[i]["default_value"]
        );
      }
      i--;
    }
    const selection = selection_value.toString().replaceAll(",", ";");
    setLoader(true);
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        selection: selection !== "all" ? selection : "",
        next_level: next_level,
        page: "roc",
        curr_selection: value,
      })
      .then((response) => {
        var data = [...sortable];
        for (var i = 0; i < sortable.length; i++) {
          if (response.data.data[data[i].level]) {
            data[i]["options"] = response.data.data[data[i].level];
            data[i]["default_value"] =
              response.data.data[data[i].level][0].value;
          }
        }
        setSortable(data);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    var chartdata = {};
    for (var k = 0; k < Fixed.length; k++) {
      chartdata[Fixed[k].id] = Fixed[k].value;
    }
    for (var l = 0; l < Sortable.length; l++) {
      chartdata[Sortable[l].name] = Sortable[l].default_value;
    }
    setHeirarchyData(chartdata);
    setFetchCall(true);
  }, [Fixed, Sortable]);

  useEffect(() => {
    setFetchCall(true);
  }, [Analyse.Brand]);

  const [ChartHeight, setChartHeight] = useState("");
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [ROCData, SelectedChartData]);

  const [DivHeight, setDivHeight] = useState("");
  const DivRef = useRef(null);

  useEffect(() => {
    if (DivRef.current !== null) {
      setDivHeight(DivRef.current.clientHeight);
    }
  }, [ROCData]);

  function getFormattedString(id, obj) {
    console.log("id ltstroi", id, "    ", obj);
    if (!id || typeof id !== "string") {
      return id;
    }

    if (configDriven.show_display_name) {
      const keys = id.split("-");
      const values = keys.map((key) => obj?.[key] ?? key);
      return values.join("-");
    } else {
      return id;
    }
  }

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNamesChart")
  );

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  };

  const payloadPDF = {
    footer_content: "Private & Confidential",
    table_heading1: "Growth Curves",
    image_heading1: "Growth Curves",
    username: userName,
    timeframe: "",
    start_date: "",
    end_date: "",
    titlename: "Growth Curves",
    category: "",
    chartdata: HeirarchyData,
    brand: Analyse.Brand,
    kpi: Analyse.KPI,
    tabname: "roc",
  };
  const downloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);

    const chartContainers = {};

    if (SelectedChannel === "All Channels") {
      chartContainers["All Channels"] =
        document.getElementById("growth_curve_chart");
    } else {
      chartContainers[SelectedChartData.tab_name] =
        document.getElementById("growth_curve_chart");
      chartContainers["Adstock"] = document.getElementById("adstock_chart");
    }

    const validChartContainers = Object.fromEntries(
      Object.entries(chartContainers).filter(([key, value]) => value !== null)
    );

    // console.log("Valid Chart Containers:", validChartContainers);

    // console.log("chartContainers: ", chartContainers);

    if (
      Object.values(validChartContainers).every(
        (container) => container !== null
      )
    ) {
      try {
        const dataUrls = await Promise.all(
          Object.entries(validChartContainers).map(async ([key, container]) => {
            const dataUrl = await domtoimage.toPng(container);
            return { [key]: dataUrl.split(",")[1] };
          })
        );

        const response = await axiosDownload.post("roc/", {
          data: payloadChartData,
          theme:localStorage.getItem("theme"),
          download:1,
          download_type: "PDF",
        });

        const imagesObject = Object.assign({}, ...dataUrls);

        // console.log("imagesObject: ", imagesObject);

        // SelectedChannel === "All Channels" ? payloadPDF["All Channles"] = response.data["All Channels"] : payloadPDF[tabName] = response.data[payloadChartData?.chart_data[0]?.tab_name];
        if (SelectedChannel === "All Channels") {
          payloadPDF["All Channels"] = response.data["All Channels"];
        } else {
          payloadPDF[tabName] =
            response.data[payloadChartData?.chart_data[0]?.tab_name];
          payloadPDF["Adstock"] = response.data.Adstock;
        }

        // console.log("payload pdf: ", payloadPDF);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/rocpdf/",
          {
            images: imagesObject,
            theme:localStorage.getItem("theme"),
            document: payloadPDF,
          }
        );

        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    } else {
      setLoader(false);
      console.error("Chart containers not found");
    }
  };

  //  For searching channels based in search query..................
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter channels based on the search query
    const filtered = ChannelAll?.filter((channel) =>
      channel.key.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredChannels(filtered);
  };
  // const [cascaderData,setCascaderData]=useState(responseData?.data);

  // function findDeepestAccessOne(data) {
  //   let result = null; // To store the ID of the desired node
  //   let maxDepth = -1; // To track the maximum depth encountered

  //   // Helper function to perform the recursive search
  //   function search(node, depth) {
  //     // Check if the node meets the condition (access: 1)
  //     if (node.access === 1) {
  //       // If this node is deeper than the previously found node, update result
  //       if (depth > maxDepth) {
  //         maxDepth = depth;
  //         result = node.id;
  //       }
  //     }

  //     // Continue searching in the child nodes
  //     for (let child of node.children) {
  //       search(child, depth + 1); // Recursively call search for each child node
  //     }
  //   }

  //   // Start searching from the root nodes with depth 0
  //   for (let root of data) {
  //     search(root, 0);
  //   }

  //   return result;
  // }
  // function findFirstAccessibleId(data) {
  //   function search(node) {
  //     if (node.access === 1) {
  //       return node.id;
  //     }

  //     if (node.children && node.children.length > 0) {
  //       for (let child of node.children) {
  //         const result = search(child);
  //         if (result) {
  //           return result;
  //         }
  //       }
  //     }

  //     return null;
  //   }

  //   for (let item of data) {
  //     const result = search(item);
  //     if (result) {
  //       return result;
  //     }
  //   }

  //   return null; // If no accessible id is found
  // }

  // console.log("hey", findFirstAccessibleId(responseData?.data));
  console.log("matchID", matchId);

  const [windowHeight, setWindowHeight] = useState(0); // Default height value
  useEffect(() => {

    if (refGraphDiv.current) {

      const calculatedHeight = window.innerHeight - refGraphDiv.current.getBoundingClientRect().top - 50;
      console.log("Chart Height", calculatedHeight);
      window.innerWidth < 1000 ? setWindowHeight(Math.max(330, calculatedHeight)) : setWindowHeight(Math.max(0, calculatedHeight));
    }

  }, [refGraphDiv.current?.getBoundingClientRect().top])

  return (
    <div className="skewb-div">
      {loader ? <Loader /> : null}
      {ShowPopup ? (
        <PopUp setShowPopup={setShowPopup} height="100%">
          {JSON.stringify(AdstockData) !== "{}" ? (
            <LineChart
              ChartTitle={AdstockData.data.title}
              series={AdstockData.data.series}
              xunits={AdstockData.data.xunits}
              plotBands={AdstockData.data.plotBands}
              labels={AdstockData.data.labels}
              yunits={AdstockData.data.yunits}
              SelectedChannel={""}
              height={"h-100-per"}
              windowHeight={windowHeight}
              setDownloadchart={setDownloadchart}
              Downloadchart={Downloadchart}
              Brand={Analyse.brand}
              ActiveFilters={Sortable}
              Fixed={Fixed}
              setLoader={setLoader}
            />
          ) : null}
        </PopUp>
      ) : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content-div" ref={scrollRef}>
        <div className="page-content-div">
          <div className="filter-class" style={{ height: '30px' }}>
            <div
              className="heirarchy-class2 mb-1"
              style={{ border: "none", width: "100vw" }}
            >
              <div
                className="heirarchy-class2-div"
              // style={{  width: "100vw"}}
              >
                {responseData && (
                  <GCSingleCascader
                    data={responseData.data}
                    match={matchId}
                    setGetBrand={setSelectedChannel}
                    setFetchCall={setFetchCall}
                    scrollRef={scrollRef}
                  />
                )}

              </div>
            </div>
          </div>

          <div className="container-fluid container-fluid-curve pt-1" >
            <div className="row row-div-class"
              style={{ height: '100%' }}
            >
              <div
                className={
                  parseInt(ShowSummary) === 0 &&
                    JSON.stringify(AdstockData) === "{}"
                    ? "col-xl-12 "
                    : "col-xl-8 "
                }
                style={{ height: '100%' }}
              // style={{border:"2px solid white"}}
              >
                <div
                  // className="card card-min-height align-items-sm-center pt-2"
                  className="card card-min-height optimizer-table-height card-body brand-card pt-2"
                  ref={DivRef}
                // style={{
                //   background: "transparent",
                //   height: `${
                //     window.innerHeight < 850
                //       ? window.innerHeight * 0.8
                //       : window.innerHeight * 0.87
                //   }px`,
                // }}
                >

                  <div
                    className="col-sm-9 ef-drivers card-min-height "
                    style={{
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                      // border:"2px solid green",
                      width: "100%"
                    }}
                  >
                    {JSON.stringify(ROCData) !== "{}" ? (
                      <>
                        <div className="row align-items-center justify-content-sm-between pb-0">
                          <div className="col-sm-11 d-flex align-items-center justify-content-sm-end" >
                            {ChartData.length > 1
                              ? // <ul className="p-0 m-0">
                              //   {
                              ChartData.map((item, index) => {
                                return (

                                  <p
                                    key={index}
                                    className={
                                      SelectedChartData === item
                                        ? "cursor-pointer text-white lt-st custom-input-box tab-class-clicked"
                                        : "cursor-pointer text-lable text-white custom-input-box tab-class-unclicked"
                                    }
                                    onClick={() => {
                                      setSelectedChartData(item);
                                      setTabName(item.tab_name);
                                    }}
                                  >
                                    {item.tab_name}
                                  </p>
                                );
                              })
                              : //   }
                              // </ul>
                              null}
                          </div>
                          <div className="col-sm-1 d-flex align-items-center justify-content-end">
                            {Notes !== "" ? (
                              <>
                                <p className="icon-class icon-fill"
                                  id='notesdiv'
                                // style={{cursor:'pointer'}}
                                >
                                  <svg
                                    src={InfoIcon}
                                    id="notes"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    class="bi bi-info-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                  </svg>
                                </p>

                                <Tooltip anchorSelect="#notesdiv" clickable>
                                  {/* <div className="api-notes">
                                      {Notes}
                                    </div> */}
                                  <div
                                    className="api-notes"
                                    style={{ height: 'max-content', color: 'white' }}
                                    dangerouslySetInnerHTML={{
                                      __html: Notes,
                                    }}
                                  ></div>
                                </Tooltip>
                              </>
                            ) : null}

                            {(downloadFlag.chart ||
                              downloadFlag.data ||
                              downloadFlag.pdf) && (
                                <span className="flex-shrink-0 dropdown background-colors">
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                    id="dropdownUser2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <p className="icon-class icon-fill">
                                      <svg
                                        src={Download}
                                        title="Download"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        class="bi bi-download "
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                      </svg>
                                    </p>
                                  </Link>
                                  <ul
                                    className="dropdown-menu text-small shadow background-colors"
                                    aria-labelledby="dropdownUser2"
                                  >
                                    {downloadFlag.chart && (
                                      <li onClick={(e) => { }}>
                                        <Link
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (ROCData.series.length > 0) {
                                              setChartContainerID(
                                                "growth_curve_chart"
                                              );
                                              setIsDownloadingChart(true);
                                              setDownloadedImageName(
                                                "Growth Curves"
                                              );
                                              setDownloadingChartTitle(
                                                "Growth Curves"
                                              );
                                              // setDownloadchart(true)
                                              // setLoader(true)
                                            } else {
                                              toast.error(
                                                "Chartdata is not available"
                                              );
                                            }
                                          }}
                                        >
                                          Download Chart
                                        </Link>
                                      </li>
                                    )}

                                    {downloadFlag.data && (
                                      <li onClick={(e) => { }}>
                                        <Link
                                          className="dropdown-item"
                                          onClick={(e) => DownloadData(e)}
                                        >
                                          Download Data
                                        </Link>
                                      </li>
                                    )}
                                    {downloadFlag.pdf && (
                                      <li onClick={(e) => { }}>
                                        <Link
                                          className="dropdown-item"
                                          onClick={(e) => downloadPDF(e)}
                                        >
                                          Download PDF
                                        </Link>
                                      </li>
                                    )}
                                  </ul>
                                </span>
                              )}
                          </div>
                        </div>

                        <div
                          className=""
                          ref={refGraphDiv}

                        // style={{background:'orange'}}
                        >
                          {ROCData.series.length > 0 && windowHeight > 0 ? (
                            <LineChart
                              ChartTitle={ROCData.title}
                              series={ROCData.series}
                              xunits={ROCData.xunits}
                              plotBands={ROCData.plotBands}
                              labels={ROCData.labels}
                              yunits={ROCData.yunits}
                              SelectedChannel={SelectedChannel}
                              Downloadchart={Downloadchart}
                              setDownloadchart={setDownloadchart}
                              setLoader={setLoader}
                              height={ChartHeight}
                              DivHeight={DivHeight}
                              windowHeight={windowHeight}
                              Brand={Analyse.brand}
                              ActiveFilters={Sortable}
                              Fixed={Fixed}
                            />
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>

                </div>
              </div>

              {JSON.stringify(AdstockData) !== "{}" ||
                parseInt(ShowSummary) !== 0 ? (
                <SummaryAdstock
                  adstockChart={true}
                  setIsDownloadingChart={setIsDownloadingChart}
                  setChartContainerID={setChartContainerID}
                  setDownloadedImageName={setDownloadedImageName}
                  setDownloadingChartTitle={setDownloadingChartTitle}
                  DivHeight={DivHeight}
                  windowHeight={windowHeight}
                  ShowSummary={ShowSummary}
                  ChartHeight={
                    refGraphDiv.current
                      ? refGraphDiv.current.clientHeight + 12
                      : ""
                  }
                  Summary={Summary}
                  AdstockData={AdstockData}
                  setShowPopup={setShowPopup}
                />
              ) : null}

            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {FooterNotes !== "" ? (
                  <>
                    <i
                      className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class icon-fill"
                      id="clickable"
                    ></i>
                    <Tooltip anchorSelect="#clickable" clickable>
                      {/* <div className="footer-notes">
                          {FooterNotes}
                        </div> */}
                      <div
                        className="footer-notes"
                        dangerouslySetInnerHTML={{ __html: FooterNotes }}
                      ></div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
