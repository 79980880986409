import React, { useState, useMemo } from 'react'
import Growth from './Growth'
import Summary from './Summary'

const SummaryGrowth = (props) => {
    const [Toggle, setToggle] = useState(false)

    const GrowthSummary = useMemo(() => {
        return (
            Toggle ? <Growth OptChartdict={props.OptChartdict} setChartDataMain={props.setChartDataMain} /> : <Summary OptSummarydict={props.OptSummarydict} AllowComp={props.AllowComp} />
        )
    }, [Toggle, props.OptChartdict, props.OptSummarydict, props.setChartDataMain])

    return (
        <div className={props.AllowEdit ? "col-xs-6 col-md-6 col-lg-6 col-xl-3" : "col-xs-6 col-md-6 col-lg-6 col-xl-4"}
        style={{height:'calc(100%)'}}
        >
            <div className="card custom-summary optimizer-table-height mb-0 "
            style={{height:'100%'}}
            >
                <div className="card-body p-0" style={{height: "100%"}}>
                    <div className="card-3" style={{height: "100%",}}>
                        <div className="custom-header mb-1">
                            <span className="header-title cursor-pointer" onClick={() => setToggle(!Toggle)}>
                                <h3 className="data-spends my-2 px-2" >Summary</h3>
                                {
                                    Toggle ? <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3>
                                }
                            </span>
                        </div>

                        <div className={Toggle ? "custom-footer active mb-1" : "custom-footer mb-1"}
                        // style={{padding:'0 5%'}}
                        >
                            <span className="footer-title cursor-pointer" onClick={() => setToggle(!Toggle)}
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h3 className="data-spends my-2 px-2">Charts</h3>
                                {
                                    !Toggle ? <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3>
                                }
                            </span>
                        </div>

                        {/* <div className="custom-body" style={{ overflow: "auto", height: "510px" }}> */}
                        <div className="custom-body px-3 py-1" style={{ overflowY: "scroll", height: "78%"}}>
                            <div className=''>
                                {/* {
                                    props.AllowEdit ?
                                        Toggle ? <Growth OptChartdict={props.OptChartdict} /> : <Summary OptSummarydict={props.OptSummarydict} /> : Toggle ? <Growth OptChartdict={props.OptChartdict} /> : <Summary OptSummarydict={props.OptSummarydict} />
                                } */}
                                {GrowthSummary}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryGrowth