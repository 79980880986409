import React from "react";
import { Box } from "@mui/material";
import LoaderImage from "../assets/skewb-logomark.png";
import CyanLogo from "../assets/skewb-logomark-c7.png";



function Loader() {
  return (
    <Box
      sx={{
        color: "red",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "99999",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(8px)",
      }}
    >
      <Box
        sx={{
          width: 100,
          height: 100,
          borderRadius: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <img
          src={
            localStorage.getItem("theme") === "dark"
              ? LoaderImage
              : CyanLogo
          }
          alt="Loading..."
          style={{
            animation: "rotate360 2s linear infinite",
            transformOrigin: "center",
            filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))",
            maxWidth: "80%",
            maxHeight: "80%",
          }}
        />
      </Box>
      <style>
        {`
          @keyframes rotate360 {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Box>
  );
}
 
export default Loader;
