import React, { useEffect, useState } from "react";

const Table = (props) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    setTheme(storedTheme || "light");
  }, []);

  const isDarkTheme = theme === "dark";

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="table-responsive p-0 analytics-table table-optimizer">
          {JSON.stringify(props.data) !== "{}" ? (
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  {props.data.headers.map((item, index) => (
                    <th key={index} scope="col">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.data.rawdata.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {item.rodata.map((cell, colIndex) => {
                      const isTopLeftCell = rowIndex === 0 && colIndex === 0;
                      const isFirstColumn = colIndex === 0 && rowIndex !== 0;

                      return (
                        <td
                          key={`${rowIndex}-${colIndex}`}
                          className={rowIndex === 0 ? "first-row" : ""}
                          style={{
                            // Apply background and text color for the first row
                            background: rowIndex === 0
                              ? isDarkTheme
                                ? "#d6ff41"
                                : "#196ab6"
                              : "",
                            color: rowIndex === 0
                              ? isDarkTheme
                                ? "#000000"
                                : "#f7feff"
                              : cell.color,
                            fontWeight: rowIndex === 0 ? "bold" : "normal",
                            // Text alignment logic:
                            textAlign: isTopLeftCell
                              ? "left" // Center-align the top-left cell
                              : isFirstColumn
                              ? "left"   // Left-align the first column cells below the top-left
                              : "center" // Center-align all other cells
                          }}
                        >
                          {cell.value}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Table;
