import { React, useRef, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import groupedCategories from 'highcharts-grouped-categories';
import configDriven from "../ConfirgDriven";
// groupedCategories(Highcharts);


const LTSTROI = (props) => {

  const refChart = useRef(null);
  const divRef = useRef(null);

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  // return display names of x axis labels title
  function getFormattedString(id, obj) {
    console.log("id ltstroi", id, "    ", obj);
    if (!id || typeof id !== "string") {
      return id;
    }

    if (configDriven.show_display_name) {
      const keys = id.split("-");
      const values = keys.map((key) => obj?.[key] ?? key);
      return values.join("-");
    } else {
      return id;
    }
  }

  const calculateWidth = (categories, containerWidth, minColumnWidth) => {
    let converedWidthByColumns = minColumnWidth * categories + categories * 50;
    return Math.max(containerWidth, converedWidthByColumns);
  };


  const handleLoader = () => {
    console.log("handleLoader");
    props.setLoader(false);
  }

  console.log("ltstroi chart height", window.outerHeight, " ", props.refGraphDiv?.current?.getBoundingClientRect().top - 10);
  const options = {
    chart: {
      type: "column",

      zoomType: "xy",
      class: 'roi-col-chart',
      resetZoomButton: {
        position: {
          align: 'center', // Align the button to the center horizontally
          verticalAlign: 'top', // Keep it at the top vertically
          x: 0, // No horizontal offset, keep it centered
          y: 10, // Optional: Adjust vertical offset if needed
        },
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
      backgroundColor: "rgba(0,0,0,0)",
      // backgroundColor: localStorage.getItem('theme') === "dark" ? "#1c2427" : "",
      showAxes: true,
      // height: window.innerHeight < 850 ? window.innerHeight * 0.68 : window.innerHeight * 0.76,
      height: props.windowHeight,
      events: {
        render: handleLoader,
      }
      // height:props.height,

    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.Categories,
      min: 0,
      max: props.full?8:props.Series[0].data.length-1,
      scrollbar: {
        enabled: !props.full?false:true
      },
      title: {
        text: props.Xtitle,
        style: {
          color: "#FFFFFF",
        },
      },
      crosshair: false,
      labels: {
        useHTML: true,
        formatter: function () {

          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];

            function chnnellist(list) {
              // console.log("chnnellist ", list);
              if (typeof list === 'object' && list !== null) {
                if (list.name?.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              } else if (typeof list === 'string') {
                SeriesList.push(list.split(",")[0]);
              }
            }
            if (this?.pos !== undefined && this?.axis?.categories[this.pos]) {
              chnnellist(this.axis.categories[this.pos]);
            } else {
              console.error("Invalid position or categories:", this.pos, this.axis?.categories);
              return '';
            }

            const val = this?.value?.split(",");
            const lebelText = returnDisplayName(val[0]);
            let title = SeriesList?.reverse()?.join("-");
            title = title?.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }

            //   return localStorage.getItem("theme") === "dark"
            //     ? `<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title="${getFormattedString(title, displayNames)}">${lebelText}</div>`
            //     : `<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title="${getFormattedString(title, displayNames)}">${lebelText}</div>`;
            // } else {
            //   return localStorage.getItem("theme") === "dark"
            //     ? `<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`
            //     : `<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`;
            // }
            // const dynamicId = val[0]; // Replace with your logic for dynamic ID

            return localStorage.getItem("theme") === "dark"
              ? `<div id="${title}" class="cursor-pointer" style="background-color:#1C2427;" title="${getFormattedString(title, displayNames)}">${lebelText}</div>`
              : `<div id="${title}" class="cursor-pointer" style="color:#2f3c41; background-color:white;" title="${getFormattedString(title, displayNames)}">${lebelText}</div>`;
          } else {
            return localStorage.getItem("theme") === "dark"
              ? `<div id="${this?.value?.name?.split(",")[0]}" class="cursor-pointer" style="background-color:#1C2427;" title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`
              : `<div id="${this?.value?.name?.split(",")[0]}" class="cursor-pointer" style="color:#2f3c41; background-color:white; " title="${fulltext}">${this?.value?.name?.split(",")[0]}</div>`;
          }

        }


        ,
        rotation: props?.full ? 0 : props?.Series[0]?.data.length > 3 ? -45 : 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            console.log("Label Click", this);
            props?.DrillUp(this);
          },

        },

      },



    },
    yAxis: {
      min: 0,
      gridLineColor: '#808080',
      // gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.Ytitle,
        style: {
          color:
            localStorage.getItem("theme") === "dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
      labels: {
        style: {
          color:
            localStorage.getItem("theme") === "dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },

    tooltip: {
      followPointer: true,
      formatter: function () {
        const SeriesList = [];
        function chnnellist(list) {
          if (typeof list === 'object' && list !== null) { // Ensure it's an object
            if (list.name.split(",")[0] !== "") {
              SeriesList.push(list.name.split(",")[0]);
            }
            if ("parent" in list && list.parent !== undefined) {
              chnnellist(list.parent);
            }
          } else if (typeof list === 'string') { // Handle the case where list is a string
            SeriesList.push(list.split(",")[0]);
          }
        }

        chnnellist(this?.x);

        let label = SeriesList?.reverse()?.join("-");
        // return label + " " + this.y.toFixed(2) + "";
        const foundLabel = label?.split("-")?.pop();

        var series = this.series;
        var spends = series.userOptions.spends[this.point.index];
        var contribution = series.userOptions.contributions
          ? series.userOptions.contributions[this.point.index]
          : undefined;

        return (
          "<b>" +
          // label +
          getFormattedString(label, displayNames) +
          "</b><br/>" +
          series.name +
          ": " +
          this.y +
          "<br/>" +
          "Spends: " +
          spends +
          "<br/>" +
          "Contribution: " +
          contribution
        );
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },

    plotOptions: {
      column: {
        pointPadding: .1, // Slight padding for better spacing
        borderWidth: 0,
        borderRadius: 4,

        borderRadiusTop: 15, // Rounded top-left corner
        borderRadiusTopRight: 15, // Rounded top-right corner
        borderColor: '#1c2427', // darker border color for a sleek look


        dataLabels: {
          enabled: true,
          allowOverlap: true,

        },
        states: {
          hover: {
            brightness: 0,
            enabled: false
          },
          select: {
            color: null,
            borderColor: 'black',
            borderWidth: 1
          }
        }
      },
      series: {
        // cursor: 'pointer',
        point: {
          events: {
            click: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (typeof list === 'object' && list !== null) { // Ensure it's an object
                  if (list.name.split(",")[0] !== "") {
                    SeriesList.push(list.name.split(",")[0]);
                  }
                  if ("parent" in list && list.parent !== undefined) {
                    chnnellist(list.parent);
                  }
                } else if (typeof list === 'string') { // Handle the case where list is a string
                  SeriesList.push(list.split(",")[0]);
                }
              }

              chnnellist(this.category);

              let label = SeriesList?.reverse()?.join("-");
              // console.log("label: ", label);

              if (!props.NotAllow.includes(label)) {
                // props.DrillDown(this);
                props.DrillDown(label);
              }
            },

            mouseOver: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (typeof list === 'object' && list !== null) { // Ensure it's an object
                  if (list.name.split(",")[0] !== "") {
                    SeriesList.push(list.name.split(",")[0]);
                  }
                  if ("parent" in list && list.parent !== undefined) {
                    chnnellist(list.parent);
                  }
                } else if (typeof list === 'string') { // Handle the case where list is a string
                  SeriesList.push(list.split(",")[0]);
                }
              }

              chnnellist(this.category);

              let label = SeriesList?.reverse()?.join("-");

              window.document.getElementsByTagName("body")[0].style.cursor =
                "pointer";
              if (props.NotAllow.includes(label)) {
                window.document.getElementsByTagName("body")[0].style.cursor =
                  "not-allowed";
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName("body")[0].style.cursor =
                "default";
            },
          },
        },
      },
    },
    legend: {
      enabled: false,
      itemHoverStyle: {
        color:
          localStorage.getItem("theme") === "dark"
            ? "#FFFFFF"
            : "#1C2427",
      },
      itemStyle: {
        color:
          localStorage.getItem("theme") === "dark"
            ? "#FFFFFF"
            : "#1C2427",

      },
      symbolRadius: 0,
    },
    series: props.Series,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
        },
      },
    },
  };

  useEffect(() => {
    if (divRef.current) {

      Highcharts.chart(divRef.current, {
        ...options
      })
    }

  }, [props.Chartdata, props.full, props.DivHeight, divRef])
  return (
    <div
      id="roi_chart"
      key={props.full}
      style={{

        overflowX: props.full?"scroll":"",
        width: "100%",
        // height: "98%",

      }}
    >
      <div
        id="container"
        ref={divRef}
        style={{
          // width: props.full
          //   ? calculateWidth(props.Series[0].data.length, props.divWidth, 40)
          //   : null,
          width:props.full? "100%":null,

          // height: "98%",

        }}
      >
        {/* <HighchartsReact
          ref={refChart}
          highcharts={Highcharts}
          options={options}
          {...props}
        />  */}
      </div>
    </div>
  );
};

export default LTSTROI;
