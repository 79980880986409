import React from "react";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ExcelExport from "fusioncharts/fusioncharts.excelexport";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFusioncharts.fcRoot(
  FusionCharts,
  charts,
  ExcelExport,
  PowerCharts,
  FusionTheme
);
const WaterfallChart = (props) => {
  const getBaseFontSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 480) return "8px";      // Small screens
    if (screenWidth <= 768) return "10px";     // Medium screens
    if (screenWidth <= 1200) return "11px";
    if (screenWidth <= 1600) return "12px";
    if (screenWidth <=2000) return "13px"; // Large screens
    return "14px";                             // Extra large screens
  };

  const dataSource = {
    chart: {
      caption: "",
      xAxisName: props.data.xtitle,
      className: "cm-filter-title waterfall-charts",
      // background: localStorage.getItem("theme") !== null 
      //   ? (localStorage.getItem("theme") === "dark" ? "#1c2427" : "red") 
      //   : "#1c2427",
      // background:"red",
      bgColor: localStorage.getItem("theme")==="dark"?"#1c2427":"#f7feff",
      baseFontSize: getBaseFontSize(),
      labelDisplay:"rotate",
      slantLabel:"1",
      // useEllipsesWhenOverflow: "0",
      baseFontColor:localStorage.getItem("theme")==="dark"?"#d6ff41":"",
      xAxisNameFontColor: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") === "Dark" ?
        "#D6FF41" : "#0a2472" :
        "#D6FF41",
      // localStorage.getItem("ColorCode") === "Lavender" ?
      //     "#9384bf" :
      //     localStorage.getItem("ColorCode") === "Sapphire" ?
      //         "#6b75af" :
      //         localStorage.getItem("ColorCode") === "Teal" ?
      //             "#57898c" :
      //             localStorage.getItem("ColorCode") === "Cyan" ?
      //                 "#21afc2" :
      //                 localStorage.getItem("ColorCode") === "Goldenrod" ?
      //                     "#dca25b" : "" : 

      yAxisName: props.data.ytitle,
      yAxisNameFontColor: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") === "Dark" ?
        "#D6FF41" : "#0a2472" :
        "#D6FF41",
      

      theme: "fusion",
      numberSuffix: "%",
      exportenabled: 0,
      exportformats:
        "PNG=Export as High Quality Image|PDF=Export as Printable Document|XLS=Export Chart Data|CSV=Export Chart Data as csv",
      showSumAtEnd: "0",
      yAxisMinValue:
        props.data.chartdata.length > 0
          ? Math.floor(props.data.chartdata[0].value)
          : 0,
      // bgColor: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") === "dark" ? "#1c2427" : "#ffffff" : "#1c2427",
      // baseFontColor: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") === "dark" ? "#ffffff" : "#1c2427" : "#ffffff",
      toolTipColor: "#000000",
      toolTipBgColor: localStorage.getItem("theme")==="dark"?"#1c2427":"#f7feff",
      // showToolTip: "0", // Disable tooltips
      xaxislinecolor: "#7D9CA6",
      yaxislinecolor: "#7D9CA6",
      showxaxisline: "1",
      showyaxisline: "1",
      decimals: "1", // Show up to 1 decimal place
      showvalues: "1",
      valueFontColor: localStorage.getItem("theme") !== null ? localStorage.getItem("theme") === "dark" ? "#f7feff" : "#1c2427" : "#f7feff",
      chartLeftMargin: "0",
      chartTopMargin: "10",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      // Disable hover effects and crosshair
      showHoverEffect: "0",
      plotHoverEffect: "0",
      crossLineAlpha: "0",
      drawCrossLineOnTop: "0",
      // Disable tooltips if you want to remove all hover interactions
      // showToolTip: "0"
      // crosshair:0,


    },
    axes: [
      {
        type: 'number',
        position: 'left',
        baseFontSize: getBaseFontSize(),
        crossline: {
          enabled: 0
        },
        showHoverEffect: 0
      },
    ],
    data: props.data.chartdata
  };


  return (
    <div className="waterfallChart">
      <ReactFusioncharts
        type="waterfall2d"
        // width={props.ChartWidth}
        width='100%'
        height={props.ChartHeight}
        
        dataFormat="JSON"
        dataSource={dataSource}
        fcEvent-dataplotRollOver={props.dataplotRollOverHandler}
      />
    </div>
  );
};

export default WaterfallChart; 

// import React from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import HighchartsMore from 'highcharts/highcharts-more';

// // Initialize HighchartsMore
// if (typeof Highcharts === 'object') {
//   HighchartsMore(Highcharts);
// }

// const WaterfallChart = (props) => {
//   const isDarkTheme = localStorage.getItem("theme") === "dark";
  
//   const options = {
//     chart: {
//       type: 'waterfall',
//       backgroundColor: isDarkTheme ? "#1c2427" : "#f7feff",
//       marginTop: 50,
//       marginBottom: 100,
//       style: {
//         fontFamily: 'Arial, sans-serif'
//       }
//     },
//     title: {
//       text: '',
//     },
//     xAxis: {
//       categories: props.data.chartdata.map(item => item.label),
//       title: {
//         text: props.data.xtitle,
//         style: {
//           color: isDarkTheme ? "#D6FF41" : "#0a2472",
//           // fontSize: '14px',
//           fontWeight: 'bold'
//         },
//         margin: 20
//       },
//       labels: {
//         style: {
//           color: isDarkTheme ? "#f7feff" : "#1c2427",
//           // fontSize: '12px'
//         },
//         rotation: -45,
//         autoRotation: [-45],
//         overflow: 'justify'
//       },
//       lineColor: '#7D9CA6',
//       lineWidth: 1,
//       tickLength: 5,
//       tickWidth: 1,
//       tickColor: '#7D9CA6'
//     },
//     yAxis: {
//       title: {
//         text: props.data.ytitle,
//         style: {
//           color: isDarkTheme ? "#D6FF41" : "#0a2472",
//           // fontSize: '14px',
//           fontWeight: 'bold'
//         },
//         margin: 20
//       },
//       labels: {
//         format: '{value}%',
//         style: {
//           color: isDarkTheme ? "#f7feff" : "#1c2427",
//           fontSize: '12px'
//         }
//       },
//       gridLineColor: isDarkTheme ? 'rgba(125, 156, 166, 0.1)' : 'rgba(125, 156, 166, 0.2)',
//       lineColor: '#7D9CA6',
//       lineWidth: 1,
//       // Add tickInterval for 10% increments
//       tickInterval: 10,
//       // Ensure minor ticks are shown for 5% intervals if desired
//       minorTickInterval: 5,
//       minorGridLineColor: isDarkTheme ? 'rgba(125, 156, 166, 0.05)' : 'rgba(125, 156, 166, 0.1)',
//       minorGridLineWidth: 1,
//       minorTickLength: 3,
//       minorTickWidth: 1,
//       // Ensure the axis starts and ends at nice intervals
//       startOnTick: true,
//       endOnTick: true,
//       // Optional: Set specific min and max if needed
//       // min: 0,
//       // max: 100
//     },
//     legend: {
//       enabled: false
//     },
//     tooltip: {
//       backgroundColor: isDarkTheme ? "#1c2427" : "#f7feff",
//       borderColor: '#7D9CA6',
//       borderRadius: 8,
//       style: {
//         color: isDarkTheme ? "#f7feff" : "#1c2427",
//         // fontSize: '12px'
//       },
//       formatter: function() {
//         return `<b>${this.point.name}</b><br/>
//                 Value: ${this.y.toFixed(1)}%<br/>
//                 ${this.point.change ? 'Change: ' + this.point.change.toFixed(1) + '%' : ''}`;
//       },
//       shadow: false
//     },
//     plotOptions: {
//       series: {
//         borderWidth: 0,
//         dataLabels: {
//           enabled: true,
//           format: '{y:.1f}%',
//           style: {
//             color: isDarkTheme ? "#f7feff" : "#1c2427",
//             fontSize: '12px',
//             fontWeight: 'normal',
//             textOutline: 'none'
//           },
//           backgroundColor: 'rgba(255,255,255,0.5)',
//           borderRadius: 4,
//           padding: 4
//         },
//         states: {
//           hover: {
//             enabled: false
//           },
//           inactive: {
//             opacity: 1
//           }
//         },
//         upColor: '#00C853',
//         color: '#FF5252',
//         pointPadding: 0.2,
//         groupPadding: 0
//       }
//     },
//     series: [{
//       data: props.data.chartdata.map(item => ({
//         name: item.label,
//         y: item.value,
//         color: item.color || undefined,
//         change: item.value - (props.data.chartdata[props.data.chartdata.indexOf(item) - 1]?.value || 0)
//       }))
//     }],
//     credits: {
//       enabled: false
//     },
//     exporting: {
//       enabled: false,
//       buttons: {
//         contextButton: {
//           menuItems: ['downloadPNG', 'downloadPDF', 'downloadCSV']
//         }
//       },
//       fallbackToExportServer: false
//     },
//     responsive: {
//       rules: [{
//         condition: {
//           maxWidth: 500
//         },
//         chartOptions: {
//           chart: {
//             marginBottom: 120
//           },
//           xAxis: {
//             labels: {
//               rotation: -90
//             }
//           }
//         }
//       }]
//     }
//   };

//   return (
//     <div className="waterfallChart">
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={options}
//         containerProps={{ 
//           style: { 
//             height: props.ChartHeight,
//             width: '100%'
//           } 
//         }}
//       />
//     </div>
//   );
// };

// export default WaterfallChart;