import React, { useState, useEffect } from "react";
import Legends from "./Legends";

import configDriven from "../ConfirgDriven";

const RowItem = ({ item, showOpenLevel }) => {
  const [expand, setExpand] = useState(item?.heirarchy_level <= showOpenLevel);
  const isLeaf = item?.children.length === 0;

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(item?.heirarchy_level <= showOpenLevel);
  }, [showOpenLevel, item]);

  const displayNamesChart = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNamesChart?.hasOwnProperty(id)) {
      return displayNamesChart[id];
    } else {
      return id;
    }
  }


  return (
    <>
      <tr>
        <td className="channel-name" >
          <div
            style={{
              cursor: isLeaf ? "default" : "pointer",
              marginLeft:
                parseInt(item?.heirarchy_level) !== 0
                  ? parseInt(item?.heirarchy_level) * 8 + "px"
                  : "0px",
              // fontSize: "12px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={isLeaf ? null : handleExpand}
          >
            {!isLeaf ? (
              <i
                className={`fas fa-${expand ? "minus" : "plus"
                  } mr-1 custom-table-toggle-icon icon-class2`}
                style={{
                  marginRight: "4px",
                  // width: '15px',
                  color: "white",
                  textAlign: "center",
                  // border: "1px solid #d6ff41",  // Add a border for the box
                  borderRadius: "3px", // Rounded corners for the box
                  padding: "5px", // Add padding for spacing inside the box
                  width: "15px", // Fixed width to ensure consistency
                  height: "15px", // Fixed height to ensure consistency
                  display: "flex", // Center the icon within the box
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1c2427", // Optional: Add background color to the box
                }}
              ></i>
            ) : (
              <span style={{ width: "15px", marginRight: "5px" }}>
                &nbsp;&nbsp;
              </span>
            )}
            {returnDisplayName(item?.name)}
          </div>

        </td>
        {item?.value.map((data, dataIndex) => (
          // fontSize: '12px'
          <td key={dataIndex} style={{ color: data.color,  }}>{data?.value}</td>
        ))}
      </tr>
      {expand &&
        item.children &&
        item.children.map((childItem) => (
          <RowItem
            key={childItem.id}
            item={childItem}
            showOpenLevel={showOpenLevel}
          />
        ))}
    </>
  );
};


const ComparativeROI = (props) => {
  // console.log("Xtitle: ", props.Xtitle);
  // console.log("Ytitle: ", props.Ytitle);
  // console.log("ChannelYears: ", props.ChannelYears);
  // console.log("ChannelData: ", props.ChannelData);


  return (
    <>
      <div className="row my-1 flex-nowrap table-div" style={{ paddingRight: "40px", }}>
        <div className="custom-col-sm-1" style={{ width: "40px" , border:"none"}}>
          <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {props.Ytitle}</p>
        </div>
        <div className="col-sm-12 px-0 comp-roi-table" style={{ marginTop: '15px' }}>
          {/* icons.....start */}
          <div className="row align-items-center sorting-btn-ui comp-roi-icons">
            <div className="col-sm-3" style={{ display: "flex", }}>
              {/* <ul> */}
              {/* span--> position: "fixed", zIndex: "10", top: "150px", left: "20px", marginBottom: "10px" ,className="my-2"*/}
              <span className="" style={{ display: "flex",  }}>
                <div onClick={() => props.setShowOpenLevel(Math.floor(Math.random() * (10000000 - 1000000 + 1)) + 1000000)} title="Expand All">
                  <i className="fa fa-sitemap icon-class icon-fill" aria-hidden="true"></i>
                </div>
                <div onClick={() => props.setShowOpenLevel(0)} title="Reset">
                  <i className="fa icon-class icon-fill" aria-hidden="true">
                    &#8635;
                  </i>
                </div>
              </span>
              {/* </ul> */}
            </div>
          </div>
          {/* icons.......end */}
          <div className="gr-table-x-title-div ">
            <p className="m-0 gr-table-x-title">{props.Xtitle} <i className="ri-arrow-right-line mx-2"></i></p>
          </div>
          {/* maxHeight: `${window.innerHeight < 950 ? window.innerHeight * 0.65 : window.innerHeight * 0.75}px` */}
          <div className="table-responsive gains-risk-table" style={{ maxHeight: `${props.windowHeight}px`, overflowY: "scroll", }}>
            <table className="table table-bordered-right mb-0" >

              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.ChannelYears.map((item, index) => {
                    return <th key={index + item}
                      style={{
                        // fontSize: '12px',
                        whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px'
                      }}

                    >{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {props.ChannelData?.map(item => {
                  return <RowItem key={item.id} item={item} showOpenLevel={props.showOpenLevel} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row my-3 d-flex justify-content-sm-end">
        <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
          {props.Legends.length > 0 ? props.Legends.map((item, index) => {
            return <Legends key={item + "-" + index} item={item} />;
          }) : null}
        </div>
      </div>
    </>
  );
};

export default ComparativeROI;
