import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";

import DeleteResultPopup from "../Components/DeleteResultPopup";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";
import configDriven from "../ConfirgDriven";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
    const Analyse = JSON.parse(localStorage.getItem("Analyse"));
    const [SavedResults, setSavedResults] = useState([]);
    const [DeletedId, setDeletedId] = useState("");
    const [Search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [GetData, setGetData] = useState(true);


    const displayNames = JSON.parse(localStorage.getItem("displayNames"));

    function getFormattedString(id, obj) {
        console.log("id ltstroi", id, "    ", obj);
        if (!id || typeof id !== "string") {
            return id;
        }

        if (configDriven.show_display_name) {
            const keys = id.split("-");
            const values = keys.map((key) => obj?.[key] ?? key);
            return values.join("-");
        } else {
            return id;
        }
    }

    useEffect(() => {
        if (GetData) {
            setLoader(true);
            axios
                .post("get_saved_optimisations/", {
                    brand: Analyse.Brand,
                    kpi: Analyse.KPI,
                })
                .then((response) => {
                    if (response.data.error === 1) {
                        toast.error(response.data.erroMsg);
                    } else {
                        setSavedResults(response.data.data);
                        setGetData(false)
                    }
                    setLoader(false);
                });
        }
    }, [Analyse.Brand, Analyse.KPI, GetData]);

    const DeleteId = (id) => {
        setDeletedId(id);
    };

    const CanceledId = () => {
        setDeletedId("");
    }
    const DeleteRecord = (id) => {
        setLoader(true);
        axios.post("deleted_optimisation/", { result_id: DeletedId }).then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
            } else {
                setDeletedId(response.data.data);
                setDeletedId("");
                setGetData(true)
            }
            setLoader(false);
        })
    }

    return (
        <div className="skewb-div-gpt">
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            {/* <Topbar BrandName={Analyse.Brand || ""} />
            <Menubar /> */}
            <div className="main-content-div">
                <div className="page-content-div">

                    <div
                        className="container-fluid main-content-w pt-1 savedResult-main-div"
                    // style={{ paddingTop: "10px" }}
                    >
                        <div className="row savedResult-div" >
                            <div className="col-sm-12 savedResult-div">
                                <div
                                    className="card card-min-height optimizer-table-height card-body brand-card pt-2"
                                // className="card"

                                >
                                    <div className="row justify-content-between mx-2">
                                        <div className="col-sm-12">
                                            <div className="page-title-box d-sm-flex align-items-center flex-row-reverse p-0">
                                                <div className="page-title-right">
                                                    <form className="app-search d-none d-lg-block">
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                className="cm-filter-title background-colors text-colors form control form-control-input"
                                                                style={{
                                                                    flexGrow: 1,
                                                                    position: "relative",


                                                                    borderRadius: "4px",
                                                                    autocomplete: "off",
                                                                    // background: '#1c2427',

                                                                    // color: "white",
                                                                    // fontSize: '12px',
                                                                    width: '17rem',
                                                                    padding: '4px',
                                                                    outline: "none"
                                                                }}
                                                                placeholder="Search By Name"

                                                                value={Search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                            {/* <span className="ri-search-line"></span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between ">
                                        <div className="col-sm-12">
                                            <div className="table-responsive saved-result-table">
                                                <table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ color: "black" }}>Serial No</th>
                                                            <th style={{ color: "black" }}>Name</th>
                                                            {/* New Column */}
                                                            <th style={{ color: "black" }}>Brand</th>
                                                            {/*New Column  */}
                                                            <th style={{ color: "black" }}>KPI</th>
                                                            <th style={{ color: "black" }}>Date &amp; Time</th>
                                                            <th style={{ color: "black" }}>User</th>
                                                            <th style={{ color: "black" }}>Optimised for</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {SavedResults.map((item) => {
                                                            return (item.result_name.toLowerCase().includes(Search.toLowerCase())) ? (
                                                                <tr key={item.result_id}>
                                                                    <td>{item.result_id}</td>
                                                                    <td>{item.result_name}</td>
                                                                    <td>{getFormattedString(item.brand, displayNames)}</td>
                                                                    <td>{getFormattedString(item.kpi, displayNames)}</td>
                                                                    <td>{item.result_date}</td>
                                                                    <td>{item.username}</td>
                                                                    <td style={{ textTransform: 'capitalize' }}>
                                                                        {item.optimization_type} - {item.budget}{item.optimization_type === 'budget' ? '' : '%'}
                                                                    </td>
                                                                    <td>
                                                                        {/* fas fa-folder-open */}
                                                                        <Link to={"/optimizer/" + item.result_id} className="btn-class" style={{ color: 'inherit', textDecoration: 'none', height: '55px', padding: '3px' }}>
                                                                            Open
                                                                        </Link>
                                                                    </td>
                                                                    <td className="delete-result-icon">
                                                                        <i
                                                                            className=" ri-delete-bin-7-line icon-class icon-fill"
                                                                            onClick={() => DeleteId(item.result_id)}
                                                                            // style={{ background: '#1c2427' }}
                                                                        ></i>
                                                                        {DeletedId !== "" ? <DeleteResultPopup CanceledId={CanceledId} DeleteRecord={DeleteRecord} /> : null}
                                                                    </td>
                                                                </tr>
                                                            ) : null;
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
