import React, { useState, useEffect, useRef } from "react";
// import Download from '../../images/download-icon.png'
// import circle from '../../images/c.png'
// import tringle from '../../images/t.png'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
// import axiosChartDownload from "../../axiosChartDownload";
// import { toast } from "react-toastify";
// import domtoimage from "dom-to-image";
import configDriven from "../ConfirgDriven";

HC_exporting(Highcharts);

const LineChart = (props) => {
  const refGraphDiv = useRef(null);
  const refChart = useRef(null);
  const [graphData, setgraphData] = useState([])

  // useEffect(() => {
  //   if (props.Downloadchart) {
  //     const chartContainer = document.getElementById("growth_curve_chart");
  //     domtoimage
  //       .toPng(chartContainer)
  //       .then(function (dataUrl) {
  //         var string = "";
  //         for (var i = 0; i < props.Fixed.length; i++) {
  //           string += props.Fixed[i].id;
  //           if (props.ActiveFilters.length > 0) {
  //             string += ",";
  //           }
  //         }

  //         for (var j = 0; j < props.ActiveFilters.length; j++) {
  //           string += props.ActiveFilters[j].name;
  //           if (props.ActiveFilters.length - 1 !== j) {
  //             string += ","
  //           }
  //         }

  //         props.setLoader(true);
  //         axiosChartDownload.post("download_chart/create-image/",
  //           {
  //             "title": "Growth Curves",
  //             "filter": props.Brand,
  //             "hierarchy": string,
  //             "timeframe": "",
  //             "start_date": "",
  //             "end_date": "",
  //             "base64_image": dataUrl.split(",")[1],
  //             theme: localStorage.getItem("CurrentTheme")

  //           }

  //         ).then((response) => {
  //           if (response.data.error === 1) {
  //             toast.error(response.data.erroMsg);
  //             props.setLoader(false);
  //           } else {
  //             var link = document.createElement("a");
  //             link.target = "_blank";
  //             link.href = response.data.data.combined_graph_url;
  //             document.body.appendChild(link);
  //             link.click();
  //             props.setLoader(false);
  //             document.body.removeChild(link);
  //           }
  //         }).catch((data) => {
  //           props.setLoader(false);
  //         });
  //       })
  //       .catch(function (error) {
  //         props.setLoader(false)
  //         console.error("Error while generating chart image:", error);
  //       });
  //     props.setDownloadchart(false)
  //   }
  // }, [props])

  function getFormattedString(id, obj) {
    console.log("id ltstroi", id, "    ", obj);
    if (!id || typeof id !== "string") {
      return id;
    }

    if (configDriven.show_display_name) {
      const keys = id.split("-");
      const values = keys.map((key) => obj?.[key] ?? key);
      return values.join("-");
    } else {
      return id;
    }
  }

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNamesChart")
  );

  useEffect(() => {
    let graphDataTemp = [];
    for (let index = 0; index < props.series.length; index++) {
      const element = props.series[index];
      const obj = {
        type: "line",
        className: 'roc-line-chart',
        name: element.name,
        marker: element.marker,
        data: element.data,
        showInLegend: props.SelectedChannel !== 'All Channels' ? false : true,
        color: element.color ? element.color :
          localStorage.getItem("theme") === "dark" ?
            "#D6FF41" :
            // localStorage.getItem("ColorCode") === "Lavender" ?
            //   "#9384bf" :
            //   localStorage.getItem("ColorCode") === "Sapphire" ?
            //     "#6b75af" :
            //     localStorage.getItem("ColorCode") === "Teal" ?
            //       "#57898c" :
            // localStorage.getItem("ColorCode") === "Cyan" ?
            "#0a2472"
        // localStorage.getItem("ColorCode") === "Goldenrod" ?
        //   "#dca25b" : "#D6FF41"
        ,
        dataLabels: {
          enabled: true,
          allowOverlap: true,

          formatter: function (e) {
            if ("marker" in this.point) {
              return (
                localStorage.getItem('theme') === "dark" ?

                  "<div class='data-point-label' style='color:#ffffff;font-family:Inter;'>" +
                  this.x +
                  "</div>"
                  :
                  "<div class='data-point-label' style='color:#000000;font-family:Inter;'>" +
                  this.x +
                  "</div>"

              );
            } else {
              return "";
            }
          },
          useHTML: true
        },
      };
      graphDataTemp.push(obj);
    }

    setgraphData(graphDataTemp)


  }, [props.series, props.SelectedChannel])

  const options = {
    title: {
      text: null,
    },
    // title: {
    //   text: getFormattedString(props.ChartTitle, displayNamesChart),
    //   style: {
    //     color: "#D6FF41",
    //     fontSize: "11px",
    //   },
    // },
    credits: {
      enabled: false,
    },
    chart: {
      // backgroundColor:localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" :  "",
      backgroundColor: 'rgba(0,0,0,0)',
      type: "line",
      zoomType: "xy",
      resetZoomButton: {
        position: {
          align: 'center', // Align the button to the center horizontally
          verticalAlign: 'top', // Keep it at the top vertically
          x: 0, // No horizontal offset, keep it centered
          y: 10, // Optional: Adjust vertical offset if needed
        },
        theme: {
          fill: "",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
      // height: window.innerHeight<950?window.innerHeight * 0.67:window.innerHeight * 0.76, 
      height: props.windowHeight,

      showAxes: true,
    },
    xAxis: {
      visible: true,
      lineWidth: 1,
      title: {
        enabled: true,
        text: props.xunits,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: localStorage.getItem('theme') === "dark" ? "#FFFFFF" : "1c2427",
        },
      },
      plotBands: [
        {
          from: props.plotBands.from,
          to: props.plotBands.to,
          color: localStorage.getItem("theme") === "dark"
            ? {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "rgba(214, 255, 65, 0.2)"],
                [0.5, "rgba(214, 255, 65, 0.2)"],
                [1, "rgba(214, 255, 65, 1)"]
              ]
            }
            : {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "rgba(202, 240, 248, 0.4)"],    // #caf0f8 - Lightest shade
                [0.2, "rgba(173, 232, 244, 0.6)"],  // #ade8f4 - Light medium shade
                [0.5, "rgba(144, 224, 239, 0.8)"],  // #90e0ef - Medium shade
                [1, "rgba(72, 202, 228, 1)"]        // #48cae4 - Darkest shade
              ]
            }
        }
      ],

      categories: props.labels,
      gridLineColor: '#808080',
    },
    yAxis: {
      visible: true,
      lineWidth: 1,

      title: {
        enabled: true,
        text: props.yunits,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        formatter: function () {
          return this.value + " %";
        },
        style: {
          color: localStorage.getItem('theme') === "dark" ? "#FFFFFF" : "1c2427",
        },
      },
      gridLineColor: '#808080',
    },
    tooltip: {


      enabled: props.SelectedChannel !== 'All Channels' ? true : false,
      headerFormat: `<div style="padding:0;background-color:#ffffff;color:#000000;">
      <span style="font-size:10px;">
        Spends:<b> {point.x}</b></br>
        ${props?.yunits || this?.yAxis[0]?.options?.title.text}:<b> {point.y:.1f}</b>
      </span>
    </div>`,  // Dynamically use yAxis title instead of props.labels
      pointFormat: '',

      // pointFormat:
      //   '<tr><td style="padding:0;">{series.name}: </td>' +
      //   '<td style="padding:0;"><b>({point.x}, {point.y:.1f})</b></td></tr>',  // Display in (x, y) format
      // footerFormat: '</table></div>',
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },


    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: localStorage.getItem('theme') === "dark" ? "#fff" : "1c2427",
      },
      itemHoverStyle: {
        color: localStorage.getItem('theme') === "dark" ? "#fff" : "1c2427",
      },
    },
    series: graphData,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          className: "highcharts-contextbutton",
          symbol: 'menu',
          symbolStroke: "#ffffff",
          theme: {
            fill: '#1c2427',
          },
          titleKey: "",
          menuItems: ["viewFullscreen"]
        }
      }
    },
    //   plotOptions: {
    //     series: {
    //         marker: 'normal',
    //         fillColor: 'red', // Set the marker fill color
    //         lineColor: 'black',
    //     }
    // },
  };

  return (
    <>
      <div id="growth_curve_chart" className={props.height ? props.height + " row align-items-center justify-content-sm-between" : "h-500 row align-items-center justify-content-sm-between"}>

        {/* <div style={{ height: props.height ? props.height : '450px' }} ref={refGraphDiv}> */}
        <div className={window.innerHeight < 1050 ? window.innerHeight * 0.68 : window.innerHeight * 0.76 ? window.innerHeight < 1050 ? window.innerHeight * 0.68 : window.innerHeight * 0.76 : "h-500"} ref={refGraphDiv}>
          {
            graphData.length === props.series.length ? <div id="roc_chart"> <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} /> </div> : null
          }
        </div>
        {
          props.adstockChart !== true &&
          <div className="row ch-movement-legend">
            <div className="col-sm-12">
              <ul className="m-0 p-0 text-center">
                <li className="px-2">
                  <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-triangle-fill" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z" />
                    </svg>
                    <p className="m-0 legend-title mx-1">Sustenance Point</p>
                  </div>
                </li>
                <li className="px-2">
                  <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-circle-fill" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                    <p className="m-0 legend-title mx-1">Operating Point</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        }

      </div>
    </>
  );
  // }

};

export default LineChart;
