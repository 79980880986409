import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ConstructionOutlined } from "@mui/icons-material";

const FlowChart = ({
  sankeyData,
  nodeData,
  setSankeyData,
  originalSankeyData,
  setOriginalSankeyData,
}) => {
  const mainChartRef = useRef(null);
  const backgroundChartRef = useRef(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [fromNode, setFromNode] = useState(null);
  const [toNode, setToNode] = useState(null);

  const initialData = sankeyData;

  useEffect(() => {
    // Background chart setup
    const bgRoot = am5.Root.new(backgroundChartRef.current);
    bgRoot._logo.dispose();
    bgRoot.setThemes([am5themes_Animated.new(bgRoot)]);

    const bgSeries = bgRoot.container.children.push(
      am5flow.Sankey.new(bgRoot, {
        sourceIdField: "from",
        targetIdField: "to",
        valueField: "value",
        // nodeAlign: "justify",
        linkTension: 0.5,
        paddingRight: 50,
        idField: "id",
        sequencedInterpolation: false,
      })
    );

    // Set background chart properties
    bgSeries.links.template.setAll({
      fillStyle: "solid",
      fillOpacity: 0.1, // Very low opacity for background
      strokeWidth: 0,
    });

    bgSeries.nodes.rectangles.template.setAll({
      fillOpacity: 0.1, // Very low opacity for background
      stroke: am5.color(0x000000),
      strokeWidth: 0.5,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
    });

    // Disable all interactions for background chart
    bgSeries.nodes.nodes.template.setAll({
      draggable: false,
      toggleKey: "none",
      interactive: false,
    });

    bgSeries.links.template.setAll({
      interactive: false,
    });

    // Set the data for background chart
    bgSeries.data.setAll(sankeyData);

    // Main interactive chart setup
    const root = am5.Root.new(mainChartRef.current);
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    const series = root.container.children.push(
      am5flow.Sankey.new(root, {
        sourceIdField: "from",
        targetIdField: "to",
        valueField: "value",
        // linkSort: null,
        linkTension: 0.5,
        paddingRight: 50,
        idField: "id",
        sequencedInterpolation: true,
        sequencedDelay: 100,
      })
    );

    // series.animate({
    //   key: "startAngle",
    //   to: 180,
    //   loops: Infinity,
    //   duration: 2000,
    //   easing: am5.ease.inOut,
    // });

    series.links.template.setAll({
      fillStyle: "solid",
      fillOpacity: 0.3,
      tooltipY: 0,
      strokeWidth: 0,
    });

    series.links.template.states.create("hover", {
      fillOpacity: 1,
    });

    series.nodes.nodes.template.setAll({
      draggable: false,
      toggleKey: "none",
    });

    series.links.template.setAll({
      fillStyle: "gradient",
    });

    series.nodes.rectangles.template.setAll({
      fillOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
      tooltipText: "[bold]{name}[/]\n",
    });

    series.nodes.labels.template.setAll({
      fill:
        localStorage.getItem("theme") === "dark"
          ? am5.color(0xffffff)
          : am5.color(0x000000),
    });

    series.nodes.rectangles.template.events.on("pointerover", function (event) {
      const node = event.target._dataItem.dataContext;
      const nodeName = node.id;
      const contribution = getNodeContribution(nodeName);
      const units = getNodeUnits(nodeName);
      const filteredData = filterDataByNode(nodeName);

      const connectionsText = filteredData
        .map(
          (item) =>
            `${item.from} \u2192 ${item.to}: ${item.value}${item.units}${
              item.Sensitivity > 0 ? `, ${item.Sensitivity}` : ""
            }`
        )
        .join("\n");

      event.target.set(
        "tooltipText",
        `[bold]{name}[/]${
          true ? `\nContribution: ${contribution}${units}` : ""
        }\n${connectionsText}`
      );
    });

    function filterDataByNode(node) {
      return initialData.filter(
        (item) => item.from === node || item.to === node
      );
    }

    function getNodeContribution(nodeName) {
      const node = nodeData.find((n) => n.name === nodeName);
      return node ? node.Contribution : "N/A";
    }

    function getNodeUnits(nodeName) {
      const node = nodeData.find((n) => n.name === nodeName);
      return node ? node.units : "N/A";
    }

    series.links.each((link) => {
      link.get("tooltip")?.dispose();
    });

    series.links.template.events.on("pointerover", (event) => {
      const dataItem = event.target.dataItem;
      const currentHoveredId = dataItem.get("id");
      const currentfromNode = dataItem.get("sourceId");
      const currenttoNode = dataItem.get("targetId");

      if (
        currentHoveredId !== hoveredId ||
        currentfromNode !== fromNode ||
        currenttoNode !== toNode
      ) {
        const modifiedData = modifySankeyData(
          sankeyData,
          currentHoveredId,
          currentfromNode,
          currenttoNode
        );
        setOriginalSankeyData(modifiedData);
        setHoveredId(currentHoveredId);
        setFromNode(currentfromNode);
        setToNode(currenttoNode);
      }

      const idBase = currentHoveredId.split("-")[0];

      am5.array.each(series.dataItems, (dataItem) => {
        if (dataItem.get("id").includes(idBase)) {
          const link = dataItem.get("link");
          link.hover();
          
          if (!link.get("tooltip")) {
            
            const tooltip = am5.Tooltip.new(root, {
              labelText: `{from} \u2192 {to} : {value}{units} ${dataItem.dataContext.Sensitivity > 0 ? ", " + dataItem.dataContext.Sensitivity : ""}`
            });

            link.set("tooltip", tooltip);
          }

          link.get("tooltip").show();
        }
      });
    });

    series.links.template.events.on("pointerout", () => {
      am5.array.each(series.dataItems, (dataItem) => {
        const link = dataItem.get("link");
        link.unhover();
        if (link.get("tooltip")) {
          link.get("tooltip").dispose();
          link.set("tooltip", null);
        }
      });
    });

    series.nodes.setAll({
      idField: "id",
      nameField: "name",
      fillField: "color",
    });
    const enrichedNodeData = nodeData.map((node) => ({
      ...node,
      color: am5.color(node.color), // Convert color to amCharts color format
    }));

    // Set the node data in series with colors and tooltips
    series.nodes.data.setAll(enrichedNodeData);

    series.data.setAll(originalSankeyData);
    series.appear(3000, 100);

    return () => {
      root.dispose();
      bgRoot.dispose();
    };
  }, [originalSankeyData, hoveredId, fromNode, toNode, sankeyData]);

  const modifySankeyData = (data, newId, fromNode, toNode) => {
    // Create a deep copy of the data to avoid mutating the original
    const updatedData = data.map((item) => ({ ...item }));

    // Collection to store all links that need their ID changed
    const linksToUpdate = new Set();

    // Backward recursion: Traverse backwards starting from `fromNode`
    const updateBackward = (currentNode) => {
      updatedData.forEach((item) => {
        if (item.to === currentNode && !linksToUpdate.has(item)) {
          linksToUpdate.add(item);
          updateBackward(item.from);
        }
      });
    };

    // Forward recursion: Traverse forwards starting from `toNode`
    const updateForward = (currentNode) => {
      updatedData.forEach((item) => {
        if (item.from === currentNode && !linksToUpdate.has(item)) {
          linksToUpdate.add(item);
          updateForward(item.to);
        }
      });
    };

    // Start the recursion from the initial nodes
    updateBackward(fromNode);
    updateForward(toNode);

    // Update IDs of collected links
    updatedData.forEach((item) => {
      if (linksToUpdate.has(item)) {
        item.id = newId;
      }
    });

    return updatedData;
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        // height: "500px"
        height:
          window.innerHeight < 850
            ? window.innerHeight * 0.78
            : window.innerHeight * 0.82,
      }}
    >
      <div
        ref={backgroundChartRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      />
      <div
        ref={mainChartRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 2,
        }}
      />
    </div>
  );
};

export default FlowChart;
